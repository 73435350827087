import { AuthenticationError } from "../../../types/user";
import { VuiCallout, VuiLink, VuiLinkInternal, VuiSpacer } from "@vectara/vectara-ui";
import { awsMarketplaceConsoleUrl, forumsUrl } from "../../../utils/links";
import React from "react";

const authenticationErrorToMessageMap: Record<AuthenticationError, React.ReactNode> = {
  ["genericLoginError"]: "There was an error logging you in. Please try again later.",
  ["expiredAwsSubscription"]: (
    <>
      Your AWS Marketplace subscription to Vectara is expired. Visit the{" "}
      <VuiLink href={awsMarketplaceConsoleUrl}>AWS Marketplace Console</VuiLink> to renew it.
    </>
  ),
  ["deactivatedAccount"]: "Your account is deactivated."
};

type Props = {
  title: string;
  authenticationError?: AuthenticationError;
  invalidRootUser: boolean;
  error?: React.ReactNode;
  setTabToTeamMember?: () => void;
};

export const LoginError = ({ title, authenticationError, invalidRootUser, error, setTabToTeamMember }: Props) => {
  if (!authenticationError && !invalidRootUser && !error) {
    return null;
  }

  let content;

  if (authenticationError) {
    content = (
      <>
        <p>{authenticationErrorToMessageMap[authenticationError]}</p>

        <p>
          Need help? Ask us questions on{" "}
          <VuiLinkInternal href={forumsUrl} target="_blank">
            our forums
          </VuiLinkInternal>
          .
        </p>
      </>
    );
  } else if (invalidRootUser) {
    content = (
      <p>
        We couldn't find your account. If you're not an account owner,{" "}
        <VuiLink
          onClick={() => {
            setTabToTeamMember?.();
          }}
        >
          sign in as a team member
        </VuiLink>
        .
      </p>
    );
  } else if (error) {
    content = <p>{error}</p>;
  }

  return (
    <>
      <VuiCallout color="danger" headingElement="h2" title={title} data-testid="loginErrorCallout">
        {content}
      </VuiCallout>
      <VuiSpacer size="l" />
    </>
  );
};
