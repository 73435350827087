// source: admin/admin_user.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var admin_admin_security_pb = require('../admin/admin_security_pb.js');
goog.object.extend(proto, admin_admin_security_pb);
var status_pb = require('../status_pb.js');
goog.object.extend(proto, status_pb);
goog.exportSymbol('proto.com.vectara.admin.GetUserRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.GetUserRequest.OneofUseridentifierCase', null, global);
goog.exportSymbol('proto.com.vectara.admin.GetUserResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.ListUsersRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ListUsersResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.ListUsersType', null, global);
goog.exportSymbol('proto.com.vectara.admin.ManageUserRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ManageUserRequest.UserAction', null, global);
goog.exportSymbol('proto.com.vectara.admin.ManageUserResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.ManageUserResponse.UserResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.User', null, global);
goog.exportSymbol('proto.com.vectara.admin.UserActionType', null, global);
goog.exportSymbol('proto.com.vectara.admin.UserStatus', null, global);
goog.exportSymbol('proto.com.vectara.admin.UserType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.User.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.User.displayName = 'proto.com.vectara.admin.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ListUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ListUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ListUsersRequest.displayName = 'proto.com.vectara.admin.ListUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ListUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.com.vectara.admin.ListUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ListUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ListUsersResponse.displayName = 'proto.com.vectara.admin.ListUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ManageUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.ManageUserRequest.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ManageUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ManageUserRequest.displayName = 'proto.com.vectara.admin.ManageUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ManageUserRequest.UserAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ManageUserRequest.UserAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ManageUserRequest.UserAction.displayName = 'proto.com.vectara.admin.ManageUserRequest.UserAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ManageUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.ManageUserResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ManageUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ManageUserResponse.displayName = 'proto.com.vectara.admin.ManageUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ManageUserResponse.UserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.com.vectara.admin.ManageUserResponse.UserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ManageUserResponse.UserResponse.displayName = 'proto.com.vectara.admin.ManageUserResponse.UserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.GetUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.vectara.admin.GetUserRequest.oneofGroups_);
};
goog.inherits(proto.com.vectara.admin.GetUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.GetUserRequest.displayName = 'proto.com.vectara.admin.GetUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.GetUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.GetUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.GetUserResponse.displayName = 'proto.com.vectara.admin.GetUserResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.User.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.User.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sub: jspb.Message.getFieldWithDefault(msg, 2, ""),
    handle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tsCreate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    idCreate: jspb.Message.getFieldWithDefault(msg, 7, 0),
    tsUpdate: jspb.Message.getFieldWithDefault(msg, 8, 0),
    idUpdate: jspb.Message.getFieldWithDefault(msg, 9, 0),
    email: jspb.Message.getFieldWithDefault(msg, 10, ""),
    userStatus: jspb.Message.getFieldWithDefault(msg, 11, 0),
    roleList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.User}
 */
proto.com.vectara.admin.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.User;
  return proto.com.vectara.admin.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.User}
 */
proto.com.vectara.admin.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSub(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHandle(value);
      break;
    case 4:
      var value = /** @type {!proto.com.vectara.admin.UserType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTsCreate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIdCreate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTsUpdate(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIdUpdate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 11:
      var value = /** @type {!proto.com.vectara.admin.UserStatus} */ (reader.readEnum());
      msg.setUserStatus(value);
      break;
    case 12:
      var values = /** @type {!Array<!proto.com.vectara.admin.CustomerRole>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRole(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getSub();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHandle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTsCreate();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getIdCreate();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getTsUpdate();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getIdUpdate();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getUserStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getRoleList();
  if (f.length > 0) {
    writer.writePackedEnum(
      12,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.com.vectara.admin.User.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.User} returns this
 */
proto.com.vectara.admin.User.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string sub = 2;
 * @return {string}
 */
proto.com.vectara.admin.User.prototype.getSub = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.User} returns this
 */
proto.com.vectara.admin.User.prototype.setSub = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string handle = 3;
 * @return {string}
 */
proto.com.vectara.admin.User.prototype.getHandle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.User} returns this
 */
proto.com.vectara.admin.User.prototype.setHandle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional UserType type = 4;
 * @return {!proto.com.vectara.admin.UserType}
 */
proto.com.vectara.admin.User.prototype.getType = function() {
  return /** @type {!proto.com.vectara.admin.UserType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.com.vectara.admin.UserType} value
 * @return {!proto.com.vectara.admin.User} returns this
 */
proto.com.vectara.admin.User.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string comment = 5;
 * @return {string}
 */
proto.com.vectara.admin.User.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.User} returns this
 */
proto.com.vectara.admin.User.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 ts_create = 6;
 * @return {number}
 */
proto.com.vectara.admin.User.prototype.getTsCreate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.User} returns this
 */
proto.com.vectara.admin.User.prototype.setTsCreate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 id_create = 7;
 * @return {number}
 */
proto.com.vectara.admin.User.prototype.getIdCreate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.User} returns this
 */
proto.com.vectara.admin.User.prototype.setIdCreate = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 ts_update = 8;
 * @return {number}
 */
proto.com.vectara.admin.User.prototype.getTsUpdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.User} returns this
 */
proto.com.vectara.admin.User.prototype.setTsUpdate = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 id_update = 9;
 * @return {number}
 */
proto.com.vectara.admin.User.prototype.getIdUpdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.User} returns this
 */
proto.com.vectara.admin.User.prototype.setIdUpdate = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string email = 10;
 * @return {string}
 */
proto.com.vectara.admin.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.User} returns this
 */
proto.com.vectara.admin.User.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional UserStatus user_status = 11;
 * @return {!proto.com.vectara.admin.UserStatus}
 */
proto.com.vectara.admin.User.prototype.getUserStatus = function() {
  return /** @type {!proto.com.vectara.admin.UserStatus} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.com.vectara.admin.UserStatus} value
 * @return {!proto.com.vectara.admin.User} returns this
 */
proto.com.vectara.admin.User.prototype.setUserStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * repeated CustomerRole role = 12;
 * @return {!Array<!proto.com.vectara.admin.CustomerRole>}
 */
proto.com.vectara.admin.User.prototype.getRoleList = function() {
  return /** @type {!Array<!proto.com.vectara.admin.CustomerRole>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<!proto.com.vectara.admin.CustomerRole>} value
 * @return {!proto.com.vectara.admin.User} returns this
 */
proto.com.vectara.admin.User.prototype.setRoleList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {!proto.com.vectara.admin.CustomerRole} value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.User} returns this
 */
proto.com.vectara.admin.User.prototype.addRole = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.User} returns this
 */
proto.com.vectara.admin.User.prototype.clearRoleList = function() {
  return this.setRoleList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ListUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ListUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ListUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpusId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    filter: jspb.Message.getFieldWithDefault(msg, 2, ""),
    listUsersType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageKey: msg.getPageKey_asB64(),
    numResults: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ListUsersRequest}
 */
proto.com.vectara.admin.ListUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ListUsersRequest;
  return proto.com.vectara.admin.ListUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ListUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ListUsersRequest}
 */
proto.com.vectara.admin.ListUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorpusId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilter(value);
      break;
    case 3:
      var value = /** @type {!proto.com.vectara.admin.ListUsersType} */ (reader.readEnum());
      msg.setListUsersType(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPageKey(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ListUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ListUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getListUsersType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPageKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getNumResults();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional uint32 corpus_id = 1;
 * @return {number}
 */
proto.com.vectara.admin.ListUsersRequest.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ListUsersRequest} returns this
 */
proto.com.vectara.admin.ListUsersRequest.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string filter = 2;
 * @return {string}
 */
proto.com.vectara.admin.ListUsersRequest.prototype.getFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ListUsersRequest} returns this
 */
proto.com.vectara.admin.ListUsersRequest.prototype.setFilter = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ListUsersType list_users_type = 3;
 * @return {!proto.com.vectara.admin.ListUsersType}
 */
proto.com.vectara.admin.ListUsersRequest.prototype.getListUsersType = function() {
  return /** @type {!proto.com.vectara.admin.ListUsersType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.com.vectara.admin.ListUsersType} value
 * @return {!proto.com.vectara.admin.ListUsersRequest} returns this
 */
proto.com.vectara.admin.ListUsersRequest.prototype.setListUsersType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bytes page_key = 4;
 * @return {string}
 */
proto.com.vectara.admin.ListUsersRequest.prototype.getPageKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes page_key = 4;
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {string}
 */
proto.com.vectara.admin.ListUsersRequest.prototype.getPageKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPageKey()));
};


/**
 * optional bytes page_key = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListUsersRequest.prototype.getPageKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPageKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.vectara.admin.ListUsersRequest} returns this
 */
proto.com.vectara.admin.ListUsersRequest.prototype.setPageKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional uint32 num_results = 5;
 * @return {number}
 */
proto.com.vectara.admin.ListUsersRequest.prototype.getNumResults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ListUsersRequest} returns this
 */
proto.com.vectara.admin.ListUsersRequest.prototype.setNumResults = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ListUsersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ListUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ListUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ListUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userList: jspb.Message.toObjectList(msg.getUserList(),
    proto.com.vectara.admin.User.toObject, includeInstance),
    pageKey: msg.getPageKey_asB64(),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ListUsersResponse}
 */
proto.com.vectara.admin.ListUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ListUsersResponse;
  return proto.com.vectara.admin.ListUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ListUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ListUsersResponse}
 */
proto.com.vectara.admin.ListUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.User;
      reader.readMessage(value,proto.com.vectara.admin.User.deserializeBinaryFromReader);
      msg.addUser(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPageKey(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ListUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ListUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.admin.User.serializeBinaryToWriter
    );
  }
  f = message.getPageKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * repeated User user = 1;
 * @return {!Array<!proto.com.vectara.admin.User>}
 */
proto.com.vectara.admin.ListUsersResponse.prototype.getUserList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.User, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.User>} value
 * @return {!proto.com.vectara.admin.ListUsersResponse} returns this
*/
proto.com.vectara.admin.ListUsersResponse.prototype.setUserList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.admin.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.User}
 */
proto.com.vectara.admin.ListUsersResponse.prototype.addUser = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.admin.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ListUsersResponse} returns this
 */
proto.com.vectara.admin.ListUsersResponse.prototype.clearUserList = function() {
  return this.setUserList([]);
};


/**
 * optional bytes page_key = 2;
 * @return {string}
 */
proto.com.vectara.admin.ListUsersResponse.prototype.getPageKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes page_key = 2;
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {string}
 */
proto.com.vectara.admin.ListUsersResponse.prototype.getPageKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPageKey()));
};


/**
 * optional bytes page_key = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListUsersResponse.prototype.getPageKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPageKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.vectara.admin.ListUsersResponse} returns this
 */
proto.com.vectara.admin.ListUsersResponse.prototype.setPageKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional com.vectara.Status status = 1000;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ListUsersResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ListUsersResponse} returns this
*/
proto.com.vectara.admin.ListUsersResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ListUsersResponse} returns this
 */
proto.com.vectara.admin.ListUsersResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ListUsersResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1000) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ManageUserRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ManageUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ManageUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ManageUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ManageUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userActionList: jspb.Message.toObjectList(msg.getUserActionList(),
    proto.com.vectara.admin.ManageUserRequest.UserAction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ManageUserRequest}
 */
proto.com.vectara.admin.ManageUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ManageUserRequest;
  return proto.com.vectara.admin.ManageUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ManageUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ManageUserRequest}
 */
proto.com.vectara.admin.ManageUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.ManageUserRequest.UserAction;
      reader.readMessage(value,proto.com.vectara.admin.ManageUserRequest.UserAction.deserializeBinaryFromReader);
      msg.addUserAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ManageUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ManageUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ManageUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ManageUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserActionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.admin.ManageUserRequest.UserAction.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ManageUserRequest.UserAction.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ManageUserRequest.UserAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ManageUserRequest.UserAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ManageUserRequest.UserAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.com.vectara.admin.User.toObject(includeInstance, f),
    userActionType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ManageUserRequest.UserAction}
 */
proto.com.vectara.admin.ManageUserRequest.UserAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ManageUserRequest.UserAction;
  return proto.com.vectara.admin.ManageUserRequest.UserAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ManageUserRequest.UserAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ManageUserRequest.UserAction}
 */
proto.com.vectara.admin.ManageUserRequest.UserAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.User;
      reader.readMessage(value,proto.com.vectara.admin.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {!proto.com.vectara.admin.UserActionType} */ (reader.readEnum());
      msg.setUserActionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ManageUserRequest.UserAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ManageUserRequest.UserAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ManageUserRequest.UserAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ManageUserRequest.UserAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.vectara.admin.User.serializeBinaryToWriter
    );
  }
  f = message.getUserActionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.com.vectara.admin.User}
 */
proto.com.vectara.admin.ManageUserRequest.UserAction.prototype.getUser = function() {
  return /** @type{?proto.com.vectara.admin.User} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.User, 1));
};


/**
 * @param {?proto.com.vectara.admin.User|undefined} value
 * @return {!proto.com.vectara.admin.ManageUserRequest.UserAction} returns this
*/
proto.com.vectara.admin.ManageUserRequest.UserAction.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ManageUserRequest.UserAction} returns this
 */
proto.com.vectara.admin.ManageUserRequest.UserAction.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ManageUserRequest.UserAction.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserActionType user_action_type = 2;
 * @return {!proto.com.vectara.admin.UserActionType}
 */
proto.com.vectara.admin.ManageUserRequest.UserAction.prototype.getUserActionType = function() {
  return /** @type {!proto.com.vectara.admin.UserActionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.vectara.admin.UserActionType} value
 * @return {!proto.com.vectara.admin.ManageUserRequest.UserAction} returns this
 */
proto.com.vectara.admin.ManageUserRequest.UserAction.prototype.setUserActionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated UserAction user_action = 1;
 * @return {!Array<!proto.com.vectara.admin.ManageUserRequest.UserAction>}
 */
proto.com.vectara.admin.ManageUserRequest.prototype.getUserActionList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.ManageUserRequest.UserAction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.ManageUserRequest.UserAction, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.ManageUserRequest.UserAction>} value
 * @return {!proto.com.vectara.admin.ManageUserRequest} returns this
*/
proto.com.vectara.admin.ManageUserRequest.prototype.setUserActionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.admin.ManageUserRequest.UserAction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.ManageUserRequest.UserAction}
 */
proto.com.vectara.admin.ManageUserRequest.prototype.addUserAction = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.admin.ManageUserRequest.UserAction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ManageUserRequest} returns this
 */
proto.com.vectara.admin.ManageUserRequest.prototype.clearUserActionList = function() {
  return this.setUserActionList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ManageUserResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ManageUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ManageUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ManageUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ManageUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    responseList: jspb.Message.toObjectList(msg.getResponseList(),
    proto.com.vectara.admin.ManageUserResponse.UserResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ManageUserResponse}
 */
proto.com.vectara.admin.ManageUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ManageUserResponse;
  return proto.com.vectara.admin.ManageUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ManageUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ManageUserResponse}
 */
proto.com.vectara.admin.ManageUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.ManageUserResponse.UserResponse;
      reader.readMessage(value,proto.com.vectara.admin.ManageUserResponse.UserResponse.deserializeBinaryFromReader);
      msg.addResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ManageUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ManageUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ManageUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ManageUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.admin.ManageUserResponse.UserResponse.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ManageUserResponse.UserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ManageUserResponse.UserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ManageUserResponse.UserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ManageUserResponse.UserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.com.vectara.admin.User.toObject(includeInstance, f),
    oneTimeCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    oneTimeLink: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ManageUserResponse.UserResponse}
 */
proto.com.vectara.admin.ManageUserResponse.UserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ManageUserResponse.UserResponse;
  return proto.com.vectara.admin.ManageUserResponse.UserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ManageUserResponse.UserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ManageUserResponse.UserResponse}
 */
proto.com.vectara.admin.ManageUserResponse.UserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.User;
      reader.readMessage(value,proto.com.vectara.admin.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOneTimeCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOneTimeLink(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ManageUserResponse.UserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ManageUserResponse.UserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ManageUserResponse.UserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ManageUserResponse.UserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.vectara.admin.User.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.com.vectara.admin.User}
 */
proto.com.vectara.admin.ManageUserResponse.UserResponse.prototype.getUser = function() {
  return /** @type{?proto.com.vectara.admin.User} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.User, 1));
};


/**
 * @param {?proto.com.vectara.admin.User|undefined} value
 * @return {!proto.com.vectara.admin.ManageUserResponse.UserResponse} returns this
*/
proto.com.vectara.admin.ManageUserResponse.UserResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ManageUserResponse.UserResponse} returns this
 */
proto.com.vectara.admin.ManageUserResponse.UserResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ManageUserResponse.UserResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string one_time_code = 2;
 * @return {string}
 */
proto.com.vectara.admin.ManageUserResponse.UserResponse.prototype.getOneTimeCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ManageUserResponse.UserResponse} returns this
 */
proto.com.vectara.admin.ManageUserResponse.UserResponse.prototype.setOneTimeCode = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.admin.ManageUserResponse.UserResponse} returns this
 */
proto.com.vectara.admin.ManageUserResponse.UserResponse.prototype.clearOneTimeCode = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ManageUserResponse.UserResponse.prototype.hasOneTimeCode = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string one_time_link = 3;
 * @return {string}
 */
proto.com.vectara.admin.ManageUserResponse.UserResponse.prototype.getOneTimeLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ManageUserResponse.UserResponse} returns this
 */
proto.com.vectara.admin.ManageUserResponse.UserResponse.prototype.setOneTimeLink = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.admin.ManageUserResponse.UserResponse} returns this
 */
proto.com.vectara.admin.ManageUserResponse.UserResponse.prototype.clearOneTimeLink = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ManageUserResponse.UserResponse.prototype.hasOneTimeLink = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional com.vectara.Status status = 1000;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ManageUserResponse.UserResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ManageUserResponse.UserResponse} returns this
*/
proto.com.vectara.admin.ManageUserResponse.UserResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ManageUserResponse.UserResponse} returns this
 */
proto.com.vectara.admin.ManageUserResponse.UserResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ManageUserResponse.UserResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1000) != null;
};


/**
 * repeated UserResponse response = 1;
 * @return {!Array<!proto.com.vectara.admin.ManageUserResponse.UserResponse>}
 */
proto.com.vectara.admin.ManageUserResponse.prototype.getResponseList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.ManageUserResponse.UserResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.ManageUserResponse.UserResponse, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.ManageUserResponse.UserResponse>} value
 * @return {!proto.com.vectara.admin.ManageUserResponse} returns this
*/
proto.com.vectara.admin.ManageUserResponse.prototype.setResponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.admin.ManageUserResponse.UserResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.ManageUserResponse.UserResponse}
 */
proto.com.vectara.admin.ManageUserResponse.prototype.addResponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.admin.ManageUserResponse.UserResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ManageUserResponse} returns this
 */
proto.com.vectara.admin.ManageUserResponse.prototype.clearResponseList = function() {
  return this.setResponseList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.vectara.admin.GetUserRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.com.vectara.admin.GetUserRequest.OneofUseridentifierCase = {
  ONEOF_USERIDENTIFIER_NOT_SET: 0,
  HANDLE: 1,
  ID: 2
};

/**
 * @return {proto.com.vectara.admin.GetUserRequest.OneofUseridentifierCase}
 */
proto.com.vectara.admin.GetUserRequest.prototype.getOneofUseridentifierCase = function() {
  return /** @type {proto.com.vectara.admin.GetUserRequest.OneofUseridentifierCase} */(jspb.Message.computeOneofCase(this, proto.com.vectara.admin.GetUserRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.GetUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.GetUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.GetUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.GetUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    handle: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.GetUserRequest}
 */
proto.com.vectara.admin.GetUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.GetUserRequest;
  return proto.com.vectara.admin.GetUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.GetUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.GetUserRequest}
 */
proto.com.vectara.admin.GetUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHandle(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.GetUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.GetUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.GetUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.GetUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string handle = 1;
 * @return {string}
 */
proto.com.vectara.admin.GetUserRequest.prototype.getHandle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.GetUserRequest} returns this
 */
proto.com.vectara.admin.GetUserRequest.prototype.setHandle = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.com.vectara.admin.GetUserRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.admin.GetUserRequest} returns this
 */
proto.com.vectara.admin.GetUserRequest.prototype.clearHandle = function() {
  return jspb.Message.setOneofField(this, 1, proto.com.vectara.admin.GetUserRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.GetUserRequest.prototype.hasHandle = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 id = 2;
 * @return {number}
 */
proto.com.vectara.admin.GetUserRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.GetUserRequest} returns this
 */
proto.com.vectara.admin.GetUserRequest.prototype.setId = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.com.vectara.admin.GetUserRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.admin.GetUserRequest} returns this
 */
proto.com.vectara.admin.GetUserRequest.prototype.clearId = function() {
  return jspb.Message.setOneofField(this, 2, proto.com.vectara.admin.GetUserRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.GetUserRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.GetUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.GetUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.GetUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.GetUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.com.vectara.admin.User.toObject(includeInstance, f),
    analyticsAuthToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.GetUserResponse}
 */
proto.com.vectara.admin.GetUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.GetUserResponse;
  return proto.com.vectara.admin.GetUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.GetUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.GetUserResponse}
 */
proto.com.vectara.admin.GetUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.User;
      reader.readMessage(value,proto.com.vectara.admin.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnalyticsAuthToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.GetUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.GetUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.GetUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.GetUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.vectara.admin.User.serializeBinaryToWriter
    );
  }
  f = message.getAnalyticsAuthToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.com.vectara.admin.User}
 */
proto.com.vectara.admin.GetUserResponse.prototype.getUser = function() {
  return /** @type{?proto.com.vectara.admin.User} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.User, 1));
};


/**
 * @param {?proto.com.vectara.admin.User|undefined} value
 * @return {!proto.com.vectara.admin.GetUserResponse} returns this
*/
proto.com.vectara.admin.GetUserResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.GetUserResponse} returns this
 */
proto.com.vectara.admin.GetUserResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.GetUserResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string analytics_auth_token = 2;
 * @return {string}
 */
proto.com.vectara.admin.GetUserResponse.prototype.getAnalyticsAuthToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.GetUserResponse} returns this
 */
proto.com.vectara.admin.GetUserResponse.prototype.setAnalyticsAuthToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.com.vectara.admin.UserType = {
  USER_TYPE__NONE: 0,
  USER_TYPE__USER: 1,
  USER_TYPE__FEDERATED_USER: 2,
  USER_TYPE__APP_CLIENT: 3
};

/**
 * @enum {number}
 */
proto.com.vectara.admin.UserStatus = {
  USER_STATUS__NONE: 0,
  USER_STATUS__ACTIVE: 1,
  USER_STATUS__DISABLED: 2
};

/**
 * @enum {number}
 */
proto.com.vectara.admin.ListUsersType = {
  LIST_USERS_TYPE__NONE: 0,
  LIST_USERS_TYPE__CORPUS: 1,
  LIST_USERS_TYPE__CUSTOMER: 2,
  LIST_USERS_TYPE__ALL: 3
};

/**
 * @enum {number}
 */
proto.com.vectara.admin.UserActionType = {
  USER_ACTION_TYPE__NONE: 0,
  USER_ACTION_TYPE__ADD: 1,
  USER_ACTION_TYPE__DELETE: 2,
  USER_ACTION_TYPE__DISABLE: 3,
  USER_ACTION_TYPE__ENABLE: 4,
  USER_ACTION_TYPE__RESET_PASSWORD: 5,
  USER_ACTION_TYPE__EDIT_ROLE: 6
};

goog.object.extend(exports, proto.com.vectara.admin);
