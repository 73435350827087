import {
  ListUsersRequest,
  ManageUserRequest,
  GetUserRequest,
  User,
  UserActionType,
  ListUsersType,
  ListUsersResponse,
  ManageUserResponse,
  GetUserResponse
} from "../generated_protos/admin/admin_user_pb";
import { AdminServicePromiseClient } from "../generated_protos/services_grpc_web_pb";
import { DeleteCustomerRequest } from "../generated_protos/admin/admin_signup_pb";
import { getDeadline } from "./getDeadline";
import { ExtendSessionRequest, ExtendSessionResponse } from "../generated_protos/admin/admin_account_pb";
import { apiV2Client } from "./apiV2Client";

export const sendDeleteUserRequest = async (jwt: string, customerId: string, username: string) => {
  const { data, error } = await apiV2Client.DELETE("/v2/users/{username}", {
    headers: {
      "customer-id": customerId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`
    },
    params: {
      path: {
        username: username
      }
    }
  });

  if (error) {
    throw error;
  }

  return data;
};

export const ExtendSession = (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string,
  sessionId: string
): Promise<ExtendSessionResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }
    const request = new ExtendSessionRequest();
    request.setSessionId(sessionId);
    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };

    adminService
      .extendSession(request, args)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from gRPC call: extendSession");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};

export const ManageUser = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string,
  user: User,
  actionType: UserActionType
): Promise<ManageUserResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const request = new ManageUserRequest();
    const userAction = new ManageUserRequest.UserAction();
    userAction.setUser(user);
    userAction.setUserActionType(actionType);
    request.getUserActionList().push(userAction);

    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };
    adminService
      .manageUser(request, args)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from gRPC call: manageUser");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};

export const ListUsers = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string,
  listUsersType: ListUsersType
): Promise<ListUsersResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const request = new ListUsersRequest();
    request.setListUsersType(listUsersType);

    const metadata = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt,
      deadline: getDeadline(5)
    };
    adminService
      .listUsers(request, metadata)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from gRPC call: listUsers");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};

export const GetUser = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string,
  // During registration, this is email.
  handle?: string,
  id?: number
): Promise<GetUserResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }

    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    if (!handle && !id) {
      throw new Error("GetUser requires either handle or id");
    }

    const request = new GetUserRequest();
    if (handle !== undefined) {
      request.setHandle(handle);
    } else if (id !== undefined) {
      request.setId(id);
    }

    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };

    adminService
      .getUser(request, args)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from gRPC call: getUser");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};

export const DeleteCustomer = async (jwt: string, adminService: AdminServicePromiseClient, customerId: string) => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }
    const request = new DeleteCustomerRequest();

    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };
    adminService
      .deleteCustomer(request, args)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from: DeleteCustomer");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};
