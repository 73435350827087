import { ChunkingStrategy } from "../contexts/UploadContext";
import { UploadConfig, UploadStatus } from "../types/corporaState";
import { isHttpResponseCodeError } from "../utils/isHttpResponseCodeError";
import { apiV2Client, UploadFileResponse } from "./apiV2Client";

export const uploadFile = async ({
  payload,
  onComplete,
  controller
}: {
  payload: {
    corpusKey: string;
    fileOrBlob: File | Blob;
    isExtractTablesEnabled: boolean;
    metadata?: string;
    chunkingStrategy: ChunkingStrategy;
    maxCharsPerChunk?: number;
  };
  onComplete: (params: { status: UploadStatus; data?: UploadFileResponse; error?: UploadConfig["error"] }) => void;
  controller?: AbortController;
}) => {
  const { corpusKey, fileOrBlob, isExtractTablesEnabled, metadata, chunkingStrategy, maxCharsPerChunk } = payload;

  const formData = new FormData();

  formData.append(
    "file",
    fileOrBlob,
    // file name needs to be url encoded to avoid issues with special characters
    encodeURIComponent(fileOrBlob instanceof File ? fileOrBlob.name : `file_${Date.now()}`)
  );

  if (metadata) {
    formData.append(
      "metadata",
      new Blob([metadata], {
        type: "application/json"
      })
    );
  }

  if (chunkingStrategy === "max_chars_chunking_strategy") {
    formData.append(
      "chunking_strategy",
      new Blob(
        [
          JSON.stringify({
            type: "max_chars_chunking_strategy",
            max_chars_per_chunk: maxCharsPerChunk
          })
        ],
        {
          type: "application/json"
        }
      )
    );
  }

  formData.append(
    "table_extraction_config",
    new Blob(
      [
        JSON.stringify({
          extract_tables: isExtractTablesEnabled && fileOrBlob.type === "application/pdf"
        })
      ],
      {
        type: "application/json"
      }
    )
  );

  const {
    data,
    error,
    response: { status }
  } = await apiV2Client.POST("/v2/corpora/{corpus_key}/upload_file", {
    fetch: (url, ...args) =>
      fetch(url, {
        ...args,
        signal: controller?.signal,
        // openapi-fetch doesn't support FormData as body, so we need to set it manually
        // more details: https://github.com/openapi-ts/openapi-typescript/issues/1214
        body: formData
      }),
    params: {
      path: { corpus_key: corpusKey }
    }
  });

  if (error) {
    onComplete({
      status: "error",
      error: {
        msg:
          Object.values((error as any).field_errors ?? {}).join(", ") ??
          error.messages?.join(", ") ??
          "Unexpected error",
        code: status
      }
    });
  } else {
    onComplete({ status: "success", data });
  }
};

export const uploadSampleData = async ({
  payload,
  onComplete
}: {
  payload: { corpusKey: string };
  onComplete: (params: {
    status: UploadStatus;
    data?: UploadFileResponse;
    error?: { code: number; msg: string };
  }) => void;
}) => {
  const response = await fetch("/files/black_hole.html", {
    method: "GET",
    headers: {
      Accept: "application/octet-stream"
    }
  });

  if (isHttpResponseCodeError(response.status)) {
    throw "Could not retrieve sample data.";
  }

  const data = await response.arrayBuffer();
  const blob = new Blob([data]);

  uploadFile({
    payload: {
      corpusKey: payload.corpusKey,
      fileOrBlob: blob,
      isExtractTablesEnabled: false,
      chunkingStrategy: "sentence_chunking_strategy"
    },
    onComplete
  });
};
