import { Reranker } from "../../apis/apiV2Client";
import { SLINGSHOT_RERANKER_ID } from "../../constants";
import { toV1Id } from "../../utils/idConversion";

export const selectReranker = (rerankers?: Reranker[]) => {
  const isSlingshotAvailable = Boolean(
    rerankers?.find((reranker) => toV1Id(reranker.id ?? "") === SLINGSHOT_RERANKER_ID)
  );
  return isSlingshotAvailable ? SLINGSHOT_RERANKER_ID : toV1Id(rerankers?.[0].id ?? "");
};
