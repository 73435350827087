/**
 * @fileoverview gRPC-Web generated client stub for com.vectara
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: services.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var admin_admin_account_pb = require('./admin/admin_account_pb.js')

var admin_admin_apikey_pb = require('./admin/admin_apikey_pb.js')

var admin_admin_corpus_pb = require('./admin/admin_corpus_pb.js')

var admin_admin_job_pb = require('./admin/admin_job_pb.js')

var admin_admin_metric_pb = require('./admin/admin_metric_pb.js')

var admin_admin_security_pb = require('./admin/admin_security_pb.js')

var admin_admin_user_pb = require('./admin/admin_user_pb.js')

var admin_admin_appclient_pb = require('./admin/admin_appclient_pb.js')

var admin_admin_billing_pb = require('./admin/admin_billing_pb.js')

var admin_admin_corpusauth_pb = require('./admin/admin_corpusauth_pb.js')

var admin_admin_encoder_pb = require('./admin/admin_encoder_pb.js')

var admin_admin_feature_pb = require('./admin/admin_feature_pb.js')

var admin_admin_permission_pb = require('./admin/admin_permission_pb.js')

var admin_admin_reranker_pb = require('./admin/admin_reranker_pb.js')

var admin_admin_signup_pb = require('./admin/admin_signup_pb.js')

var admin_admin_stats_pb = require('./admin/admin_stats_pb.js')

var admin_admin_summarizer_pb = require('./admin/admin_summarizer_pb.js')

var chat_pb = require('./chat_pb.js')

var common_pb = require('./common_pb.js')

var indexing_pb = require('./indexing_pb.js')

var serving_pb = require('./serving_pb.js')

var status_pb = require('./status_pb.js')

var list_documents_pb = require('./list_documents_pb.js')

var google_api_annotations_pb = require('./google/api/annotations_pb.js')
const proto = {};
proto.com = {};
proto.com.vectara = require('./services_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.vectara.IndexServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.vectara.IndexServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.IndexDocumentRequest,
 *   !proto.com.vectara.IndexDocumentResponse>}
 */
const methodDescriptor_IndexService_Index = new grpc.web.MethodDescriptor(
  '/com.vectara.IndexService/Index',
  grpc.web.MethodType.UNARY,
  proto.com.vectara.IndexDocumentRequest,
  proto.com.vectara.IndexDocumentResponse,
  /**
   * @param {!proto.com.vectara.IndexDocumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.vectara.IndexDocumentResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.IndexDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.IndexDocumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.IndexDocumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.IndexServiceClient.prototype.index =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.IndexService/Index',
      request,
      metadata || {},
      methodDescriptor_IndexService_Index,
      callback);
};


/**
 * @param {!proto.com.vectara.IndexDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.IndexDocumentResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.IndexServicePromiseClient.prototype.index =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.IndexService/Index',
      request,
      metadata || {},
      methodDescriptor_IndexService_Index);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.DeleteDocumentRequest,
 *   !proto.com.vectara.DeleteDocumentResponse>}
 */
const methodDescriptor_IndexService_Delete = new grpc.web.MethodDescriptor(
  '/com.vectara.IndexService/Delete',
  grpc.web.MethodType.UNARY,
  common_pb.DeleteDocumentRequest,
  common_pb.DeleteDocumentResponse,
  /**
   * @param {!proto.com.vectara.DeleteDocumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.DeleteDocumentResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.DeleteDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.DeleteDocumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.DeleteDocumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.IndexServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.IndexService/Delete',
      request,
      metadata || {},
      methodDescriptor_IndexService_Delete,
      callback);
};


/**
 * @param {!proto.com.vectara.DeleteDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.DeleteDocumentResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.IndexServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.IndexService/Delete',
      request,
      metadata || {},
      methodDescriptor_IndexService_Delete);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.vectara.QueryServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.vectara.QueryServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.serving.BatchQueryRequest,
 *   !proto.com.vectara.serving.BatchQueryResponse>}
 */
const methodDescriptor_QueryService_Query = new grpc.web.MethodDescriptor(
  '/com.vectara.QueryService/Query',
  grpc.web.MethodType.UNARY,
  serving_pb.BatchQueryRequest,
  serving_pb.BatchQueryResponse,
  /**
   * @param {!proto.com.vectara.serving.BatchQueryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  serving_pb.BatchQueryResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.serving.BatchQueryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.serving.BatchQueryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.serving.BatchQueryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.QueryServiceClient.prototype.query =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.QueryService/Query',
      request,
      metadata || {},
      methodDescriptor_QueryService_Query,
      callback);
};


/**
 * @param {!proto.com.vectara.serving.BatchQueryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.serving.BatchQueryResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.QueryServicePromiseClient.prototype.query =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.QueryService/Query',
      request,
      metadata || {},
      methodDescriptor_QueryService_Query);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.serving.BatchQueryRequest,
 *   !proto.com.vectara.serving.QueryResponsePart>}
 */
const methodDescriptor_QueryService_StreamQuery = new grpc.web.MethodDescriptor(
  '/com.vectara.QueryService/StreamQuery',
  grpc.web.MethodType.SERVER_STREAMING,
  serving_pb.BatchQueryRequest,
  serving_pb.QueryResponsePart,
  /**
   * @param {!proto.com.vectara.serving.BatchQueryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  serving_pb.QueryResponsePart.deserializeBinary
);


/**
 * @param {!proto.com.vectara.serving.BatchQueryRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.serving.QueryResponsePart>}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.QueryServiceClient.prototype.streamQuery =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/com.vectara.QueryService/StreamQuery',
      request,
      metadata || {},
      methodDescriptor_QueryService_StreamQuery);
};


/**
 * @param {!proto.com.vectara.serving.BatchQueryRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.serving.QueryResponsePart>}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.QueryServicePromiseClient.prototype.streamQuery =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/com.vectara.QueryService/StreamQuery',
      request,
      metadata || {},
      methodDescriptor_QueryService_StreamQuery);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.vectara.PublicAdminServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.vectara.PublicAdminServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.LoginRedirectRequest,
 *   !proto.com.vectara.admin.LoginRedirectResponse>}
 */
const methodDescriptor_PublicAdminService_LoginRedirect = new grpc.web.MethodDescriptor(
  '/com.vectara.PublicAdminService/LoginRedirect',
  grpc.web.MethodType.UNARY,
  admin_admin_security_pb.LoginRedirectRequest,
  admin_admin_security_pb.LoginRedirectResponse,
  /**
   * @param {!proto.com.vectara.admin.LoginRedirectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_security_pb.LoginRedirectResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.LoginRedirectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.LoginRedirectResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.LoginRedirectResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.PublicAdminServiceClient.prototype.loginRedirect =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.PublicAdminService/LoginRedirect',
      request,
      metadata || {},
      methodDescriptor_PublicAdminService_LoginRedirect,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.LoginRedirectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.LoginRedirectResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.PublicAdminServicePromiseClient.prototype.loginRedirect =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.PublicAdminService/LoginRedirect',
      request,
      metadata || {},
      methodDescriptor_PublicAdminService_LoginRedirect);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest,
 *   !proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse>}
 */
const methodDescriptor_PublicAdminService_InitiatePasswordRecoveryFlow = new grpc.web.MethodDescriptor(
  '/com.vectara.PublicAdminService/InitiatePasswordRecoveryFlow',
  grpc.web.MethodType.UNARY,
  admin_admin_account_pb.InitiatePasswordRecoveryFlowRequest,
  admin_admin_account_pb.InitiatePasswordRecoveryFlowResponse,
  /**
   * @param {!proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_account_pb.InitiatePasswordRecoveryFlowResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.PublicAdminServiceClient.prototype.initiatePasswordRecoveryFlow =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.PublicAdminService/InitiatePasswordRecoveryFlow',
      request,
      metadata || {},
      methodDescriptor_PublicAdminService_InitiatePasswordRecoveryFlow,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.PublicAdminServicePromiseClient.prototype.initiatePasswordRecoveryFlow =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.PublicAdminService/InitiatePasswordRecoveryFlow',
      request,
      metadata || {},
      methodDescriptor_PublicAdminService_InitiatePasswordRecoveryFlow);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.GetCustomerLoginAttribsRequest,
 *   !proto.com.vectara.admin.GetCustomerLoginAttribsResponse>}
 */
const methodDescriptor_PublicAdminService_GetCustomerLoginAttribs = new grpc.web.MethodDescriptor(
  '/com.vectara.PublicAdminService/GetCustomerLoginAttribs',
  grpc.web.MethodType.UNARY,
  admin_admin_account_pb.GetCustomerLoginAttribsRequest,
  admin_admin_account_pb.GetCustomerLoginAttribsResponse,
  /**
   * @param {!proto.com.vectara.admin.GetCustomerLoginAttribsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_account_pb.GetCustomerLoginAttribsResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.GetCustomerLoginAttribsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.GetCustomerLoginAttribsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.GetCustomerLoginAttribsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.PublicAdminServiceClient.prototype.getCustomerLoginAttribs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.PublicAdminService/GetCustomerLoginAttribs',
      request,
      metadata || {},
      methodDescriptor_PublicAdminService_GetCustomerLoginAttribs,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.GetCustomerLoginAttribsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.GetCustomerLoginAttribsResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.PublicAdminServicePromiseClient.prototype.getCustomerLoginAttribs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.PublicAdminService/GetCustomerLoginAttribs',
      request,
      metadata || {},
      methodDescriptor_PublicAdminService_GetCustomerLoginAttribs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.RegisterCustomerRequest,
 *   !proto.com.vectara.admin.RegisterCustomerResponse>}
 */
const methodDescriptor_PublicAdminService_RegisterCustomer = new grpc.web.MethodDescriptor(
  '/com.vectara.PublicAdminService/RegisterCustomer',
  grpc.web.MethodType.UNARY,
  admin_admin_signup_pb.RegisterCustomerRequest,
  admin_admin_signup_pb.RegisterCustomerResponse,
  /**
   * @param {!proto.com.vectara.admin.RegisterCustomerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_signup_pb.RegisterCustomerResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.RegisterCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.RegisterCustomerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.RegisterCustomerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.PublicAdminServiceClient.prototype.registerCustomer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.PublicAdminService/RegisterCustomer',
      request,
      metadata || {},
      methodDescriptor_PublicAdminService_RegisterCustomer,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.RegisterCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.RegisterCustomerResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.PublicAdminServicePromiseClient.prototype.registerCustomer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.PublicAdminService/RegisterCustomer',
      request,
      metadata || {},
      methodDescriptor_PublicAdminService_RegisterCustomer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.SocialLoginRequest,
 *   !proto.com.vectara.admin.SocialLoginResponse>}
 */
const methodDescriptor_PublicAdminService_LoginSocial = new grpc.web.MethodDescriptor(
  '/com.vectara.PublicAdminService/LoginSocial',
  grpc.web.MethodType.UNARY,
  admin_admin_signup_pb.SocialLoginRequest,
  admin_admin_signup_pb.SocialLoginResponse,
  /**
   * @param {!proto.com.vectara.admin.SocialLoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_signup_pb.SocialLoginResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.SocialLoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.SocialLoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.SocialLoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.PublicAdminServiceClient.prototype.loginSocial =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.PublicAdminService/LoginSocial',
      request,
      metadata || {},
      methodDescriptor_PublicAdminService_LoginSocial,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.SocialLoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.SocialLoginResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.PublicAdminServicePromiseClient.prototype.loginSocial =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.PublicAdminService/LoginSocial',
      request,
      metadata || {},
      methodDescriptor_PublicAdminService_LoginSocial);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ValidateEmailRequest,
 *   !proto.com.vectara.admin.ValidateEmailResponse>}
 */
const methodDescriptor_PublicAdminService_ValidateEmail = new grpc.web.MethodDescriptor(
  '/com.vectara.PublicAdminService/ValidateEmail',
  grpc.web.MethodType.UNARY,
  admin_admin_signup_pb.ValidateEmailRequest,
  admin_admin_signup_pb.ValidateEmailResponse,
  /**
   * @param {!proto.com.vectara.admin.ValidateEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_signup_pb.ValidateEmailResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ValidateEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ValidateEmailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ValidateEmailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.PublicAdminServiceClient.prototype.validateEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.PublicAdminService/ValidateEmail',
      request,
      metadata || {},
      methodDescriptor_PublicAdminService_ValidateEmail,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ValidateEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ValidateEmailResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.PublicAdminServicePromiseClient.prototype.validateEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.PublicAdminService/ValidateEmail',
      request,
      metadata || {},
      methodDescriptor_PublicAdminService_ValidateEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.CheckEmailValidationStatusRequest,
 *   !proto.com.vectara.admin.CheckEmailValidationStatusResponse>}
 */
const methodDescriptor_PublicAdminService_CheckEmailValidationStatus = new grpc.web.MethodDescriptor(
  '/com.vectara.PublicAdminService/CheckEmailValidationStatus',
  grpc.web.MethodType.UNARY,
  admin_admin_signup_pb.CheckEmailValidationStatusRequest,
  admin_admin_signup_pb.CheckEmailValidationStatusResponse,
  /**
   * @param {!proto.com.vectara.admin.CheckEmailValidationStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_signup_pb.CheckEmailValidationStatusResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.CheckEmailValidationStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.CheckEmailValidationStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.CheckEmailValidationStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.PublicAdminServiceClient.prototype.checkEmailValidationStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.PublicAdminService/CheckEmailValidationStatus',
      request,
      metadata || {},
      methodDescriptor_PublicAdminService_CheckEmailValidationStatus,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.CheckEmailValidationStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.CheckEmailValidationStatusResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.PublicAdminServicePromiseClient.prototype.checkEmailValidationStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.PublicAdminService/CheckEmailValidationStatus',
      request,
      metadata || {},
      methodDescriptor_PublicAdminService_CheckEmailValidationStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ReadCustomerPlanDetailsRequest,
 *   !proto.com.vectara.admin.ReadCustomerPlanDetailsResponse>}
 */
const methodDescriptor_PublicAdminService_ReadCustomerPlanDetails = new grpc.web.MethodDescriptor(
  '/com.vectara.PublicAdminService/ReadCustomerPlanDetails',
  grpc.web.MethodType.UNARY,
  admin_admin_signup_pb.ReadCustomerPlanDetailsRequest,
  admin_admin_signup_pb.ReadCustomerPlanDetailsResponse,
  /**
   * @param {!proto.com.vectara.admin.ReadCustomerPlanDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_signup_pb.ReadCustomerPlanDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ReadCustomerPlanDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ReadCustomerPlanDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ReadCustomerPlanDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.PublicAdminServiceClient.prototype.readCustomerPlanDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.PublicAdminService/ReadCustomerPlanDetails',
      request,
      metadata || {},
      methodDescriptor_PublicAdminService_ReadCustomerPlanDetails,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ReadCustomerPlanDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ReadCustomerPlanDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.PublicAdminServicePromiseClient.prototype.readCustomerPlanDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.PublicAdminService/ReadCustomerPlanDetails',
      request,
      metadata || {},
      methodDescriptor_PublicAdminService_ReadCustomerPlanDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ReadCurrentIdForPlanRequest,
 *   !proto.com.vectara.admin.ReadCurrentIdForPlanResponse>}
 */
const methodDescriptor_PublicAdminService_ReadCurrentIdForPlan = new grpc.web.MethodDescriptor(
  '/com.vectara.PublicAdminService/ReadCurrentIdForPlan',
  grpc.web.MethodType.UNARY,
  admin_admin_signup_pb.ReadCurrentIdForPlanRequest,
  admin_admin_signup_pb.ReadCurrentIdForPlanResponse,
  /**
   * @param {!proto.com.vectara.admin.ReadCurrentIdForPlanRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_signup_pb.ReadCurrentIdForPlanResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ReadCurrentIdForPlanRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ReadCurrentIdForPlanResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ReadCurrentIdForPlanResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.PublicAdminServiceClient.prototype.readCurrentIdForPlan =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.PublicAdminService/ReadCurrentIdForPlan',
      request,
      metadata || {},
      methodDescriptor_PublicAdminService_ReadCurrentIdForPlan,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ReadCurrentIdForPlanRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ReadCurrentIdForPlanResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.PublicAdminServicePromiseClient.prototype.readCurrentIdForPlan =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.PublicAdminService/ReadCurrentIdForPlan',
      request,
      metadata || {},
      methodDescriptor_PublicAdminService_ReadCurrentIdForPlan);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.vectara.AdminServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.vectara.AdminServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.CreateCorpusRequest,
 *   !proto.com.vectara.admin.CreateCorpusResponse>}
 */
const methodDescriptor_AdminService_CreateCorpus = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/CreateCorpus',
  grpc.web.MethodType.UNARY,
  admin_admin_corpus_pb.CreateCorpusRequest,
  admin_admin_corpus_pb.CreateCorpusResponse,
  /**
   * @param {!proto.com.vectara.admin.CreateCorpusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_corpus_pb.CreateCorpusResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.CreateCorpusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.CreateCorpusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.CreateCorpusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.createCorpus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/CreateCorpus',
      request,
      metadata || {},
      methodDescriptor_AdminService_CreateCorpus,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.CreateCorpusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.CreateCorpusResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.createCorpus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/CreateCorpus',
      request,
      metadata || {},
      methodDescriptor_AdminService_CreateCorpus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.UpdateCorpusRequest,
 *   !proto.com.vectara.admin.UpdateCorpusResponse>}
 */
const methodDescriptor_AdminService_UpdateCorpus = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/UpdateCorpus',
  grpc.web.MethodType.UNARY,
  admin_admin_corpus_pb.UpdateCorpusRequest,
  admin_admin_corpus_pb.UpdateCorpusResponse,
  /**
   * @param {!proto.com.vectara.admin.UpdateCorpusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_corpus_pb.UpdateCorpusResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.UpdateCorpusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.UpdateCorpusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.UpdateCorpusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.updateCorpus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/UpdateCorpus',
      request,
      metadata || {},
      methodDescriptor_AdminService_UpdateCorpus,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.UpdateCorpusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.UpdateCorpusResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.updateCorpus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/UpdateCorpus',
      request,
      metadata || {},
      methodDescriptor_AdminService_UpdateCorpus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.DeleteCorpusRequest,
 *   !proto.com.vectara.admin.DeleteCorpusResponse>}
 */
const methodDescriptor_AdminService_DeleteCorpus = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/DeleteCorpus',
  grpc.web.MethodType.UNARY,
  admin_admin_corpus_pb.DeleteCorpusRequest,
  admin_admin_corpus_pb.DeleteCorpusResponse,
  /**
   * @param {!proto.com.vectara.admin.DeleteCorpusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_corpus_pb.DeleteCorpusResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.DeleteCorpusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.DeleteCorpusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.DeleteCorpusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.deleteCorpus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/DeleteCorpus',
      request,
      metadata || {},
      methodDescriptor_AdminService_DeleteCorpus,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.DeleteCorpusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.DeleteCorpusResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.deleteCorpus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/DeleteCorpus',
      request,
      metadata || {},
      methodDescriptor_AdminService_DeleteCorpus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ResetCorpusRequest,
 *   !proto.com.vectara.admin.ResetCorpusResponse>}
 */
const methodDescriptor_AdminService_ResetCorpus = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ResetCorpus',
  grpc.web.MethodType.UNARY,
  admin_admin_corpus_pb.ResetCorpusRequest,
  admin_admin_corpus_pb.ResetCorpusResponse,
  /**
   * @param {!proto.com.vectara.admin.ResetCorpusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_corpus_pb.ResetCorpusResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ResetCorpusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ResetCorpusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ResetCorpusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.resetCorpus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ResetCorpus',
      request,
      metadata || {},
      methodDescriptor_AdminService_ResetCorpus,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ResetCorpusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ResetCorpusResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.resetCorpus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ResetCorpus',
      request,
      metadata || {},
      methodDescriptor_AdminService_ResetCorpus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ListCorporaRequest,
 *   !proto.com.vectara.admin.ListCorporaResponse>}
 */
const methodDescriptor_AdminService_ListCorpora = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ListCorpora',
  grpc.web.MethodType.UNARY,
  admin_admin_corpus_pb.ListCorporaRequest,
  admin_admin_corpus_pb.ListCorporaResponse,
  /**
   * @param {!proto.com.vectara.admin.ListCorporaRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_corpus_pb.ListCorporaResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ListCorporaRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ListCorporaResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ListCorporaResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.listCorpora =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ListCorpora',
      request,
      metadata || {},
      methodDescriptor_AdminService_ListCorpora,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ListCorporaRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ListCorporaResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.listCorpora =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ListCorpora',
      request,
      metadata || {},
      methodDescriptor_AdminService_ListCorpora);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ListEncodersRequest,
 *   !proto.com.vectara.admin.ListEncodersResponse>}
 */
const methodDescriptor_AdminService_ListEncoders = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ListEncoders',
  grpc.web.MethodType.UNARY,
  admin_admin_encoder_pb.ListEncodersRequest,
  admin_admin_encoder_pb.ListEncodersResponse,
  /**
   * @param {!proto.com.vectara.admin.ListEncodersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_encoder_pb.ListEncodersResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ListEncodersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ListEncodersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ListEncodersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.listEncoders =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ListEncoders',
      request,
      metadata || {},
      methodDescriptor_AdminService_ListEncoders,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ListEncodersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ListEncodersResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.listEncoders =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ListEncoders',
      request,
      metadata || {},
      methodDescriptor_AdminService_ListEncoders);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ListJobsRequest,
 *   !proto.com.vectara.admin.ListJobsResponse>}
 */
const methodDescriptor_AdminService_ListJobs = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ListJobs',
  grpc.web.MethodType.UNARY,
  admin_admin_job_pb.ListJobsRequest,
  admin_admin_job_pb.ListJobsResponse,
  /**
   * @param {!proto.com.vectara.admin.ListJobsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_job_pb.ListJobsResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ListJobsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ListJobsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ListJobsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.listJobs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ListJobs',
      request,
      metadata || {},
      methodDescriptor_AdminService_ListJobs,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ListJobsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ListJobsResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.listJobs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ListJobs',
      request,
      metadata || {},
      methodDescriptor_AdminService_ListJobs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ListSummarizersRequest,
 *   !proto.com.vectara.admin.ListSummarizersResponse>}
 */
const methodDescriptor_AdminService_ListSummarizers = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ListSummarizers',
  grpc.web.MethodType.UNARY,
  admin_admin_summarizer_pb.ListSummarizersRequest,
  admin_admin_summarizer_pb.ListSummarizersResponse,
  /**
   * @param {!proto.com.vectara.admin.ListSummarizersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_summarizer_pb.ListSummarizersResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ListSummarizersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ListSummarizersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ListSummarizersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.listSummarizers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ListSummarizers',
      request,
      metadata || {},
      methodDescriptor_AdminService_ListSummarizers,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ListSummarizersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ListSummarizersResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.listSummarizers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ListSummarizers',
      request,
      metadata || {},
      methodDescriptor_AdminService_ListSummarizers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ListRerankersRequest,
 *   !proto.com.vectara.admin.ListRerankersResponse>}
 */
const methodDescriptor_AdminService_ListRerankers = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ListRerankers',
  grpc.web.MethodType.UNARY,
  admin_admin_reranker_pb.ListRerankersRequest,
  admin_admin_reranker_pb.ListRerankersResponse,
  /**
   * @param {!proto.com.vectara.admin.ListRerankersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_reranker_pb.ListRerankersResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ListRerankersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ListRerankersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ListRerankersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.listRerankers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ListRerankers',
      request,
      metadata || {},
      methodDescriptor_AdminService_ListRerankers,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ListRerankersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ListRerankersResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.listRerankers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ListRerankers',
      request,
      metadata || {},
      methodDescriptor_AdminService_ListRerankers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.SleepRequest,
 *   !proto.com.vectara.admin.SleepResponse>}
 */
const methodDescriptor_AdminService_Sleep = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/Sleep',
  grpc.web.MethodType.UNARY,
  admin_admin_account_pb.SleepRequest,
  admin_admin_account_pb.SleepResponse,
  /**
   * @param {!proto.com.vectara.admin.SleepRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_account_pb.SleepResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.SleepRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.SleepResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.SleepResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.sleep =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/Sleep',
      request,
      metadata || {},
      methodDescriptor_AdminService_Sleep,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.SleepRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.SleepResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.sleep =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/Sleep',
      request,
      metadata || {},
      methodDescriptor_AdminService_Sleep);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.CorpusInfoRequest,
 *   !proto.com.vectara.admin.CorpusInfoResponse>}
 */
const methodDescriptor_AdminService_CorpusInfo = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/CorpusInfo',
  grpc.web.MethodType.UNARY,
  admin_admin_corpus_pb.CorpusInfoRequest,
  admin_admin_corpus_pb.CorpusInfoResponse,
  /**
   * @param {!proto.com.vectara.admin.CorpusInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_corpus_pb.CorpusInfoResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.CorpusInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.CorpusInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.CorpusInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.corpusInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/CorpusInfo',
      request,
      metadata || {},
      methodDescriptor_AdminService_CorpusInfo,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.CorpusInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.CorpusInfoResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.corpusInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/CorpusInfo',
      request,
      metadata || {},
      methodDescriptor_AdminService_CorpusInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ReadCorpusRequest,
 *   !proto.com.vectara.admin.ReadCorpusResponse>}
 */
const methodDescriptor_AdminService_ReadCorpus = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ReadCorpus',
  grpc.web.MethodType.UNARY,
  admin_admin_corpus_pb.ReadCorpusRequest,
  admin_admin_corpus_pb.ReadCorpusResponse,
  /**
   * @param {!proto.com.vectara.admin.ReadCorpusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_corpus_pb.ReadCorpusResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ReadCorpusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ReadCorpusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ReadCorpusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.readCorpus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ReadCorpus',
      request,
      metadata || {},
      methodDescriptor_AdminService_ReadCorpus,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ReadCorpusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ReadCorpusResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.readCorpus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ReadCorpus',
      request,
      metadata || {},
      methodDescriptor_AdminService_ReadCorpus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ReadCorpusSizeRequest,
 *   !proto.com.vectara.admin.ReadCorpusSizeResponse>}
 */
const methodDescriptor_AdminService_ReadCorpusSize = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ReadCorpusSize',
  grpc.web.MethodType.UNARY,
  admin_admin_corpus_pb.ReadCorpusSizeRequest,
  admin_admin_corpus_pb.ReadCorpusSizeResponse,
  /**
   * @param {!proto.com.vectara.admin.ReadCorpusSizeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_corpus_pb.ReadCorpusSizeResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ReadCorpusSizeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ReadCorpusSizeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ReadCorpusSizeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.readCorpusSize =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ReadCorpusSize',
      request,
      metadata || {},
      methodDescriptor_AdminService_ReadCorpusSize,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ReadCorpusSizeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ReadCorpusSizeResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.readCorpusSize =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ReadCorpusSize',
      request,
      metadata || {},
      methodDescriptor_AdminService_ReadCorpusSize);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ComputeCorpusSizeRequest,
 *   !proto.com.vectara.admin.ComputeCorpusSizeResponse>}
 */
const methodDescriptor_AdminService_ComputeCorpusSize = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ComputeCorpusSize',
  grpc.web.MethodType.UNARY,
  admin_admin_corpus_pb.ComputeCorpusSizeRequest,
  admin_admin_corpus_pb.ComputeCorpusSizeResponse,
  /**
   * @param {!proto.com.vectara.admin.ComputeCorpusSizeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_corpus_pb.ComputeCorpusSizeResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ComputeCorpusSizeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ComputeCorpusSizeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ComputeCorpusSizeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.computeCorpusSize =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ComputeCorpusSize',
      request,
      metadata || {},
      methodDescriptor_AdminService_ComputeCorpusSize,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ComputeCorpusSizeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ComputeCorpusSizeResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.computeCorpusSize =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ComputeCorpusSize',
      request,
      metadata || {},
      methodDescriptor_AdminService_ComputeCorpusSize);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ComputeAccountSizeRequest,
 *   !proto.com.vectara.admin.ComputeAccountSizeResponse>}
 */
const methodDescriptor_AdminService_ComputeAccountSize = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ComputeAccountSize',
  grpc.web.MethodType.UNARY,
  admin_admin_account_pb.ComputeAccountSizeRequest,
  admin_admin_account_pb.ComputeAccountSizeResponse,
  /**
   * @param {!proto.com.vectara.admin.ComputeAccountSizeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_account_pb.ComputeAccountSizeResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ComputeAccountSizeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ComputeAccountSizeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ComputeAccountSizeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.computeAccountSize =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ComputeAccountSize',
      request,
      metadata || {},
      methodDescriptor_AdminService_ComputeAccountSize,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ComputeAccountSizeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ComputeAccountSizeResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.computeAccountSize =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ComputeAccountSize',
      request,
      metadata || {},
      methodDescriptor_AdminService_ComputeAccountSize);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ReadAccountSizeRequest,
 *   !proto.com.vectara.admin.ReadAccountSizeResponse>}
 */
const methodDescriptor_AdminService_ReadAccountSize = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ReadAccountSize',
  grpc.web.MethodType.UNARY,
  admin_admin_account_pb.ReadAccountSizeRequest,
  admin_admin_account_pb.ReadAccountSizeResponse,
  /**
   * @param {!proto.com.vectara.admin.ReadAccountSizeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_account_pb.ReadAccountSizeResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ReadAccountSizeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ReadAccountSizeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ReadAccountSizeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.readAccountSize =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ReadAccountSize',
      request,
      metadata || {},
      methodDescriptor_AdminService_ReadAccountSize,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ReadAccountSizeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ReadAccountSizeResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.readAccountSize =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ReadAccountSize',
      request,
      metadata || {},
      methodDescriptor_AdminService_ReadAccountSize);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ReadCorpusRecallRequest,
 *   !proto.com.vectara.admin.ReadCorpusRecallResponse>}
 */
const methodDescriptor_AdminService_ReadCorpusRecall = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ReadCorpusRecall',
  grpc.web.MethodType.UNARY,
  admin_admin_corpus_pb.ReadCorpusRecallRequest,
  admin_admin_corpus_pb.ReadCorpusRecallResponse,
  /**
   * @param {!proto.com.vectara.admin.ReadCorpusRecallRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_corpus_pb.ReadCorpusRecallResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ReadCorpusRecallRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ReadCorpusRecallResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ReadCorpusRecallResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.readCorpusRecall =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ReadCorpusRecall',
      request,
      metadata || {},
      methodDescriptor_AdminService_ReadCorpusRecall,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ReadCorpusRecallRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ReadCorpusRecallResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.readCorpusRecall =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ReadCorpusRecall',
      request,
      metadata || {},
      methodDescriptor_AdminService_ReadCorpusRecall);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ListUsersRequest,
 *   !proto.com.vectara.admin.ListUsersResponse>}
 */
const methodDescriptor_AdminService_ListUsers = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ListUsers',
  grpc.web.MethodType.UNARY,
  admin_admin_user_pb.ListUsersRequest,
  admin_admin_user_pb.ListUsersResponse,
  /**
   * @param {!proto.com.vectara.admin.ListUsersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_user_pb.ListUsersResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ListUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ListUsersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ListUsersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.listUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ListUsers',
      request,
      metadata || {},
      methodDescriptor_AdminService_ListUsers,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ListUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ListUsersResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.listUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ListUsers',
      request,
      metadata || {},
      methodDescriptor_AdminService_ListUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ManageUserRequest,
 *   !proto.com.vectara.admin.ManageUserResponse>}
 */
const methodDescriptor_AdminService_ManageUser = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ManageUser',
  grpc.web.MethodType.UNARY,
  admin_admin_user_pb.ManageUserRequest,
  admin_admin_user_pb.ManageUserResponse,
  /**
   * @param {!proto.com.vectara.admin.ManageUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_user_pb.ManageUserResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ManageUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ManageUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ManageUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.manageUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ManageUser',
      request,
      metadata || {},
      methodDescriptor_AdminService_ManageUser,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ManageUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ManageUserResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.manageUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ManageUser',
      request,
      metadata || {},
      methodDescriptor_AdminService_ManageUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ComputeRecallRequest,
 *   !proto.com.vectara.admin.ComputeRecallResponse>}
 */
const methodDescriptor_AdminService_ComputeCorpusRecall = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ComputeCorpusRecall',
  grpc.web.MethodType.SERVER_STREAMING,
  admin_admin_corpus_pb.ComputeRecallRequest,
  admin_admin_corpus_pb.ComputeRecallResponse,
  /**
   * @param {!proto.com.vectara.admin.ComputeRecallRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_corpus_pb.ComputeRecallResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ComputeRecallRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ComputeRecallResponse>}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.computeCorpusRecall =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/com.vectara.AdminService/ComputeCorpusRecall',
      request,
      metadata || {},
      methodDescriptor_AdminService_ComputeCorpusRecall);
};


/**
 * @param {!proto.com.vectara.admin.ComputeRecallRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ComputeRecallResponse>}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServicePromiseClient.prototype.computeCorpusRecall =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/com.vectara.AdminService/ComputeCorpusRecall',
      request,
      metadata || {},
      methodDescriptor_AdminService_ComputeCorpusRecall);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ComputeBackgroundRequest,
 *   !proto.com.vectara.admin.ComputeBackgroundResponse>}
 */
const methodDescriptor_AdminService_ComputeCorpusBackground = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ComputeCorpusBackground',
  grpc.web.MethodType.SERVER_STREAMING,
  admin_admin_corpus_pb.ComputeBackgroundRequest,
  admin_admin_corpus_pb.ComputeBackgroundResponse,
  /**
   * @param {!proto.com.vectara.admin.ComputeBackgroundRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_corpus_pb.ComputeBackgroundResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ComputeBackgroundRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ComputeBackgroundResponse>}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.computeCorpusBackground =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/com.vectara.AdminService/ComputeCorpusBackground',
      request,
      metadata || {},
      methodDescriptor_AdminService_ComputeCorpusBackground);
};


/**
 * @param {!proto.com.vectara.admin.ComputeBackgroundRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ComputeBackgroundResponse>}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServicePromiseClient.prototype.computeCorpusBackground =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/com.vectara.AdminService/ComputeCorpusBackground',
      request,
      metadata || {},
      methodDescriptor_AdminService_ComputeCorpusBackground);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.RoleRequestList,
 *   !proto.com.vectara.admin.RoleResponseList>}
 */
const methodDescriptor_AdminService_AssignRole = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/AssignRole',
  grpc.web.MethodType.UNARY,
  admin_admin_security_pb.RoleRequestList,
  admin_admin_security_pb.RoleResponseList,
  /**
   * @param {!proto.com.vectara.admin.RoleRequestList} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_security_pb.RoleResponseList.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.RoleRequestList} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.RoleResponseList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.RoleResponseList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.assignRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/AssignRole',
      request,
      metadata || {},
      methodDescriptor_AdminService_AssignRole,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.RoleRequestList} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.RoleResponseList>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.assignRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/AssignRole',
      request,
      metadata || {},
      methodDescriptor_AdminService_AssignRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.RoleRequestList,
 *   !proto.com.vectara.admin.RoleResponseList>}
 */
const methodDescriptor_AdminService_RemoveRole = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/RemoveRole',
  grpc.web.MethodType.UNARY,
  admin_admin_security_pb.RoleRequestList,
  admin_admin_security_pb.RoleResponseList,
  /**
   * @param {!proto.com.vectara.admin.RoleRequestList} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_security_pb.RoleResponseList.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.RoleRequestList} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.RoleResponseList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.RoleResponseList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.removeRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/RemoveRole',
      request,
      metadata || {},
      methodDescriptor_AdminService_RemoveRole,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.RoleRequestList} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.RoleResponseList>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.removeRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/RemoveRole',
      request,
      metadata || {},
      methodDescriptor_AdminService_RemoveRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.RoleRequestList,
 *   !proto.com.vectara.admin.RoleResponseList>}
 */
const methodDescriptor_AdminService_TransferOwnership = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/TransferOwnership',
  grpc.web.MethodType.UNARY,
  admin_admin_security_pb.RoleRequestList,
  admin_admin_security_pb.RoleResponseList,
  /**
   * @param {!proto.com.vectara.admin.RoleRequestList} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_security_pb.RoleResponseList.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.RoleRequestList} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.RoleResponseList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.RoleResponseList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.transferOwnership =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/TransferOwnership',
      request,
      metadata || {},
      methodDescriptor_AdminService_TransferOwnership,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.RoleRequestList} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.RoleResponseList>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.transferOwnership =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/TransferOwnership',
      request,
      metadata || {},
      methodDescriptor_AdminService_TransferOwnership);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ListCorpusRolesRequest,
 *   !proto.com.vectara.admin.ListCorpusRolesResponse>}
 */
const methodDescriptor_AdminService_ListCorpusRoles = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ListCorpusRoles',
  grpc.web.MethodType.UNARY,
  admin_admin_security_pb.ListCorpusRolesRequest,
  admin_admin_security_pb.ListCorpusRolesResponse,
  /**
   * @param {!proto.com.vectara.admin.ListCorpusRolesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_security_pb.ListCorpusRolesResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ListCorpusRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ListCorpusRolesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ListCorpusRolesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.listCorpusRoles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ListCorpusRoles',
      request,
      metadata || {},
      methodDescriptor_AdminService_ListCorpusRoles,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ListCorpusRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ListCorpusRolesResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.listCorpusRoles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ListCorpusRoles',
      request,
      metadata || {},
      methodDescriptor_AdminService_ListCorpusRoles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ListCorpusDefaultRolesRequest,
 *   !proto.com.vectara.admin.ListCorpusRolesResponse>}
 */
const methodDescriptor_AdminService_ListCorpusDefaultRoles = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ListCorpusDefaultRoles',
  grpc.web.MethodType.UNARY,
  admin_admin_security_pb.ListCorpusDefaultRolesRequest,
  admin_admin_security_pb.ListCorpusRolesResponse,
  /**
   * @param {!proto.com.vectara.admin.ListCorpusDefaultRolesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_security_pb.ListCorpusRolesResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ListCorpusDefaultRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ListCorpusRolesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ListCorpusRolesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.listCorpusDefaultRoles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ListCorpusDefaultRoles',
      request,
      metadata || {},
      methodDescriptor_AdminService_ListCorpusDefaultRoles,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ListCorpusDefaultRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ListCorpusRolesResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.listCorpusDefaultRoles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ListCorpusDefaultRoles',
      request,
      metadata || {},
      methodDescriptor_AdminService_ListCorpusDefaultRoles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ListCustomerRolesRequest,
 *   !proto.com.vectara.admin.ListCustomerRolesResponse>}
 */
const methodDescriptor_AdminService_ListCustomerRoles = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ListCustomerRoles',
  grpc.web.MethodType.UNARY,
  admin_admin_security_pb.ListCustomerRolesRequest,
  admin_admin_security_pb.ListCustomerRolesResponse,
  /**
   * @param {!proto.com.vectara.admin.ListCustomerRolesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_security_pb.ListCustomerRolesResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ListCustomerRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ListCustomerRolesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ListCustomerRolesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.listCustomerRoles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ListCustomerRoles',
      request,
      metadata || {},
      methodDescriptor_AdminService_ListCustomerRoles,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ListCustomerRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ListCustomerRolesResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.listCustomerRoles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ListCustomerRoles',
      request,
      metadata || {},
      methodDescriptor_AdminService_ListCustomerRoles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.GetClientDataRequest,
 *   !proto.com.vectara.admin.GetClientDataResponse>}
 */
const methodDescriptor_AdminService_GetClientData = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/GetClientData',
  grpc.web.MethodType.UNARY,
  admin_admin_account_pb.GetClientDataRequest,
  admin_admin_account_pb.GetClientDataResponse,
  /**
   * @param {!proto.com.vectara.admin.GetClientDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_account_pb.GetClientDataResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.GetClientDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.GetClientDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.GetClientDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.getClientData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/GetClientData',
      request,
      metadata || {},
      methodDescriptor_AdminService_GetClientData,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.GetClientDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.GetClientDataResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.getClientData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/GetClientData',
      request,
      metadata || {},
      methodDescriptor_AdminService_GetClientData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.SetClientDataRequest,
 *   !proto.com.vectara.admin.SetClientDataResponse>}
 */
const methodDescriptor_AdminService_SetClientData = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/SetClientData',
  grpc.web.MethodType.UNARY,
  admin_admin_account_pb.SetClientDataRequest,
  admin_admin_account_pb.SetClientDataResponse,
  /**
   * @param {!proto.com.vectara.admin.SetClientDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_account_pb.SetClientDataResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.SetClientDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.SetClientDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.SetClientDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.setClientData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/SetClientData',
      request,
      metadata || {},
      methodDescriptor_AdminService_SetClientData,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.SetClientDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.SetClientDataResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.setClientData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/SetClientData',
      request,
      metadata || {},
      methodDescriptor_AdminService_SetClientData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.DeleteClientDataRequest,
 *   !proto.com.vectara.admin.DeleteClientDataResponse>}
 */
const methodDescriptor_AdminService_DeleteClientData = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/DeleteClientData',
  grpc.web.MethodType.UNARY,
  admin_admin_account_pb.DeleteClientDataRequest,
  admin_admin_account_pb.DeleteClientDataResponse,
  /**
   * @param {!proto.com.vectara.admin.DeleteClientDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_account_pb.DeleteClientDataResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.DeleteClientDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.DeleteClientDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.DeleteClientDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.deleteClientData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/DeleteClientData',
      request,
      metadata || {},
      methodDescriptor_AdminService_DeleteClientData,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.DeleteClientDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.DeleteClientDataResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.deleteClientData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/DeleteClientData',
      request,
      metadata || {},
      methodDescriptor_AdminService_DeleteClientData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.UpdateCorpusEnablementRequest,
 *   !proto.com.vectara.admin.UpdateCorpusEnablementResponse>}
 */
const methodDescriptor_AdminService_UpdateCorpusEnablement = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/UpdateCorpusEnablement',
  grpc.web.MethodType.UNARY,
  admin_admin_corpus_pb.UpdateCorpusEnablementRequest,
  admin_admin_corpus_pb.UpdateCorpusEnablementResponse,
  /**
   * @param {!proto.com.vectara.admin.UpdateCorpusEnablementRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_corpus_pb.UpdateCorpusEnablementResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.UpdateCorpusEnablementRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.UpdateCorpusEnablementResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.UpdateCorpusEnablementResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.updateCorpusEnablement =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/UpdateCorpusEnablement',
      request,
      metadata || {},
      methodDescriptor_AdminService_UpdateCorpusEnablement,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.UpdateCorpusEnablementRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.UpdateCorpusEnablementResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.updateCorpusEnablement =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/UpdateCorpusEnablement',
      request,
      metadata || {},
      methodDescriptor_AdminService_UpdateCorpusEnablement);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest,
 *   !proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse>}
 */
const methodDescriptor_AdminService_ReplaceCorpusFilterAttrs = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ReplaceCorpusFilterAttrs',
  grpc.web.MethodType.UNARY,
  admin_admin_corpus_pb.ReplaceCorpusFilterAttrsRequest,
  admin_admin_corpus_pb.ReplaceCorpusFilterAttrsResponse,
  /**
   * @param {!proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_corpus_pb.ReplaceCorpusFilterAttrsResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.replaceCorpusFilterAttrs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ReplaceCorpusFilterAttrs',
      request,
      metadata || {},
      methodDescriptor_AdminService_ReplaceCorpusFilterAttrs,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.replaceCorpusFilterAttrs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ReplaceCorpusFilterAttrs',
      request,
      metadata || {},
      methodDescriptor_AdminService_ReplaceCorpusFilterAttrs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.UsageMetricsRequest,
 *   !proto.com.vectara.admin.UsageMetricsResponse>}
 */
const methodDescriptor_AdminService_GetUsageMetrics = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/GetUsageMetrics',
  grpc.web.MethodType.UNARY,
  admin_admin_metric_pb.UsageMetricsRequest,
  admin_admin_metric_pb.UsageMetricsResponse,
  /**
   * @param {!proto.com.vectara.admin.UsageMetricsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_metric_pb.UsageMetricsResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.UsageMetricsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.UsageMetricsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.UsageMetricsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.getUsageMetrics =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/GetUsageMetrics',
      request,
      metadata || {},
      methodDescriptor_AdminService_GetUsageMetrics,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.UsageMetricsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.UsageMetricsResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.getUsageMetrics =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/GetUsageMetrics',
      request,
      metadata || {},
      methodDescriptor_AdminService_GetUsageMetrics);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.HasPermissionsRequest,
 *   !proto.com.vectara.admin.HasPermissionsResponse>}
 */
const methodDescriptor_AdminService_HasPermissions = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/HasPermissions',
  grpc.web.MethodType.UNARY,
  admin_admin_permission_pb.HasPermissionsRequest,
  admin_admin_permission_pb.HasPermissionsResponse,
  /**
   * @param {!proto.com.vectara.admin.HasPermissionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_permission_pb.HasPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.HasPermissionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.HasPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.HasPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.hasPermissions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/HasPermissions',
      request,
      metadata || {},
      methodDescriptor_AdminService_HasPermissions,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.HasPermissionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.HasPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.hasPermissions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/HasPermissions',
      request,
      metadata || {},
      methodDescriptor_AdminService_HasPermissions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.HasCorpusPermissionsRequest,
 *   !proto.com.vectara.admin.HasCorpusPermissionsResponse>}
 */
const methodDescriptor_AdminService_HasCorpusPermissions = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/HasCorpusPermissions',
  grpc.web.MethodType.UNARY,
  admin_admin_permission_pb.HasCorpusPermissionsRequest,
  admin_admin_permission_pb.HasCorpusPermissionsResponse,
  /**
   * @param {!proto.com.vectara.admin.HasCorpusPermissionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_permission_pb.HasCorpusPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.HasCorpusPermissionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.HasCorpusPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.HasCorpusPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.hasCorpusPermissions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/HasCorpusPermissions',
      request,
      metadata || {},
      methodDescriptor_AdminService_HasCorpusPermissions,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.HasCorpusPermissionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.HasCorpusPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.hasCorpusPermissions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/HasCorpusPermissions',
      request,
      metadata || {},
      methodDescriptor_AdminService_HasCorpusPermissions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.GetCorpusAuthUsersRequest,
 *   !proto.com.vectara.admin.GetCorpusAuthUsersResponse>}
 */
const methodDescriptor_AdminService_GetCorpusAuthUsers = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/GetCorpusAuthUsers',
  grpc.web.MethodType.UNARY,
  admin_admin_corpusauth_pb.GetCorpusAuthUsersRequest,
  admin_admin_corpusauth_pb.GetCorpusAuthUsersResponse,
  /**
   * @param {!proto.com.vectara.admin.GetCorpusAuthUsersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_corpusauth_pb.GetCorpusAuthUsersResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.GetCorpusAuthUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.GetCorpusAuthUsersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.GetCorpusAuthUsersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.getCorpusAuthUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/GetCorpusAuthUsers',
      request,
      metadata || {},
      methodDescriptor_AdminService_GetCorpusAuthUsers,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.GetCorpusAuthUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.GetCorpusAuthUsersResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.getCorpusAuthUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/GetCorpusAuthUsers',
      request,
      metadata || {},
      methodDescriptor_AdminService_GetCorpusAuthUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ManageAppClientRequest,
 *   !proto.com.vectara.admin.ManageAppClientResponse>}
 */
const methodDescriptor_AdminService_ManageAppClient = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ManageAppClient',
  grpc.web.MethodType.UNARY,
  admin_admin_appclient_pb.ManageAppClientRequest,
  admin_admin_appclient_pb.ManageAppClientResponse,
  /**
   * @param {!proto.com.vectara.admin.ManageAppClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_appclient_pb.ManageAppClientResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ManageAppClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ManageAppClientResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ManageAppClientResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.manageAppClient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ManageAppClient',
      request,
      metadata || {},
      methodDescriptor_AdminService_ManageAppClient,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ManageAppClientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ManageAppClientResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.manageAppClient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ManageAppClient',
      request,
      metadata || {},
      methodDescriptor_AdminService_ManageAppClient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ListAppClientsRequest,
 *   !proto.com.vectara.admin.ListAppClientsResponse>}
 */
const methodDescriptor_AdminService_ListAppClients = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ListAppClients',
  grpc.web.MethodType.UNARY,
  admin_admin_appclient_pb.ListAppClientsRequest,
  admin_admin_appclient_pb.ListAppClientsResponse,
  /**
   * @param {!proto.com.vectara.admin.ListAppClientsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_appclient_pb.ListAppClientsResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ListAppClientsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ListAppClientsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ListAppClientsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.listAppClients =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ListAppClients',
      request,
      metadata || {},
      methodDescriptor_AdminService_ListAppClients,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ListAppClientsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ListAppClientsResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.listAppClients =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ListAppClients',
      request,
      metadata || {},
      methodDescriptor_AdminService_ListAppClients);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.DeleteCustomerRequest,
 *   !proto.com.vectara.admin.DeleteCustomerResponse>}
 */
const methodDescriptor_AdminService_DeleteCustomer = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/DeleteCustomer',
  grpc.web.MethodType.UNARY,
  admin_admin_signup_pb.DeleteCustomerRequest,
  admin_admin_signup_pb.DeleteCustomerResponse,
  /**
   * @param {!proto.com.vectara.admin.DeleteCustomerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_signup_pb.DeleteCustomerResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.DeleteCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.DeleteCustomerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.DeleteCustomerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.deleteCustomer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/DeleteCustomer',
      request,
      metadata || {},
      methodDescriptor_AdminService_DeleteCustomer,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.DeleteCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.DeleteCustomerResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.deleteCustomer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/DeleteCustomer',
      request,
      metadata || {},
      methodDescriptor_AdminService_DeleteCustomer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.GetUserRequest,
 *   !proto.com.vectara.admin.GetUserResponse>}
 */
const methodDescriptor_AdminService_GetUser = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/GetUser',
  grpc.web.MethodType.UNARY,
  admin_admin_user_pb.GetUserRequest,
  admin_admin_user_pb.GetUserResponse,
  /**
   * @param {!proto.com.vectara.admin.GetUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_user_pb.GetUserResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.GetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.GetUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.GetUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.getUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/GetUser',
      request,
      metadata || {},
      methodDescriptor_AdminService_GetUser,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.GetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.GetUserResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.getUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/GetUser',
      request,
      metadata || {},
      methodDescriptor_AdminService_GetUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ListInvoicesRequest,
 *   !proto.com.vectara.admin.ListInvoicesResponse>}
 */
const methodDescriptor_AdminService_ListInvoices = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ListInvoices',
  grpc.web.MethodType.UNARY,
  admin_admin_billing_pb.ListInvoicesRequest,
  admin_admin_billing_pb.ListInvoicesResponse,
  /**
   * @param {!proto.com.vectara.admin.ListInvoicesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_billing_pb.ListInvoicesResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ListInvoicesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ListInvoicesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ListInvoicesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.listInvoices =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ListInvoices',
      request,
      metadata || {},
      methodDescriptor_AdminService_ListInvoices,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ListInvoicesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ListInvoicesResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.listInvoices =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ListInvoices',
      request,
      metadata || {},
      methodDescriptor_AdminService_ListInvoices);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.BillingDataRequest,
 *   !proto.com.vectara.admin.BillingDataResponse>}
 */
const methodDescriptor_AdminService_readBillingData = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/readBillingData',
  grpc.web.MethodType.UNARY,
  admin_admin_billing_pb.BillingDataRequest,
  admin_admin_billing_pb.BillingDataResponse,
  /**
   * @param {!proto.com.vectara.admin.BillingDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_billing_pb.BillingDataResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.BillingDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.BillingDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.BillingDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.readBillingData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/readBillingData',
      request,
      metadata || {},
      methodDescriptor_AdminService_readBillingData,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.BillingDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.BillingDataResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.readBillingData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/readBillingData',
      request,
      metadata || {},
      methodDescriptor_AdminService_readBillingData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.UpdateBillingAddressRequest,
 *   !proto.com.vectara.admin.UpdateBillingAddressResponse>}
 */
const methodDescriptor_AdminService_updateBillingAddress = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/updateBillingAddress',
  grpc.web.MethodType.UNARY,
  admin_admin_billing_pb.UpdateBillingAddressRequest,
  admin_admin_billing_pb.UpdateBillingAddressResponse,
  /**
   * @param {!proto.com.vectara.admin.UpdateBillingAddressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_billing_pb.UpdateBillingAddressResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.UpdateBillingAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.UpdateBillingAddressResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.UpdateBillingAddressResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.updateBillingAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/updateBillingAddress',
      request,
      metadata || {},
      methodDescriptor_AdminService_updateBillingAddress,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.UpdateBillingAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.UpdateBillingAddressResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.updateBillingAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/updateBillingAddress',
      request,
      metadata || {},
      methodDescriptor_AdminService_updateBillingAddress);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.UpdatePaymentDetailsRequest,
 *   !proto.com.vectara.admin.UpdatePaymentDetailsResponse>}
 */
const methodDescriptor_AdminService_updatePaymentDetails = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/updatePaymentDetails',
  grpc.web.MethodType.UNARY,
  admin_admin_billing_pb.UpdatePaymentDetailsRequest,
  admin_admin_billing_pb.UpdatePaymentDetailsResponse,
  /**
   * @param {!proto.com.vectara.admin.UpdatePaymentDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_billing_pb.UpdatePaymentDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.UpdatePaymentDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.UpdatePaymentDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.UpdatePaymentDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.updatePaymentDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/updatePaymentDetails',
      request,
      metadata || {},
      methodDescriptor_AdminService_updatePaymentDetails,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.UpdatePaymentDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.UpdatePaymentDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.updatePaymentDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/updatePaymentDetails',
      request,
      metadata || {},
      methodDescriptor_AdminService_updatePaymentDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ReadBillingPeriodDetailsRequest,
 *   !proto.com.vectara.admin.ReadBillingPeriodDetailsResponse>}
 */
const methodDescriptor_AdminService_readBillingPeriodDetails = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/readBillingPeriodDetails',
  grpc.web.MethodType.UNARY,
  admin_admin_billing_pb.ReadBillingPeriodDetailsRequest,
  admin_admin_billing_pb.ReadBillingPeriodDetailsResponse,
  /**
   * @param {!proto.com.vectara.admin.ReadBillingPeriodDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_billing_pb.ReadBillingPeriodDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ReadBillingPeriodDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ReadBillingPeriodDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ReadBillingPeriodDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.readBillingPeriodDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/readBillingPeriodDetails',
      request,
      metadata || {},
      methodDescriptor_AdminService_readBillingPeriodDetails,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ReadBillingPeriodDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ReadBillingPeriodDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.readBillingPeriodDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/readBillingPeriodDetails',
      request,
      metadata || {},
      methodDescriptor_AdminService_readBillingPeriodDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ReadCustomerPlanDetailsRequest,
 *   !proto.com.vectara.admin.ReadCustomerPlanDetailsResponse>}
 */
const methodDescriptor_AdminService_ReadCurrentCustomerPlan = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ReadCurrentCustomerPlan',
  grpc.web.MethodType.UNARY,
  admin_admin_signup_pb.ReadCustomerPlanDetailsRequest,
  admin_admin_signup_pb.ReadCustomerPlanDetailsResponse,
  /**
   * @param {!proto.com.vectara.admin.ReadCustomerPlanDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_signup_pb.ReadCustomerPlanDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ReadCustomerPlanDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ReadCustomerPlanDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ReadCustomerPlanDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.readCurrentCustomerPlan =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ReadCurrentCustomerPlan',
      request,
      metadata || {},
      methodDescriptor_AdminService_ReadCurrentCustomerPlan,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ReadCustomerPlanDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ReadCustomerPlanDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.readCurrentCustomerPlan =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ReadCurrentCustomerPlan',
      request,
      metadata || {},
      methodDescriptor_AdminService_ReadCurrentCustomerPlan);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ChangeCustomerPlanRequest,
 *   !proto.com.vectara.admin.ChangeCustomerPlanResponse>}
 */
const methodDescriptor_AdminService_ChangeCustomerPlan = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ChangeCustomerPlan',
  grpc.web.MethodType.UNARY,
  admin_admin_signup_pb.ChangeCustomerPlanRequest,
  admin_admin_signup_pb.ChangeCustomerPlanResponse,
  /**
   * @param {!proto.com.vectara.admin.ChangeCustomerPlanRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_signup_pb.ChangeCustomerPlanResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ChangeCustomerPlanRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ChangeCustomerPlanResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ChangeCustomerPlanResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.changeCustomerPlan =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ChangeCustomerPlan',
      request,
      metadata || {},
      methodDescriptor_AdminService_ChangeCustomerPlan,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ChangeCustomerPlanRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ChangeCustomerPlanResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.changeCustomerPlan =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ChangeCustomerPlan',
      request,
      metadata || {},
      methodDescriptor_AdminService_ChangeCustomerPlan);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ReadFeaturesRequest,
 *   !proto.com.vectara.admin.ReadFeaturesResponse>}
 */
const methodDescriptor_AdminService_ReadFeatures = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ReadFeatures',
  grpc.web.MethodType.UNARY,
  admin_admin_feature_pb.ReadFeaturesRequest,
  admin_admin_feature_pb.ReadFeaturesResponse,
  /**
   * @param {!proto.com.vectara.admin.ReadFeaturesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_feature_pb.ReadFeaturesResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ReadFeaturesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ReadFeaturesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ReadFeaturesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.readFeatures =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ReadFeatures',
      request,
      metadata || {},
      methodDescriptor_AdminService_ReadFeatures,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ReadFeaturesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ReadFeaturesResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.readFeatures =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ReadFeatures',
      request,
      metadata || {},
      methodDescriptor_AdminService_ReadFeatures);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ExtendSessionRequest,
 *   !proto.com.vectara.admin.ExtendSessionResponse>}
 */
const methodDescriptor_AdminService_ExtendSession = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ExtendSession',
  grpc.web.MethodType.UNARY,
  admin_admin_account_pb.ExtendSessionRequest,
  admin_admin_account_pb.ExtendSessionResponse,
  /**
   * @param {!proto.com.vectara.admin.ExtendSessionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_account_pb.ExtendSessionResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ExtendSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ExtendSessionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ExtendSessionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.extendSession =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ExtendSession',
      request,
      metadata || {},
      methodDescriptor_AdminService_ExtendSession,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ExtendSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ExtendSessionResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.extendSession =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ExtendSession',
      request,
      metadata || {},
      methodDescriptor_AdminService_ExtendSession);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.CreateApiKeyRequest,
 *   !proto.com.vectara.admin.CreateApiKeyResponse>}
 */
const methodDescriptor_AdminService_CreateApiKey = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/CreateApiKey',
  grpc.web.MethodType.UNARY,
  admin_admin_apikey_pb.CreateApiKeyRequest,
  admin_admin_apikey_pb.CreateApiKeyResponse,
  /**
   * @param {!proto.com.vectara.admin.CreateApiKeyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_apikey_pb.CreateApiKeyResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.CreateApiKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.CreateApiKeyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.CreateApiKeyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.createApiKey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/CreateApiKey',
      request,
      metadata || {},
      methodDescriptor_AdminService_CreateApiKey,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.CreateApiKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.CreateApiKeyResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.createApiKey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/CreateApiKey',
      request,
      metadata || {},
      methodDescriptor_AdminService_CreateApiKey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.EnableApiKeyRequest,
 *   !proto.com.vectara.admin.EnableApiKeyResponse>}
 */
const methodDescriptor_AdminService_EnableApiKey = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/EnableApiKey',
  grpc.web.MethodType.UNARY,
  admin_admin_apikey_pb.EnableApiKeyRequest,
  admin_admin_apikey_pb.EnableApiKeyResponse,
  /**
   * @param {!proto.com.vectara.admin.EnableApiKeyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_apikey_pb.EnableApiKeyResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.EnableApiKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.EnableApiKeyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.EnableApiKeyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.enableApiKey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/EnableApiKey',
      request,
      metadata || {},
      methodDescriptor_AdminService_EnableApiKey,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.EnableApiKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.EnableApiKeyResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.enableApiKey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/EnableApiKey',
      request,
      metadata || {},
      methodDescriptor_AdminService_EnableApiKey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.DeleteApiKeyRequest,
 *   !proto.com.vectara.admin.DeleteApiKeyResponse>}
 */
const methodDescriptor_AdminService_DeleteApiKey = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/DeleteApiKey',
  grpc.web.MethodType.UNARY,
  admin_admin_apikey_pb.DeleteApiKeyRequest,
  admin_admin_apikey_pb.DeleteApiKeyResponse,
  /**
   * @param {!proto.com.vectara.admin.DeleteApiKeyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_apikey_pb.DeleteApiKeyResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.DeleteApiKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.DeleteApiKeyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.DeleteApiKeyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.deleteApiKey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/DeleteApiKey',
      request,
      metadata || {},
      methodDescriptor_AdminService_DeleteApiKey,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.DeleteApiKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.DeleteApiKeyResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.deleteApiKey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/DeleteApiKey',
      request,
      metadata || {},
      methodDescriptor_AdminService_DeleteApiKey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ListApiKeysRequest,
 *   !proto.com.vectara.admin.ListApiKeysResponse>}
 */
const methodDescriptor_AdminService_ListApiKeys = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ListApiKeys',
  grpc.web.MethodType.UNARY,
  admin_admin_apikey_pb.ListApiKeysRequest,
  admin_admin_apikey_pb.ListApiKeysResponse,
  /**
   * @param {!proto.com.vectara.admin.ListApiKeysRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_apikey_pb.ListApiKeysResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ListApiKeysRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ListApiKeysResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ListApiKeysResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.listApiKeys =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ListApiKeys',
      request,
      metadata || {},
      methodDescriptor_AdminService_ListApiKeys,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ListApiKeysRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ListApiKeysResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.listApiKeys =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ListApiKeys',
      request,
      metadata || {},
      methodDescriptor_AdminService_ListApiKeys);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ReadApiKeyRequest,
 *   !proto.com.vectara.admin.ReadApiKeyResponse>}
 */
const methodDescriptor_AdminService_ReadApiKeys = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ReadApiKeys',
  grpc.web.MethodType.UNARY,
  admin_admin_apikey_pb.ReadApiKeyRequest,
  admin_admin_apikey_pb.ReadApiKeyResponse,
  /**
   * @param {!proto.com.vectara.admin.ReadApiKeyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_apikey_pb.ReadApiKeyResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ReadApiKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ReadApiKeyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ReadApiKeyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.readApiKeys =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ReadApiKeys',
      request,
      metadata || {},
      methodDescriptor_AdminService_ReadApiKeys,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ReadApiKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ReadApiKeyResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.readApiKeys =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ReadApiKeys',
      request,
      metadata || {},
      methodDescriptor_AdminService_ReadApiKeys);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ReadUsageStatsRequest,
 *   !proto.com.vectara.admin.ReadUsageStatsResponse>}
 */
const methodDescriptor_AdminService_ReadCurrentPeriodUsageStats = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ReadCurrentPeriodUsageStats',
  grpc.web.MethodType.UNARY,
  admin_admin_stats_pb.ReadUsageStatsRequest,
  admin_admin_stats_pb.ReadUsageStatsResponse,
  /**
   * @param {!proto.com.vectara.admin.ReadUsageStatsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_stats_pb.ReadUsageStatsResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ReadUsageStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ReadUsageStatsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ReadUsageStatsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.readCurrentPeriodUsageStats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ReadCurrentPeriodUsageStats',
      request,
      metadata || {},
      methodDescriptor_AdminService_ReadCurrentPeriodUsageStats,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ReadUsageStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ReadUsageStatsResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.readCurrentPeriodUsageStats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ReadCurrentPeriodUsageStats',
      request,
      metadata || {},
      methodDescriptor_AdminService_ReadCurrentPeriodUsageStats);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.HasDefaultPaymentSourceRequest,
 *   !proto.com.vectara.admin.HasDefaultPaymentSourceResponse>}
 */
const methodDescriptor_AdminService_HasDefaultPaymentSource = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/HasDefaultPaymentSource',
  grpc.web.MethodType.UNARY,
  admin_admin_billing_pb.HasDefaultPaymentSourceRequest,
  admin_admin_billing_pb.HasDefaultPaymentSourceResponse,
  /**
   * @param {!proto.com.vectara.admin.HasDefaultPaymentSourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_billing_pb.HasDefaultPaymentSourceResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.HasDefaultPaymentSourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.HasDefaultPaymentSourceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.HasDefaultPaymentSourceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.hasDefaultPaymentSource =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/HasDefaultPaymentSource',
      request,
      metadata || {},
      methodDescriptor_AdminService_HasDefaultPaymentSource,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.HasDefaultPaymentSourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.HasDefaultPaymentSourceResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.hasDefaultPaymentSource =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/HasDefaultPaymentSource',
      request,
      metadata || {},
      methodDescriptor_AdminService_HasDefaultPaymentSource);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.admin.ReadAWSMPDetailsRequest,
 *   !proto.com.vectara.admin.ReadAWSMPDetailsResponse>}
 */
const methodDescriptor_AdminService_ReadAWSMPDetails = new grpc.web.MethodDescriptor(
  '/com.vectara.AdminService/ReadAWSMPDetails',
  grpc.web.MethodType.UNARY,
  admin_admin_account_pb.ReadAWSMPDetailsRequest,
  admin_admin_account_pb.ReadAWSMPDetailsResponse,
  /**
   * @param {!proto.com.vectara.admin.ReadAWSMPDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  admin_admin_account_pb.ReadAWSMPDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.admin.ReadAWSMPDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.admin.ReadAWSMPDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.admin.ReadAWSMPDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.AdminServiceClient.prototype.readAWSMPDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.AdminService/ReadAWSMPDetails',
      request,
      metadata || {},
      methodDescriptor_AdminService_ReadAWSMPDetails,
      callback);
};


/**
 * @param {!proto.com.vectara.admin.ReadAWSMPDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.admin.ReadAWSMPDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.AdminServicePromiseClient.prototype.readAWSMPDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.AdminService/ReadAWSMPDetails',
      request,
      metadata || {},
      methodDescriptor_AdminService_ReadAWSMPDetails);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.vectara.DocumentServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.vectara.DocumentServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.lists.ListDocumentsRequest,
 *   !proto.com.vectara.lists.ListDocumentsResponse>}
 */
const methodDescriptor_DocumentService_ListDocuments = new grpc.web.MethodDescriptor(
  '/com.vectara.DocumentService/ListDocuments',
  grpc.web.MethodType.UNARY,
  list_documents_pb.ListDocumentsRequest,
  list_documents_pb.ListDocumentsResponse,
  /**
   * @param {!proto.com.vectara.lists.ListDocumentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  list_documents_pb.ListDocumentsResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.lists.ListDocumentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.lists.ListDocumentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.lists.ListDocumentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.DocumentServiceClient.prototype.listDocuments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.DocumentService/ListDocuments',
      request,
      metadata || {},
      methodDescriptor_DocumentService_ListDocuments,
      callback);
};


/**
 * @param {!proto.com.vectara.lists.ListDocumentsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.lists.ListDocumentsResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.DocumentServicePromiseClient.prototype.listDocuments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.DocumentService/ListDocuments',
      request,
      metadata || {},
      methodDescriptor_DocumentService_ListDocuments);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.vectara.ChatServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.vectara.ChatServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.chat.ListConversationsRequest,
 *   !proto.com.vectara.chat.ListConversationsResponse>}
 */
const methodDescriptor_ChatService_ListConversations = new grpc.web.MethodDescriptor(
  '/com.vectara.ChatService/ListConversations',
  grpc.web.MethodType.UNARY,
  chat_pb.ListConversationsRequest,
  chat_pb.ListConversationsResponse,
  /**
   * @param {!proto.com.vectara.chat.ListConversationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  chat_pb.ListConversationsResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.chat.ListConversationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.chat.ListConversationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.chat.ListConversationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.ChatServiceClient.prototype.listConversations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.ChatService/ListConversations',
      request,
      metadata || {},
      methodDescriptor_ChatService_ListConversations,
      callback);
};


/**
 * @param {!proto.com.vectara.chat.ListConversationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.chat.ListConversationsResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.ChatServicePromiseClient.prototype.listConversations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.ChatService/ListConversations',
      request,
      metadata || {},
      methodDescriptor_ChatService_ListConversations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.chat.ReadConversationsRequest,
 *   !proto.com.vectara.chat.ReadConversationsResponse>}
 */
const methodDescriptor_ChatService_ReadConversations = new grpc.web.MethodDescriptor(
  '/com.vectara.ChatService/ReadConversations',
  grpc.web.MethodType.UNARY,
  chat_pb.ReadConversationsRequest,
  chat_pb.ReadConversationsResponse,
  /**
   * @param {!proto.com.vectara.chat.ReadConversationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  chat_pb.ReadConversationsResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.chat.ReadConversationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.chat.ReadConversationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.chat.ReadConversationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.ChatServiceClient.prototype.readConversations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.ChatService/ReadConversations',
      request,
      metadata || {},
      methodDescriptor_ChatService_ReadConversations,
      callback);
};


/**
 * @param {!proto.com.vectara.chat.ReadConversationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.chat.ReadConversationsResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.ChatServicePromiseClient.prototype.readConversations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.ChatService/ReadConversations',
      request,
      metadata || {},
      methodDescriptor_ChatService_ReadConversations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.chat.DeleteConversationsRequest,
 *   !proto.com.vectara.chat.DeleteConversationsResponse>}
 */
const methodDescriptor_ChatService_DeleteConversations = new grpc.web.MethodDescriptor(
  '/com.vectara.ChatService/DeleteConversations',
  grpc.web.MethodType.UNARY,
  chat_pb.DeleteConversationsRequest,
  chat_pb.DeleteConversationsResponse,
  /**
   * @param {!proto.com.vectara.chat.DeleteConversationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  chat_pb.DeleteConversationsResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.chat.DeleteConversationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.chat.DeleteConversationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.chat.DeleteConversationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.ChatServiceClient.prototype.deleteConversations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.ChatService/DeleteConversations',
      request,
      metadata || {},
      methodDescriptor_ChatService_DeleteConversations,
      callback);
};


/**
 * @param {!proto.com.vectara.chat.DeleteConversationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.chat.DeleteConversationsResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.ChatServicePromiseClient.prototype.deleteConversations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.ChatService/DeleteConversations',
      request,
      metadata || {},
      methodDescriptor_ChatService_DeleteConversations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.chat.DeleteTurnsRequest,
 *   !proto.com.vectara.chat.DeleteTurnsResponse>}
 */
const methodDescriptor_ChatService_DeleteTurns = new grpc.web.MethodDescriptor(
  '/com.vectara.ChatService/DeleteTurns',
  grpc.web.MethodType.UNARY,
  chat_pb.DeleteTurnsRequest,
  chat_pb.DeleteTurnsResponse,
  /**
   * @param {!proto.com.vectara.chat.DeleteTurnsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  chat_pb.DeleteTurnsResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.chat.DeleteTurnsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.chat.DeleteTurnsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.chat.DeleteTurnsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.ChatServiceClient.prototype.deleteTurns =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.ChatService/DeleteTurns',
      request,
      metadata || {},
      methodDescriptor_ChatService_DeleteTurns,
      callback);
};


/**
 * @param {!proto.com.vectara.chat.DeleteTurnsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.chat.DeleteTurnsResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.ChatServicePromiseClient.prototype.deleteTurns =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.ChatService/DeleteTurns',
      request,
      metadata || {},
      methodDescriptor_ChatService_DeleteTurns);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.vectara.chat.DisableTurnsRequest,
 *   !proto.com.vectara.chat.DisableTurnsResponse>}
 */
const methodDescriptor_ChatService_DisableTurns = new grpc.web.MethodDescriptor(
  '/com.vectara.ChatService/DisableTurns',
  grpc.web.MethodType.UNARY,
  chat_pb.DisableTurnsRequest,
  chat_pb.DisableTurnsResponse,
  /**
   * @param {!proto.com.vectara.chat.DisableTurnsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  chat_pb.DisableTurnsResponse.deserializeBinary
);


/**
 * @param {!proto.com.vectara.chat.DisableTurnsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.vectara.chat.DisableTurnsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.vectara.chat.DisableTurnsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.vectara.ChatServiceClient.prototype.disableTurns =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.vectara.ChatService/DisableTurns',
      request,
      metadata || {},
      methodDescriptor_ChatService_DisableTurns,
      callback);
};


/**
 * @param {!proto.com.vectara.chat.DisableTurnsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.vectara.chat.DisableTurnsResponse>}
 *     Promise that resolves to the response
 */
proto.com.vectara.ChatServicePromiseClient.prototype.disableTurns =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.vectara.ChatService/DisableTurns',
      request,
      metadata || {},
      methodDescriptor_ChatService_DisableTurns);
};


module.exports = proto.com.vectara;

