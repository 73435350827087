import {
  REACT_APP_IS_SSO_ENABLED,
  REACT_APP_IS_ANALYTICS_ENABLED,
  REACT_APP_IS_TABLE_DATA_QUERYING_ENABLED,
  REACT_APP_IS_DATADOG_ENABLED,
  REACT_APP_IS_BILLING_ENABLED,
  REACT_APP_IS_INVITE_EMAIL_ENABLED
} from "./envVars";

// Cookies
// This is mirrored in cypress/e2e/utils/constants.ts and should be synced manually if changed
export const ANONYMOUS_ID_COOKIE_NAME = "vectaraAnonymousId";

// Paths
export const SURVEY_PATH = "welcome";
export const CHAT_HISTORY_PATH = "chatHistory";
export const PROFILE_PATH = "profile";
export const DELETE_ACCOUNT_PATH = "deleteAccount";
export const CORPORA_PATH = "corpora";
export const CORPUS_PATH = "corpus/key";
export const CORPUS_PATH_V1 = "corpus";
export const BILLING_PATH = "billing";
export const UPDATES_PATH = "updates";
export const DOCUMENT_PATH = "document";

// Sample corpus
// SAMPLE_CORPUS_NAME is mirrored in cypress/e2e/utils/constants.ts and should be synced manually when changed
export const SAMPLE_CORPUS_NAME = "black-holes-sample-data";
export const SAMPLE_CORPUS_KEY = "black-holes-sample-data";
export const SAMPLE_CORPUS_DESCRIPTION = "Information about black holes — as it turns out, they really do suck.";

// Rerankers
export const MMR_RERANKER_ID = 272725718;
export const SLINGSHOT_RERANKER_ID = 272725719;
export const UDF_RERANKER_ID = 272725722;

// LLMs
export const LLM = {
  mockingbird: "mockingbird-1.0",
  gpt4Turbo: "gpt-4-turbo",
  gpt4o: "gpt-4o",
  gpt4: "gpt-4",
  gpt35Turbo: "gpt-3.5-turbo",
  llama: "llama-3-70b"
};

// Generation presets
export const CUSTOM_PROMPT_TEMPLATE_VALUE = "custom_prompt_template";

// On-prem flags -- these all default to TRUE.
const defaultToTrue = (envVar?: string) => envVar?.toLowerCase() !== "false";
export const IS_SSO_ENABLED = defaultToTrue(REACT_APP_IS_SSO_ENABLED);
export const IS_ANALYTICS_ENABLED = defaultToTrue(REACT_APP_IS_ANALYTICS_ENABLED);
export const IS_TABLE_DATA_QUERYING_ENABLED = defaultToTrue(REACT_APP_IS_TABLE_DATA_QUERYING_ENABLED);
export const IS_DATADOG_ENABLED = defaultToTrue(REACT_APP_IS_DATADOG_ENABLED);
export const IS_BILLING_ENABLED = defaultToTrue(REACT_APP_IS_BILLING_ENABLED);
export const IS_INVITE_EMAIL_ENABLED = defaultToTrue(REACT_APP_IS_INVITE_EMAIL_ENABLED);
