// source: status.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
goog.exportSymbol('proto.com.vectara.Status', null, global);
goog.exportSymbol('proto.com.vectara.StatusCode', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.Status.displayName = 'proto.com.vectara.Status';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.Status.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    statusDetail: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cause: (f = msg.getCause()) && google_protobuf_any_pb.Any.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.Status}
 */
proto.com.vectara.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.Status;
  return proto.com.vectara.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.Status}
 */
proto.com.vectara.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.vectara.StatusCode} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusDetail(value);
      break;
    case 3:
      var value = new google_protobuf_any_pb.Any;
      reader.readMessage(value,google_protobuf_any_pb.Any.deserializeBinaryFromReader);
      msg.setCause(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getStatusDetail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCause();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode code = 1;
 * @return {!proto.com.vectara.StatusCode}
 */
proto.com.vectara.Status.prototype.getCode = function() {
  return /** @type {!proto.com.vectara.StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.vectara.StatusCode} value
 * @return {!proto.com.vectara.Status} returns this
 */
proto.com.vectara.Status.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string status_detail = 2;
 * @return {string}
 */
proto.com.vectara.Status.prototype.getStatusDetail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.Status} returns this
 */
proto.com.vectara.Status.prototype.setStatusDetail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Any cause = 3;
 * @return {?proto.google.protobuf.Any}
 */
proto.com.vectara.Status.prototype.getCause = function() {
  return /** @type{?proto.google.protobuf.Any} */ (
    jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 3));
};


/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.com.vectara.Status} returns this
*/
proto.com.vectara.Status.prototype.setCause = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.Status} returns this
 */
proto.com.vectara.Status.prototype.clearCause = function() {
  return this.setCause(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.Status.prototype.hasCause = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * @enum {number}
 */
proto.com.vectara.StatusCode = {
  OK: 0,
  FAILURE: 1,
  UNKNOWN: 2,
  INVALID_ARGUMENT: 3,
  DEADLINE_EXCEEDED: 4,
  ALREADY_EXISTS: 6,
  PERMISSION_DENIED: 7,
  RESOURCE_EXHAUSTED: 8,
  FAILED_PRECONDITION: 9,
  ABORTED: 10,
  OUT_OF_RANGE: 11,
  UNIMPLEMENTED: 12,
  INTERNAL: 13,
  UNAVAILABLE: 14,
  DATA_LOSS: 15,
  UNAUTHENTICATED: 16,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  CONFLICT: 409,
  UNSUPPORTED_MEDIA_TYPE: 415,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  SERVICE_UNAVAILABLE: 503,
  INSUFFICIENT_STORAGE: 507,
  UNPARSEABLE_RESPONSE: 1000,
  DISABLED_CUSTOMER: 1100,
  INVALID_CUSTOMER_ID: 1101,
  DISABLED_CORPUS: 1102,
  INVALID_CORPUS_ID: 1103,
  DISABLED_API_KEY: 1104,
  EXPIRED_API_KEY: 1105,
  INVALID_API_KEY: 1106,
  CMK_INACCESSIBLE: 1107,
  QRY__DISABLED_CORPUS: 1500,
  QRY__DOCUMENT_DB_FAILURE: 1505,
  QRY__ENCODER_FAILURE: 1510,
  QRY__INTERRUPTED: 1515,
  QRY__INVALID_CORPUS: 1520,
  QRY__INVALID_START: 1525,
  QRY__INVALID_NUM_RESULTS: 1530,
  QRY__INVALID_CONTEXT: 1531,
  QRY__MISSING_QUERY: 1535,
  QRY__MISSING_CORPUS: 1540,
  QRY__TIMEOUT: 1545,
  QRY__TOO_MANY_CORPORA: 1550,
  QRY__TOO_MANY_QUERIES: 1555,
  QRY__VECTOR_INDEX_FAILURE: 1560,
  QRY__INVALID_DIMENSION: 1565,
  QRY__INVALID_CLIENTKEY: 1570,
  QRY__DECRYPTION_FAILURE: 1575,
  QRY__INVALID_RERANKER: 1580,
  QRY__PARTIAL_RERANK: 1585,
  QRY__RERANK_FAILURE: 1590,
  QRY__TOO_MANY_RESULT_ROWS: 1595,
  QRY__PARTIAL_RETRIEVAL: 1600,
  QRY__SMRY__INVALID_SUMMARIZER_PROMPT: 1660,
  QRY__SMRY__INVALID_SUMMARY_LANG: 1665,
  QRY__SMRY__UNSUPPORTED_SUMMARY_LANG: 1670,
  QRY__SMRY__PARTIAL_SUMMARY: 1675,
  QRY__SMRY__NO_QUERY_RESULTS: 1680,
  QRY__SMRY__EVAL_UNSUPPORTED_LANG: 1685,
  QRY__SMRY__EVAL_FAILURE: 1690,
  QRY__GEN__NO_QUERY_RESULTS: 1650,
  QRY__GEN__UNPARSEABLE_MODEL_PARAMS: 1651,
  QRY__IQR__NO_FILTER_ATTRS: 1700,
  QRY__IQR__EXTRACTED_EMPTY_FILTER: 1701,
  QRY__IQR__FAILED_TO_PARSE_EXTRACTED_FILTER: 1702,
  CX_SPECS__INVALID_JSON: 2000,
  CX_SPECS__UNREGISTERED_TYPE: 2005,
  CX_SPECS__MISSING_SPEC: 2010,
  CX_SPECS__MISSING_TYPE: 2011,
  CX_SPECS__UNPARSEABLE_SPEC: 2015,
  ADM__INVALID_CUSTOMER_ID: 2500,
  ADM__INVALID_CORPUS_ID: 2501,
  ADM__INVALID_ENCODER_ID: 2502,
  ADM__INVALID_ROLE_ID: 2503,
  ADM__ROLE_ALREADY_EXISTS: 2504,
  ADM__ONLY_ONE_OWNER_SUPPORTED: 2505,
  ADM__INVALID_PERMISSION: 2506,
  ADM__ROLECREATION_FAILURE: 2507,
  ADM__USER_EMAIL_NOT_AVAIALBLE: 2508,
  ADM__USERNAME_NOT_AVAILABLE: 2509,
  ADM__SIGNUP_MISSING_NAME: 2510,
  ADM__SIGNUP_MISSING_ORG: 2511,
  ADM__SIGNUP_MISSING_EMAIL: 2512,
  ADM__SIGNUP_MISSING_PAYMENT: 2513,
  ADM__SIGNUP_MISSING_PLAN: 2514,
  ADM__SIGNUP_MISSING_PASSWORD: 2515,
  ADM__SIGNUP_INVALID_NAME: 2516,
  ADM__SIGNUP_INVALID_ORG: 2517,
  ADM__SIGNUP_INVALID_EMAIL: 2518,
  ADM__SIGNUP_INVALID_PAYMENT: 2519,
  ADM__SIGNUP_INVALID_PLAN: 2520,
  ADM__SIGNUP_INVALID_PASSWORD: 2521,
  ADM__SIGNUP_INVALID_ACCOUNT_ALIAS: 2530,
  ADM__SIGNUP_INVALID_EMAIL_VALIDATION_CODE: 2531,
  ADM__SIGNUP_MISSING_COUNTRY_CODE: 2532,
  ADM__SIGNUP_ROOT_EMAIL_NOT_AVAILABLE: 2533,
  ADM__CUST_MARK_DELETE_FAILED: 2522,
  ADM__CUST_FAISS_DEALLOC_FAILED: 2523,
  ADM__CUST_ALREADY_ACTIVE: 2534,
  ADM__CUST_REACTIVATE_FAILED: 2535,
  ADM__CUST_ENABLEMENT_FAILED: 2536,
  ADM__CUST_MARK_EXPIRED_FAILED: 2537,
  ADM__CORPUS_LIMIT_REACHED: 2524,
  ADM__STRIPE_CARD_DECLINED: 2525,
  ADM__STRIPE_PROCESSING_ERROR: 2526,
  ADM__EMAIL_VALIDATION_REQUEST_NOT_FOUND: 2540,
  ADM__EMAIL_NOT_VALIDATED: 2541,
  ADM__CHANGE_PLAN__NO_CURRENT_PLAN: 2550,
  ADM__CHANGE_PLAN__REQUIRES_MANUAL_CHANGE: 2551,
  ADM__CHANGE_PLAN__INVALID_PLAN_ID: 2552,
  ADM__CHANGE_PLAN__NO_PAYMENT_SOURCE: 2553,
  ADM__CHANGE_PLAN__INVALID_EFFECTIVE_DATE: 2554,
  ADM__CHANGE_PLAN__CONFLICTING_CHANGE: 2555,
  SCM__MISCONFIGURED_CONNECTION: 3000,
  STATS_DB_READ_FAILURE: 3550,
  VDB__TEXT_READ_FAILURE: 4000,
  REBUILD__LOW_RECALL: 4500,
  REBUILD__INDEX_UPLOAD_FAILURE: 4505,
  REBUILD__UPDATE_JOURNAL_FAILURE: 4510,
  REBUILD__UPDATE_FAISSPARAMS_FAILURE: 4515,
  REBUILD__NO_DATA: 4520,
  REBUILD__EVALUATION: 4525,
  IDX__TRANSIENT_PARTIAL_DELETION_FAILURE: 5000,
  IDX__PERMANENT_PARTIAL_DELETION_FAILURE: 5001,
  CALB__INVALID_JSON: 5500,
  CALB__INVALID_SPEC: 5501,
  CALB__UNREGISTERED_TYPE: 5505,
  CALB__MISSING_SPEC: 5510,
  CALB__MISSING_TYPE: 5511,
  CALB__UNPARSABLE_SPEC: 5515
};

goog.object.extend(exports, proto.com.vectara);
