import { AdminServicePromiseClient } from "../generated_protos/services_grpc_web_pb";
import { ReadAWSMPDetailsRequest, ReadAWSMPDetailsResponse } from "../generated_protos/admin/admin_account_pb";
import { ReadFeaturesRequest, ReadFeaturesResponse } from "../generated_protos/admin/admin_feature_pb";
import { apiV2Client, ApiV2QueryParams, withApiErrorHandling } from "./apiV2Client";

/**
 * query the list of configurable features enabled/disabled for this customer
 * @return {object} returns configurable features.
 */
export const ReadAccountFeatures = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string,
  corpusIds: number[] = []
): Promise<ReadFeaturesResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("JWT token is invalid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const request = new ReadFeaturesRequest();
    request.setReadAccountFeatures(true);

    if (corpusIds.length) {
      corpusIds.forEach((corpusId) => {
        request.addCorpusId(corpusId);
      });
    }

    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };

    adminService
      .readFeatures(request, args)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from: readAccountFeatures");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};

export const sendFetchRerankersRequest = async (payload?: ApiV2QueryParams<"listRerankers">) => {
  const { data } = await withApiErrorHandling(
    apiV2Client.GET("/v2/rerankers", {
      params: { query: payload }
    })
  );

  return data;
};

export const sendFetchEncodersRequest = async (payload?: ApiV2QueryParams<"listEncoders">) => {
  const { data } = await withApiErrorHandling(
    apiV2Client.GET("/v2/encoders", {
      params: { query: payload }
    })
  );

  return data;
};

export const ReadAwsMpDetails = (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string
): Promise<ReadAWSMPDetailsResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const readAwsMpDetailsRequest = new ReadAWSMPDetailsRequest();
    readAwsMpDetailsRequest.setCustomerId(Number(customerId));

    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };

    adminService
      .readAWSMPDetails(readAwsMpDetailsRequest, args)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from gRPC call: readAwsMpDetailsRequest");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};
