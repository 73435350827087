export enum V2IdPrefix {
  CHAT = "cht_",
  CORPUS = "crp_",
  RERANKER = "rnk_",
  ENCODER = "enc_"
}

const getV2IdPrefix = (id: string) => {
  return Object.values(V2IdPrefix).find((prefix) => id.startsWith(prefix));
};

export const toV1Id = (id: string | number) => {
  // if it's already a number, then return it
  if (typeof id === "number") return id;

  // if it's a v2 id, then remove the prefix
  const prefix = getV2IdPrefix(id);
  if (prefix) id = id.split(prefix)[1];

  // convert it to a number
  return Number(id);
};

export const toV2Id = (id: string | number, prefix: V2IdPrefix) => {
  // if it's already a v2 id, then return it
  if (typeof id === "string" && getV2IdPrefix(id)) return id;

  // else, convert it to a v2 id with the given prefix
  return `${prefix}${id}`;
};
