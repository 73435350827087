import { createContext, useContext, ReactNode, useRef, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { corporaDb } from "../utils/idb/corporaDb";
import { useUserContext } from "./UserContext";
import { QueryInputPanelTab } from "../views/corpus/query/inputPanel/types";
import { QueryOutputPanelTab } from "../views/corpus/query/outputPanel/types";

type LayoutToggles = {
  input: QueryInputPanelTab | undefined;
  output: QueryOutputPanelTab | undefined;
};

interface LayoutContextType {
  uxPreviewContainerRef: React.RefObject<HTMLDivElement>;
  queryContentRef: React.RefObject<HTMLDivElement>;
  queryResponseRef: React.RefObject<HTMLDivElement>;
  isHelpDrawerOpen: boolean;
  setIsHelpDrawerOpen: (isOpen: boolean) => void;
  layoutToggles: LayoutToggles;
  setLayoutToggle: (key: keyof LayoutToggles, value: LayoutToggles[typeof key]) => void;
  customHeaderContent: ReactNode;
  setCustomHeaderContent: (customHeader: ReactNode) => void;
  isNavPinnedOpen: boolean;
  setIsNavPinnedOpen: (isOpen: boolean) => void;
  isNavOpen: boolean;
  setIsNavOpen: (isOpen: boolean) => void;
  isResizingQueryPanels: boolean;
  setIsResizingQueryPanels: (isResizing: boolean) => void;
}

const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const LayoutContextProvider = ({ children }: Props) => {
  const { customer } = useUserContext();
  const uxPreviewContainerRef = useRef<HTMLDivElement>(null);
  const queryContentRef = useRef<HTMLDivElement>(null);
  const queryResponseRef = useRef<HTMLDivElement>(null);
  const [isHelpDrawerOpen, setIsHelpDrawerOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [customHeaderContent, setCustomHeaderContent] = useState<ReactNode>();
  const [isNavPinnedOpen, setIsNavPinnedOpen] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isResizingQueryPanels, setIsResizingQueryPanels] = useState(false);

  useEffect(() => {
    const init = async () => {
      if (!customer) return;

      const isSideNavPinnedDbKey = customer.customerId;
      const isSideNavPinned = await corporaDb.getIsSideNavPinned(isSideNavPinnedDbKey);
      setIsNavPinnedOpen(isSideNavPinned ?? false);
    };

    init();
  }, [customer]);

  const layoutToggles: LayoutToggles = {
    input: emptyToDefault(searchParams.get("input"), "prettyRequest") as LayoutToggles["input"],
    output: emptyToDefault(searchParams.get("output"), "prettyResponse") as LayoutToggles["output"]
  };

  const setLayoutToggle = (key: keyof LayoutToggles, value: LayoutToggles[typeof key]) => {
    // @ts-expect-error TS thinks value is an acceptable value for any key, not just the provided one.
    layoutToggles[key] = value;

    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }

    setSearchParams(searchParams);
  };

  return (
    <LayoutContext.Provider
      value={{
        uxPreviewContainerRef,
        queryContentRef,
        queryResponseRef,
        isHelpDrawerOpen,
        setIsHelpDrawerOpen,
        layoutToggles,
        setLayoutToggle,
        customHeaderContent,
        setCustomHeaderContent,
        isNavPinnedOpen,
        setIsNavPinnedOpen: (isOpen: boolean) => {
          if (!customer) return;

          const isSideNavPinnedDbKey = customer.customerId;
          corporaDb.setIsSideNavPinned(isSideNavPinnedDbKey, isOpen);
          setIsNavPinnedOpen(isOpen);
          if (!isOpen) setIsNavOpen(false);
        },
        isNavOpen,
        setIsNavOpen,
        isResizingQueryPanels,
        setIsResizingQueryPanels
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = () => {
  const context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error("useLayoutContext must be used within a LayoutContextProvider");
  }
  return context;
};

const emptyToDefault = (value: string | null, defaultValue: string) => {
  // A search param can be an empty string, in which case it's still present in the URL but it
  // doesn't have a value assigned to it.
  if (value === "") {
    return defaultValue;
  }

  return value;
};
