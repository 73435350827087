import { RecoveryFlow, UpdateRecoveryFlowBody } from "@ory/client";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { orySdk } from "../../../utils/auth/orySdk";
import {
  VuiButtonPrimary,
  VuiCard,
  VuiFlexContainer,
  VuiFlexItem,
  VuiFormGroup,
  VuiLink,
  VuiSpacer,
  VuiSpinner,
  VuiText,
  VuiTextColor,
  VuiTextInput,
  VuiTitle
} from "@vectara/vectara-ui";
import logo from "../../../images/logo-vectara-dark.png";
import { refreshOryJwt } from "../../../utils/auth/refreshJwt";
import { datadogRum } from "@datadog/browser-rum";
import { LoginError } from "../loginPage/LoginError";
import { useNotificationsContext } from "../../../contexts/NotificationsContext";

export default function SubmitRecoveryCode() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [flow, setFlow] = useState<RecoveryFlow | null>(null);
  const [recoveryCode, setRecoveryCode] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { addNotification } = useNotificationsContext();

  useEffect(() => {
    const { state } = location;
    const flowId = searchParams.get("flow");

    if (!state?.flow && !flowId) {
      navigate("/requestResetPassword", { replace: true });
    }

    if (flowId) {
      getFlow(flowId);
    } else {
      setFlow(state?.flow);
    }
  }, []);

  const getFlow = async (flowId: string) => {
    try {
      const response = await orySdk.getRecoveryFlow({ id: flowId });
      if (response && response.status === 200) {
        setFlow(response.data);
      }
    } catch (e) {
      console.log("getFlow failure: ", e);
      datadogRum.addError(e);
      addNotification("There was an error while resetting your password", "danger");
      navigate("/login", { replace: true });
    }
  };

  const validateForm = () => {
    return recoveryCode.trim().length > 0;
  };

  const handleSubmit = async () => {
    const isFormValid = validateForm();
    setIsValid(isFormValid);

    if (!isFormValid) return;

    if (!flow) {
      navigate("/login", { replace: true });
      return;
    }

    setIsSubmitting(true);
    setErrorMessage(null);

    try {
      const body: UpdateRecoveryFlowBody = {
        method: "code",
        code: recoveryCode
      };

      const response = await orySdk.updateRecoveryFlow({
        flow: flow.id,
        updateRecoveryFlowBody: body
      });

      if (response.status !== 200) throw new Error("Failed to update recovery flow", { cause: response });

      const errorMessages = response?.data?.ui?.messages?.filter((msg) => msg.type === "error") ?? [];

      // Handle errors.
      if (errorMessages.length > 0) {
        setFlow(response.data);
        setErrorMessage(errorMessages[0].text);
        setIsSubmitting(false);
        return;
      }

      // Handle special case for on-prem deployments.
      if (response?.data?.continue_with && response?.data?.continue_with.length > 0) {
        // @ts-expect-error Property 'flow' does not exist on type 'ContinueWith'
        const continueUrl = response?.data?.continue_with[0].flow?.url;
        const urlParams = new URLSearchParams(new URL(continueUrl).search);
        await refreshOryJwt();
        return navigate(`/setNewOryPassword?flow=${urlParams.get("flow")}`);
      }

      setFlow(response.data);
      await refreshOryJwt();
      navigate(`/setNewOryPassword?flow=${response.data.id}`);
    } catch (e: any) {
      datadogRum.addError(e);
      setIsSubmitting(false);

      if (e?.response?.data?.ui?.messages) {
        setErrorMessage(e.response.data.ui.messages[0]?.text || "Invalid recovery code");
      } else if (e?.response?.data?.redirect_browser_to) {
        const urlParams = new URLSearchParams(new URL(e.response.data.redirect_browser_to).search);
        await refreshOryJwt();

        if (searchParams.get("c")) {
          urlParams.append("c", searchParams.get("c") as string);
        }

        if (searchParams.get("username")) {
          urlParams.append("username", searchParams.get("username") as string);
        }

        if (searchParams.get("invite")) {
          urlParams.append("invite", searchParams.get("invite") as string);
        }

        navigate({
          pathname: "/setNewOryPassword",
          search: urlParams.toString()
        });
      } else {
        console.log("submit code failure: ", e);
        navigate("/login", { replace: true });
      }
    }
  };

  if (!flow) {
    return (
      <div className="loginPage">
        <div className="loggedOutPageForm">
          <VuiFlexContainer justifyContent="center" alignItems="center">
            <VuiSpinner />
          </VuiFlexContainer>
        </div>
      </div>
    );
  }

  return (
    <div className="loginPage">
      <VuiCard
        padding="s"
        className="loggedOutPageForm"
        header={
          <VuiFlexContainer
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing="s"
            className="loggedOutFormHeader"
          >
            <VuiFlexItem grow={false} shrink={false}>
              <VuiLink href="/">
                <img src={logo} alt="Vectara logo" className="loggedOutFormLogo" />
              </VuiLink>
            </VuiFlexItem>

            <VuiFlexItem grow={1} shrink={1}>
              <VuiTitle size="s" align="center">
                <h1>Reset Password</h1>
              </VuiTitle>
            </VuiFlexItem>
          </VuiFlexContainer>
        }
        body={
          <div className="loginFormBody">
            <VuiText>
              <p>Please enter the recovery code we sent to your email address.</p>
            </VuiText>

            <VuiSpacer size="l" />

            {errorMessage && (
              <LoginError title="Couldn't verify recovery code" error={errorMessage} invalidRootUser={false} />
            )}

            <VuiFormGroup
              label="Recovery Code"
              labelFor="recoveryCode"
              errors={!isValid ? ["Please enter the recovery code"] : undefined}
            >
              <VuiTextInput
                name="recoveryCode"
                value={recoveryCode}
                onChange={(e) => setRecoveryCode(e.target.value)}
                isInvalid={!isValid}
                autoComplete="off"
                fullWidth
              />
            </VuiFormGroup>

            <VuiSpacer size="l" />

            <VuiButtonPrimary
              fullWidth
              color="primary"
              onClick={handleSubmit}
              isLoading={isSubmitting}
              isDisabled={recoveryCode.trim().length === 0}
            >
              {isSubmitting ? "Verifying..." : "Verify Recovery Code"}
            </VuiButtonPrimary>

            <VuiSpacer size="m" />

            <VuiText align="center">
              <p>
                <VuiTextColor color="subdued">Remember your password?</VuiTextColor>{" "}
                <VuiLink onClick={() => navigate("/login")}>Back to sign in</VuiLink>
              </p>
            </VuiText>
          </div>
        }
      />
    </div>
  );
}
