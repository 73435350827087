import { SettingsFlow, UpdateSettingsFlowBody } from "@ory/client";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { orySdk } from "../../../utils/auth/orySdk";
import { logout } from "../../../contexts/user/commonAuth";
import {
  VuiCard,
  VuiFlexContainer,
  VuiFlexItem,
  VuiLink,
  VuiSpacer,
  VuiSpinner,
  VuiTitle,
  VuiButtonPrimary,
  VuiText,
  VuiTextColor
} from "@vectara/vectara-ui";
import logo from "../../../images/logo-vectara-dark.png";
import { useNotificationsContext } from "../../../contexts/NotificationsContext";
import { useUserContext } from "../../../contexts/UserContext";
import { insertCustomerId, insertUserName } from "../../../utils/queryParams";
import { getSession } from "../../../utils/localStorage/customer";
import { extractOryErrorMessage } from "./extractOryErrorMessage";
import { validatePassword } from "../../../utils/regex";
import { datadogRum } from "@datadog/browser-rum";
import { PasswordField } from "../PasswordField";
import { Field } from "../types";
import { LoginError } from "../loginPage/LoginError";

export default function SetNewOryPassword() {
  const { deauthenticate } = useUserContext();
  const [flow, setFlow] = useState<SettingsFlow | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { addNotification } = useNotificationsContext();
  const navigate = useNavigate();
  // Form state
  const [password, setPassword] = useState<Field>({ value: "", isValid: true });
  const [confirmPassword, setConfirmPassword] = useState<Field>({ value: "", isValid: true });
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onInitialError = (error: unknown) => {
    datadogRum.addError(error);
    addNotification("There was an error while setting your new password.", "danger");
    navigate("/login", { replace: true });
  };

  const getFlow = useCallback(
    (flowId: string) =>
      orySdk
        .getSettingsFlow({ id: flowId })
        .then(({ data: flow }) => setFlow(flow))
        .catch((e) => {
          console.log("failed to getFlow", e);
          onInitialError(e);
        }),
    []
  );

  const createFlow = () => {
    orySdk
      .createBrowserSettingsFlow()
      .then(({ data: flow }) => {
        setSearchParams({ flow: flow.id });
        setFlow(flow);
      })
      .catch((e) => {
        console.log("failed to createFlow", e);
        onInitialError(e);
      });
  };

  const validateForm = () => {
    const isPassValid = validatePassword(password.value);
    const doPasswordsMatch = password.value === confirmPassword.value;

    setPassword({ ...password, isValid: isPassValid });
    setConfirmPassword({ ...confirmPassword, isValid: doPasswordsMatch });
    setIsPasswordMatch(doPasswordsMatch);

    return isPassValid && doPasswordsMatch;
  };

  const handleSubmit = async () => {
    if (!validateForm() || !flow) return;

    setIsSubmitting(true);
    setErrorMessage(null);

    try {
      // Get CSRF token from the flow
      const csrfNode = flow.ui?.nodes?.find((node) => (node.attributes as any).name === "csrf_token");
      const csrfToken = (csrfNode?.attributes as any)?.value;

      // Prepare the body for password update
      const body: UpdateSettingsFlowBody = {
        method: "password",
        password: password.value
      };

      if (csrfToken) body.csrf_token = csrfToken;

      const response = await orySdk.updateSettingsFlow({
        flow: flow.id,
        updateSettingsFlowBody: body
      });

      setFlow(response.data);
      await logout(true);

      const urlParams = new URLSearchParams();

      if (searchParams.get("c")) {
        urlParams.append("c", searchParams.get("c") as string);
      }

      if (searchParams.get("username")) {
        urlParams.append("username", searchParams.get("username") as string);
      }

      if (searchParams.get("invite")) {
        urlParams.append("invite", searchParams.get("invite") as string);
      }

      navigate({
        pathname: "/login",
        search: urlParams.toString()
      });
    } catch (e: any) {
      const errorMessage = extractOryErrorMessage(e);
      datadogRum.addError(e);
      const sessionInfo = getSession();
      const queryString = insertUserName(
        sessionInfo?.email,
        insertCustomerId(String(sessionInfo?.customerId ?? ""), searchParams)
      ).toString();

      // In case errorMessage has some value, we show notification with respective msg
      if (errorMessage) {
        console.log("error", e);
        setErrorMessage(errorMessage);
      }

      // Status Code: 400 happens in case of weak password
      // In all other cases the user should be redirected to /requestResetPassword
      if (e.response?.status !== 400) {
        console.log("failed to submit password", e);
        // Logging out in case of any other error
        await deauthenticate(queryString, "/requestResetPassword");
      }
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const flowId = searchParams.get("flow");

    if (flowId) {
      getFlow(flowId).catch(createFlow);
      return;
    }
    createFlow();
  }, []);

  if (!flow) {
    return (
      <div className="loginPage">
        <div className="loggedOutPageForm">
          <VuiFlexContainer justifyContent="center" alignItems="center">
            <VuiSpinner />
          </VuiFlexContainer>
        </div>
      </div>
    );
  }

  const flowMessages =
    flow.ui?.messages?.map((message) => message.text).join(", ") ?? "Create a new password for your account";

  return (
    <div className="loginPage">
      <VuiCard
        padding="s"
        className="loggedOutPageForm"
        header={
          <VuiFlexContainer
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing="s"
            className="loggedOutFormHeader"
          >
            <VuiFlexItem grow={false} shrink={false}>
              <VuiLink href="/">
                <img src={logo} alt="Vectara logo" className="loggedOutFormLogo" />
              </VuiLink>
            </VuiFlexItem>

            <VuiFlexItem grow={1} shrink={1}>
              <VuiTitle size="s" align="center" data-testid="setNewPasswordTitle">
                <h1>Set new password</h1>
              </VuiTitle>
            </VuiFlexItem>
          </VuiFlexContainer>
        }
        body={
          <div className="loginFormBody">
            {flowMessages && (
              <>
                <VuiText>
                  <p>{flowMessages}</p>
                </VuiText>
                <VuiSpacer size="m" />
              </>
            )}

            {errorMessage && (
              <LoginError title="Couldn't set new password" error={errorMessage} invalidRootUser={false} />
            )}

            <PasswordField
              id="password"
              name="password"
              label="New Password"
              autoComplete="new-password"
              password={password}
              onChange={(value) => setPassword({ value, isValid: true })}
              fullWidth
            />

            <VuiSpacer size="m" />

            <PasswordField
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm Password"
              autoComplete="new-password"
              password={{
                ...confirmPassword,
                isValid: isPasswordMatch,
                error: !isPasswordMatch ? "Passwords don't match" : undefined
              }}
              onChange={(value) => setConfirmPassword({ value, isValid: true })}
              fullWidth
            />

            <VuiSpacer size="l" />

            <VuiButtonPrimary fullWidth color="primary" onClick={handleSubmit} isLoading={isSubmitting}>
              {isSubmitting ? "Setting new password..." : "Set new password"}
            </VuiButtonPrimary>

            <VuiSpacer size="m" />

            <VuiText align="center">
              <p>
                <VuiTextColor color="subdued">Remember your password?</VuiTextColor>{" "}
                <VuiLink onClick={() => navigate("/login")}>Back to sign in</VuiLink>
              </p>
            </VuiText>
          </div>
        }
      />
    </div>
  );
}
