import { createContext, useContext, ReactNode, useRef, useMemo } from "react";
import { CorporaState, UploadConfig, UploadStatus } from "../types/corporaState";
import { uploadFile } from "../apis/uploadApi";
import { useCorpusGlobalContext } from "./CorpusGlobalContext";
import { UploadFileResponse } from "../apis/apiV2Client";
import { pick } from "lodash";
import { corporaDb } from "../utils/idb/corporaDb";

type CorpusUploadStatus = "notStarted" | "complete" | "inProgress";

export type ChunkingStrategy = "sentence_chunking_strategy" | "max_chars_chunking_strategy";

export type UploadConfiguration = {
  isExtractTablesEnabled: boolean;
  metadata?: string;
  chunkingStrategy: ChunkingStrategy;
  maxCharsPerChunk?: number;
};

type UploadContextType = {
  getUploadQueue: (corpusKey: string) => UploadConfig[];
  getIsActive: (corpusKey: string) => boolean;
  getStatus: (corpusKey: string) => CorpusUploadStatus;
  addFilesToUploadQueue: (corpusKey: string, newFiles: UploadConfig[], queue: UploadConfig[]) => void;
  startUpload: (corpusKey: string, uploadQueue: UploadConfig[]) => void;
  retryFailedFiles: (corpusKey: string, failedFiles: UploadConfig[]) => void;
  removeFileFromUploadQueue: (corpusKey: string, file: UploadConfig) => void;
  resetUpload: (corpusKey: string) => void;
  stopUpload: (corpusKey: string) => void;
  isUploadInProgress: (corpusKey: string) => boolean;
  isAnyUploadInProgress: boolean;
  getUploadConfigurationForCorpus: (corpusKey: string) => Promise<UploadConfiguration>;
  setUploadConfigurationForCorpus: (
    corpusKey: string,
    configProperty: Partial<UploadConfiguration>
  ) => Promise<UploadConfiguration>;
};

const UploadContext = createContext<UploadContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const getUploadCounts = (item: CorporaState) => {
  const startedFiles = item.uploadFile.queue.filter((file) => file.status !== "notStarted");
  const totalCount = item.uploadFile.queue.length;
  let completedCount = 0;
  let errorCount = 0;

  startedFiles.forEach((file) => {
    if (["success", "error"].includes(file.status)) {
      completedCount++;
    }
    if (file.status === "error") {
      errorCount++;
    }
  });
  return {
    totalCount,
    startedFilesCount: startedFiles.length,
    completedCount,
    errorCount
  };
};

const getUploadStatus = (uploadState?: CorporaState["uploadFile"]): CorpusUploadStatus => {
  if (!uploadState) return "notStarted";

  if (!uploadState.isActive) {
    return "notStarted";
  }

  // If a single file is in progress, then the whole thing is in progress.
  if (uploadState.queue.find(({ status }) => status === "inProgress")) {
    return "inProgress";
  }

  return "complete";
};

export const DEFAULT_UPLOAD_CONFIG: UploadConfiguration = {
  isExtractTablesEnabled: false,
  metadata: undefined,
  chunkingStrategy: "sentence_chunking_strategy",
  maxCharsPerChunk: undefined
} as const;

export const UploadContextProvider = ({ children }: Props) => {
  const { corporaStates, setCorporaStates } = useCorpusGlobalContext();
  const corporaStatesRef = useRef(corporaStates);
  corporaStatesRef.current = corporaStates;

  const getUploadConfigurationForCorpus = async (corpusKey: string): Promise<UploadConfiguration> => {
    const uploadConfig = await corporaDb.getFileUploadConfiguration(corpusKey);

    return (uploadConfig as UploadConfiguration) ?? { ...DEFAULT_UPLOAD_CONFIG };
  };

  const setUploadConfigurationForCorpus = async (corpusKey: string, configProperty: Partial<UploadConfiguration>) => {
    const uploadConfig = await getUploadConfigurationForCorpus(corpusKey);

    const updatedUploadConfig = {
      ...(uploadConfig ?? DEFAULT_UPLOAD_CONFIG),
      ...configProperty
    };

    const updatedConfiguration = await corporaDb.setFileUploadConfiguration(corpusKey, updatedUploadConfig);
    return updatedConfiguration ?? DEFAULT_UPLOAD_CONFIG;
  };

  const getUploadingState = (corpusKey: string) => {
    return corporaStates[corpusKey]?.uploadFile;
  };

  const getStatus = (corpusKey: string) => {
    const state = getUploadingState(corpusKey);
    return getUploadStatus(state);
  };

  const getIsActive = (corpusKey: string) => {
    const state = getUploadingState(corpusKey);
    return state?.isActive ?? false;
  };

  const setIsActive = (corpusKey: string, isActive: boolean) => {
    setCorporaStates((prev) => {
      const prevCorpusState = prev[corpusKey] ?? {};
      const prevUploadingState = prevCorpusState.uploadFile;

      if (!prevUploadingState) return prev;

      return {
        ...prev,
        [corpusKey]: {
          ...prevCorpusState,
          uploadFile: {
            ...prevUploadingState,
            isActive
          }
        }
      };
    });
  };

  const resetUpload = (corpusKey: string) => {
    setCorporaStates((prev) => {
      const prevState = prev[corpusKey];
      if (!prevState) return prev;

      return {
        ...prev,
        [corpusKey]: {
          ...prevState,
          uploadFile: {
            isActive: false,
            queue: []
          }
        }
      };
    });
  };

  const getUploadQueue = (corpusKey: string) => {
    return getUploadingState(corpusKey)?.queue;
  };

  const createNewUploadQueue = (queue: UploadConfig[], targetQueue?: UploadConfig[]): UploadConfig[] => {
    const startingQueue = targetQueue ?? [];

    // Ignore duplicates.
    const mergedQueue = [...startingQueue, ...queue].reduce((acc, file) => {
      const existingFile = acc.find((f) => f.fullPath === file.fullPath);
      if (existingFile) {
        return acc;
      }
      return [...acc, file];
    }, [] as UploadConfig[]);

    // Reset status.
    return mergedQueue.map((file) => ({
      ...file,
      error: undefined,
      status: "notStarted"
    }));
  };

  const setUploadQueue = (corpusKey: string, queue: UploadConfig[]) => {
    setCorporaStates((prev) => {
      const prevCorpusState = prev[corpusKey] ?? {};
      const prevUploadingState = prevCorpusState.uploadFile;

      return {
        ...prev,
        [corpusKey]: {
          ...prevCorpusState,
          uploadFile: {
            ...(prevUploadingState ?? {}),
            queue
          }
        }
      };
    });
  };

  const setFileStatus = ({
    corpusKey,
    uploadConfig,
    uploadStatus,
    error,
    data
  }: {
    corpusKey: string;
    uploadConfig: UploadConfig;
    uploadStatus: UploadStatus;
    error?: UploadConfig["error"];
    data?: UploadFileResponse;
  }) => {
    setCorporaStates((prev) => {
      const prevState = prev[corpusKey];

      const updatedQueue = prevState.uploadFile.queue.map((file) => {
        if (file.fullPath === uploadConfig.fullPath) {
          return {
            ...file,
            status: uploadStatus,
            error,
            response: pick(data, ["id", "storage_usage", "extraction_usage"])
          };
        }
        return file;
      });

      return {
        ...prev,
        [corpusKey]: {
          ...prevState,
          uploadFile: {
            ...prevState.uploadFile,
            queue: updatedQueue
          }
        }
      };
    });
  };

  const addFilesToUploadQueue = (corpusKey: string, newFiles: UploadConfig[], queue: UploadConfig[]) => {
    const newUploadQueue = createNewUploadQueue(newFiles, queue);
    setUploadQueue(corpusKey, newUploadQueue);
  };

  const removeFileFromUploadQueue = (corpusKey: string, file: UploadConfig) => {
    const newUploadQueue = getUploadQueue(corpusKey).filter((f) => f.fullPath !== file.fullPath);
    setUploadQueue(corpusKey, newUploadQueue);
  };

  const startUpload = async (corpusKey: string, uploadQueue: UploadConfig[]) => {
    const { isExtractTablesEnabled, metadata, chunkingStrategy, maxCharsPerChunk } =
      await getUploadConfigurationForCorpus(corpusKey);

    setIsActive(corpusKey, true);
    uploadQueue.forEach((item) => {
      if (item.status !== "success") {
        item.status = "notStarted";
      }
    });

    // This number depends on how many encoders are available on the back-end.
    const MAX_PARALLEL_UPLOADS = 8;
    let numParallelUploads = 0;

    const start = async () => {
      let isComplete = false;
      while (!isComplete && numParallelUploads < MAX_PARALLEL_UPLOADS) {
        // Work through files in the order in which they were added.
        const nextUpload = uploadQueue.find(({ status }) => status === "notStarted");

        if (nextUpload) {
          numParallelUploads++;
          nextUpload.status = "inProgress";
          setFileStatus({ corpusKey, uploadConfig: nextUpload, uploadStatus: "inProgress" });

          const upload = uploadFile({
            payload: {
              fileOrBlob: nextUpload.fileOrBlob,
              corpusKey,
              isExtractTablesEnabled,
              metadata,
              chunkingStrategy,
              maxCharsPerChunk
            },
            onComplete: ({ status, error, data }) =>
              setFileStatus({
                corpusKey,
                uploadConfig: nextUpload,
                uploadStatus: status,
                error,
                data
              }),
            controller: nextUpload.controller
          });

          upload.then(() => {
            numParallelUploads--;
            start();
          });
        } else {
          isComplete = true;
        }
      }
    };

    start();
  };

  const retryFailedFiles = (corpusKey: string, failedFiles: UploadConfig[]) => {
    const newUploadQueue = createNewUploadQueue(failedFiles);
    setUploadQueue(corpusKey, newUploadQueue);
    startUpload(corpusKey, newUploadQueue);
  };

  const stopUpload = (corpusKey: string) => {
    const uploadQueue = getUploadQueue(corpusKey);

    uploadQueue.forEach((item) => {
      const { status, controller } = item;
      if (status !== "success" && status !== "error") {
        controller.abort();
        item.status = "notStarted";
      }
    });

    setUploadQueue(corpusKey, uploadQueue);
    setIsActive(corpusKey, false);
  };

  const isUploadInProgress = (corpusKey: string) => {
    const corpusState = corporaStates[corpusKey];
    if (!corpusState?.uploadFile) return false;

    const { startedFilesCount, completedCount } = getUploadCounts(corpusState);
    return completedCount !== startedFilesCount;
  };

  const isAnyUploadInProgress = useMemo(
    () => Object.values(corporaStates).some((corpusState) => isUploadInProgress(corpusState?.corpusInfo?.key)),
    [corporaStates]
  );

  return (
    <UploadContext.Provider
      value={{
        getUploadQueue,
        getIsActive,
        getStatus,
        addFilesToUploadQueue,
        startUpload,
        retryFailedFiles,
        removeFileFromUploadQueue,
        resetUpload,
        stopUpload,
        isUploadInProgress,
        isAnyUploadInProgress,
        getUploadConfigurationForCorpus,
        setUploadConfigurationForCorpus
      }}
    >
      {children}
    </UploadContext.Provider>
  );
};

export const useUploadContext = () => {
  const context = useContext(UploadContext);
  if (context === undefined) {
    throw new Error("useUploadContext must be used within a UploadContextProvider");
  }
  return context;
};
