import { IDBPDatabase, openDB } from "idb";
import { UploadConfiguration } from "../../contexts/UploadContext";

type CorporaDbSchema = {
  "file-upload-config": {
    key: string;
    value: UploadConfiguration;
  };
  "recent-queries": {
    key: string;
    value: string[];
  };
  "pretty-request-preferences": {
    key: string;
    value: string | boolean;
  };
  "is-side-nav-pinned": {
    key: string;
    value: boolean;
  };
  "is-query-input-panel-docked": {
    key: string;
    value: boolean;
  };
  "query-input-panel-width": {
    key: string;
    value: number;
  };
};

class CorporaDb {
  private dbPromise: Promise<IDBPDatabase<CorporaDbSchema>>;

  constructor() {
    // When updating schema/structure, increment the version number and
    // implement migration logic (adding/removing object stores and indices).
    // Since we're using this as KV store, migrations will typically be needed
    // if we:
    //   * Change the names of the keys
    //   * Change the structure of the values
    this.dbPromise = openDB<CorporaDbSchema>("corpora", 2, {
      // The upgrade callback is called when the database is first created
      // or when the version number is incremented.
      async upgrade(db, oldVersion, newVersion, transaction) {
        (
          [
            "file-upload-config",
            "recent-queries",
            "pretty-request-preferences",
            "is-side-nav-pinned",
            "is-query-input-panel-docked",
            "query-input-panel-width"
          ] as const
        ).forEach((storeName) => {
          if (!db.objectStoreNames.contains(storeName)) {
            db.createObjectStore(storeName);
          }
        });

        const upgradeV1toV2 = async () => {
          const fileUploadConfigStore = transaction.objectStore("file-upload-config");

          // Return a promise that resolves when the migration is complete.
          return fileUploadConfigStore.getAll().then((fileUploadConfigs) => {
            const promises = fileUploadConfigs.map((fileUploadConfig) => {
              // Upgrade file upload configs with missing chunkingStrategy.
              if (!fileUploadConfig.chunkingStrategy) {
                fileUploadConfig.chunkingStrategy = fileUploadConfig.maxCharsPerChunk
                  ? "max_chars_chunking_strategy"
                  : "sentence_chunking_strategy";
              }

              return fileUploadConfigStore.put(fileUploadConfig);
            });

            return Promise.all(promises);
          });
        };

        // https://hackernoon.com/use-indexeddb-with-idb-a-1kb-library-that-makes-it-easy-8p1f3yqq
        switch (oldVersion) {
          case 0:
          case 1:
            await upgradeV1toV2();
            break;
          default:
            console.error(`Unknown DB version: ${oldVersion}`);
        }
      }
    });
  }
  // File Upload Configurations
  async getFileUploadConfiguration(key: string) {
    return this.get("file-upload-config", key);
  }

  async setFileUploadConfiguration(key: string, val: UploadConfiguration) {
    return this.set("file-upload-config", key, val);
  }

  // Recent queries
  async getRecentQueries(key: string) {
    return (await this.dbPromise).get("recent-queries", key);
  }

  async setRecentQueries(key: string, val: string[]) {
    return (await this.dbPromise).put("recent-queries", val, key);
  }

  async deleteRecentQueries(key: string) {
    return this.delete("recent-queries", key);
  }

  // Pretty request preferences
  async getPrettyRequestPreferences(key: string) {
    return (await this.dbPromise).get("pretty-request-preferences", key);
  }

  async setPrettyRequestPreferences(key: string, val: string | boolean) {
    return (await this.dbPromise).put("pretty-request-preferences", val, key);
  }

  // Side nav pinned
  async getIsSideNavPinned(key: string) {
    return (await this.dbPromise).get("is-side-nav-pinned", key);
  }

  async setIsSideNavPinned(key: string, val: boolean) {
    return (await this.dbPromise).put("is-side-nav-pinned", val, key);
  }

  // Query input panel docked
  async getIsQueryInputPanelDocked(key: string) {
    return (await this.dbPromise).get("is-query-input-panel-docked", key);
  }

  async setIsQueryInputPanelDocked(key: string, val: boolean) {
    return (await this.dbPromise).put("is-query-input-panel-docked", val, key);
  }

  // Query input panel width
  async getQueryInputPanelWidth(key: string) {
    return (await this.dbPromise).get("query-input-panel-width", key);
  }

  async setQueryInputPanelWidth(key: string, val: number) {
    return (await this.dbPromise).put("query-input-panel-width", val, key);
  }

  // Utilities
  private async get(keyvalStore: keyof CorporaDbSchema, key: string) {
    return (await this.dbPromise).get(keyvalStore, key);
  }

  private async set<K extends keyof CorporaDbSchema>(
    keyvalStore: K,
    key: CorporaDbSchema[K]["key"],
    val: CorporaDbSchema[K]["value"]
  ) {
    (await this.dbPromise).put(keyvalStore, val, key);

    // Return the fresh value.
    return (await this.dbPromise).get(keyvalStore, key);
  }

  private async delete(keyvalStore: keyof CorporaDbSchema, key: string) {
    return (await this.dbPromise).delete(keyvalStore, key);
  }
}

export const corporaDb = new CorporaDb();
