import { REST_SERVING_URL } from "../backendConfig";
import { toV2Id, V2IdPrefix } from "../utils/idConversion";
import {
  ApiV2,
  apiV2Client,
  ApiV2QueryParams,
  CustomDimension,
  FilterAttribute,
  withApiErrorHandling
} from "./apiV2Client";

export const sendFetchCorporaRequest = async (payload?: ApiV2QueryParams<"listCorpora">) => {
  const { data } = await withApiErrorHandling(
    apiV2Client.GET("/v2/corpora", {
      params: {
        query: payload
      }
    })
  );

  return data;
};

export const sendFetchCorpusRequest = async (corpusKey: string, signal?: AbortSignal) => {
  const { data } = await withApiErrorHandling(
    apiV2Client.GET("/v2/corpora/{corpus_key}", {
      params: {
        path: {
          corpus_key: corpusKey
        }
      },
      signal
    })
  );

  return data;
};

export const sendUpdateCorpusRequest = async (corpusKey: string, corpusData: ApiV2["UpdateCorpusRequest"]) => {
  const { data } = await withApiErrorHandling(
    apiV2Client.PATCH("/v2/corpora/{corpus_key}", {
      params: {
        path: {
          corpus_key: corpusKey
        }
      },
      body: corpusData
    })
  );

  return data;
};

export const sendDeleteCorpusRequest = async (corpusKey: string) => {
  const { data } = await withApiErrorHandling(
    apiV2Client.DELETE("/v2/corpora/{corpus_key}", {
      params: {
        path: {
          corpus_key: corpusKey
        }
      }
    })
  );

  return data;
};

export const sendResetCorpusRequest = async (corpusKey: string) => {
  const { data } = await withApiErrorHandling(
    apiV2Client.POST("/v2/corpora/{corpus_key}/reset", {
      params: {
        path: {
          corpus_key: corpusKey
        }
      }
    })
  );

  return data;
};

export const sendComputeCorpusSizeRequest = async (corpusKey: string) => {
  const { data } = await withApiErrorHandling(
    apiV2Client.POST("/v2/corpora/{corpus_key}/compute_size", {
      params: {
        path: {
          corpus_key: corpusKey
        }
      }
    })
  );

  return data;
};

export type CreateCorpusRequestConfig = {
  corpusKey: string;
  name: string;
  description: string;
  documentsAreQuestions: boolean;
  filterAttrs: FilterAttribute[];
  customDims: CustomDimension[];
  encoderId: number;
};

export const generateCreateCorpusRequest = (
  jwt: string,
  customerId: string,
  { corpusKey, name, description, documentsAreQuestions, filterAttrs, customDims, encoderId }: CreateCorpusRequestConfig
) => {
  const body = {
    key: corpusKey,
    name,
    description,
    documents_are_questions: documentsAreQuestions,
    encoder_id: toV2Id(encoderId, V2IdPrefix.ENCODER),
    filter_attributes: filterAttrs,
    custom_dimensions: customDims
  };

  const endpoint = "/v2/corpora";

  return {
    method: "POST",
    url: `${REST_SERVING_URL}${endpoint}`,
    endpoint,
    headers: {
      "customer-id": customerId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`
    },
    body
  } as const;
};

export type CreateCorpusRequest = ReturnType<typeof generateCreateCorpusRequest>;

export const sendCreateCorpusRequest = async (config: CreateCorpusRequestConfig) => {
  const { body } = generateCreateCorpusRequest("", "", config);

  const { data, response } = await withApiErrorHandling(
    apiV2Client.POST("/v2/corpora", {
      body
    })
  );

  return { data, response };
};
