// source: admin/admin_corpus.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var protoc$gen$openapiv2_options_annotations_pb = require('../protoc-gen-openapiv2/options/annotations_pb.js');
goog.object.extend(proto, protoc$gen$openapiv2_options_annotations_pb);
var admin_admin_apikey_pb = require('../admin/admin_apikey_pb.js');
goog.object.extend(proto, admin_admin_apikey_pb);
var admin_admin_metric_pb = require('../admin/admin_metric_pb.js');
goog.object.extend(proto, admin_admin_metric_pb);
var status_pb = require('../status_pb.js');
goog.object.extend(proto, status_pb);
goog.exportSymbol('proto.com.vectara.admin.AsyncIndexStats', null, global);
goog.exportSymbol('proto.com.vectara.admin.ComputeBackgroundRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ComputeBackgroundResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.ComputeCorpusSizeRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ComputeCorpusSizeResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.ComputeRecallRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ComputeRecallResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.Corpus', null, global);
goog.exportSymbol('proto.com.vectara.admin.CorpusInfoRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.CorpusInfoResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.CorpusRecall', null, global);
goog.exportSymbol('proto.com.vectara.admin.CorpusSize', null, global);
goog.exportSymbol('proto.com.vectara.admin.CreateCorpusRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.CreateCorpusResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.DeleteCorpusRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.DeleteCorpusResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.Dimension', null, global);
goog.exportSymbol('proto.com.vectara.admin.FilterAttribute', null, global);
goog.exportSymbol('proto.com.vectara.admin.FilterAttributeLevel', null, global);
goog.exportSymbol('proto.com.vectara.admin.FilterAttributeType', null, global);
goog.exportSymbol('proto.com.vectara.admin.ListCorporaRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ListCorporaResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadCorpusRecallRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadCorpusRecallResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadCorpusRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadCorpusResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadCorpusSizeRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadCorpusSizeResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.ResetCorpusRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ResetCorpusResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.SortKey', null, global);
goog.exportSymbol('proto.com.vectara.admin.SortOptions', null, global);
goog.exportSymbol('proto.com.vectara.admin.SortOrder', null, global);
goog.exportSymbol('proto.com.vectara.admin.UpdateCorpusEnablementRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.UpdateCorpusEnablementResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.UpdateCorpusRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.UpdateCorpusResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.Corpus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.Corpus.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.Corpus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.Corpus.displayName = 'proto.com.vectara.admin.Corpus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.AsyncIndexStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.AsyncIndexStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.AsyncIndexStats.displayName = 'proto.com.vectara.admin.AsyncIndexStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.Dimension = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.Dimension, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.Dimension.displayName = 'proto.com.vectara.admin.Dimension';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.FilterAttribute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.FilterAttribute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.FilterAttribute.displayName = 'proto.com.vectara.admin.FilterAttribute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.CreateCorpusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.CreateCorpusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.CreateCorpusRequest.displayName = 'proto.com.vectara.admin.CreateCorpusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.CreateCorpusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.CreateCorpusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.CreateCorpusResponse.displayName = 'proto.com.vectara.admin.CreateCorpusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.UpdateCorpusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.UpdateCorpusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.UpdateCorpusRequest.displayName = 'proto.com.vectara.admin.UpdateCorpusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.UpdateCorpusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.UpdateCorpusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.UpdateCorpusResponse.displayName = 'proto.com.vectara.admin.UpdateCorpusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.DeleteCorpusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.DeleteCorpusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.DeleteCorpusRequest.displayName = 'proto.com.vectara.admin.DeleteCorpusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.DeleteCorpusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.DeleteCorpusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.DeleteCorpusResponse.displayName = 'proto.com.vectara.admin.DeleteCorpusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ResetCorpusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ResetCorpusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ResetCorpusRequest.displayName = 'proto.com.vectara.admin.ResetCorpusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ResetCorpusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ResetCorpusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ResetCorpusResponse.displayName = 'proto.com.vectara.admin.ResetCorpusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ListCorporaRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.ListCorporaRequest.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ListCorporaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ListCorporaRequest.displayName = 'proto.com.vectara.admin.ListCorporaRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ListCorporaResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.com.vectara.admin.ListCorporaResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ListCorporaResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ListCorporaResponse.displayName = 'proto.com.vectara.admin.ListCorporaResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.UpdateCorpusEnablementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.UpdateCorpusEnablementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.UpdateCorpusEnablementRequest.displayName = 'proto.com.vectara.admin.UpdateCorpusEnablementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.UpdateCorpusEnablementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.UpdateCorpusEnablementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.UpdateCorpusEnablementResponse.displayName = 'proto.com.vectara.admin.UpdateCorpusEnablementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ComputeRecallRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ComputeRecallRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ComputeRecallRequest.displayName = 'proto.com.vectara.admin.ComputeRecallRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ComputeRecallResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.com.vectara.admin.ComputeRecallResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ComputeRecallResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ComputeRecallResponse.displayName = 'proto.com.vectara.admin.ComputeRecallResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ComputeBackgroundRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ComputeBackgroundRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ComputeBackgroundRequest.displayName = 'proto.com.vectara.admin.ComputeBackgroundRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ComputeBackgroundResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.com.vectara.admin.ComputeBackgroundResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ComputeBackgroundResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ComputeBackgroundResponse.displayName = 'proto.com.vectara.admin.ComputeBackgroundResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.CorpusInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.CorpusInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.CorpusInfoRequest.displayName = 'proto.com.vectara.admin.CorpusInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.CorpusInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.com.vectara.admin.CorpusInfoResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.CorpusInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.CorpusInfoResponse.displayName = 'proto.com.vectara.admin.CorpusInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.CorpusSize = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.CorpusSize, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.CorpusSize.displayName = 'proto.com.vectara.admin.CorpusSize';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.CorpusRecall = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.CorpusRecall, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.CorpusRecall.displayName = 'proto.com.vectara.admin.CorpusRecall';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadCorpusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.com.vectara.admin.ReadCorpusRequest.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ReadCorpusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadCorpusRequest.displayName = 'proto.com.vectara.admin.ReadCorpusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadCorpusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.ReadCorpusResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ReadCorpusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadCorpusResponse.displayName = 'proto.com.vectara.admin.ReadCorpusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.displayName = 'proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadCorpusSizeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ReadCorpusSizeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadCorpusSizeRequest.displayName = 'proto.com.vectara.admin.ReadCorpusSizeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadCorpusSizeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.ReadCorpusSizeResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ReadCorpusSizeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadCorpusSizeResponse.displayName = 'proto.com.vectara.admin.ReadCorpusSizeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ComputeCorpusSizeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ComputeCorpusSizeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ComputeCorpusSizeRequest.displayName = 'proto.com.vectara.admin.ComputeCorpusSizeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ComputeCorpusSizeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ComputeCorpusSizeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ComputeCorpusSizeResponse.displayName = 'proto.com.vectara.admin.ComputeCorpusSizeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadCorpusRecallRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ReadCorpusRecallRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadCorpusRecallRequest.displayName = 'proto.com.vectara.admin.ReadCorpusRecallRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadCorpusRecallResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.ReadCorpusRecallResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ReadCorpusRecallResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadCorpusRecallResponse.displayName = 'proto.com.vectara.admin.ReadCorpusRecallResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest.displayName = 'proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse.displayName = 'proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.SortOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.SortOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.SortOptions.displayName = 'proto.com.vectara.admin.SortOptions';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.Corpus.repeatedFields_ = [13,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.Corpus.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.Corpus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.Corpus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.Corpus.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    key: jspb.Message.getFieldWithDefault(msg, 15, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dtProvision: jspb.Message.getFieldWithDefault(msg, 4, 0),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    save: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    swapQenc: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    swapIenc: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    textless: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    encrypted: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    encoderId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    encoderName: jspb.Message.getFieldWithDefault(msg, 16, ""),
    metadataMaxBytes: jspb.Message.getFieldWithDefault(msg, 11, 0),
    faissIndexType: jspb.Message.getFieldWithDefault(msg, 12, ""),
    customDimensionsList: jspb.Message.toObjectList(msg.getCustomDimensionsList(),
    proto.com.vectara.admin.Dimension.toObject, includeInstance),
    filterAttributesList: jspb.Message.toObjectList(msg.getFilterAttributesList(),
    proto.com.vectara.admin.FilterAttribute.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.Corpus}
 */
proto.com.vectara.admin.Corpus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.Corpus;
  return proto.com.vectara.admin.Corpus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.Corpus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.Corpus}
 */
proto.com.vectara.admin.Corpus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDtProvision(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSave(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSwapQenc(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSwapIenc(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTextless(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEncrypted(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEncoderId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setEncoderName(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMetadataMaxBytes(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaissIndexType(value);
      break;
    case 13:
      var value = new proto.com.vectara.admin.Dimension;
      reader.readMessage(value,proto.com.vectara.admin.Dimension.deserializeBinaryFromReader);
      msg.addCustomDimensions(value);
      break;
    case 14:
      var value = new proto.com.vectara.admin.FilterAttribute;
      reader.readMessage(value,proto.com.vectara.admin.FilterAttribute.deserializeBinaryFromReader);
      msg.addFilterAttributes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.Corpus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.Corpus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.Corpus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.Corpus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDtProvision();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getSave();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getSwapQenc();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getSwapIenc();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getTextless();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getEncrypted();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getEncoderId();
  if (f !== 0) {
    writer.writeUint64(
      10,
      f
    );
  }
  f = message.getEncoderName();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getMetadataMaxBytes();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getFaissIndexType();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCustomDimensionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.com.vectara.admin.Dimension.serializeBinaryToWriter
    );
  }
  f = message.getFilterAttributesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.com.vectara.admin.FilterAttribute.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.com.vectara.admin.Corpus.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.Corpus} returns this
 */
proto.com.vectara.admin.Corpus.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.vectara.admin.Corpus.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.Corpus} returns this
 */
proto.com.vectara.admin.Corpus.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string key = 15;
 * @return {string}
 */
proto.com.vectara.admin.Corpus.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.Corpus} returns this
 */
proto.com.vectara.admin.Corpus.prototype.setKey = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.admin.Corpus} returns this
 */
proto.com.vectara.admin.Corpus.prototype.clearKey = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.Corpus.prototype.hasKey = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.com.vectara.admin.Corpus.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.Corpus} returns this
 */
proto.com.vectara.admin.Corpus.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 dt_provision = 4;
 * @return {number}
 */
proto.com.vectara.admin.Corpus.prototype.getDtProvision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.Corpus} returns this
 */
proto.com.vectara.admin.Corpus.prototype.setDtProvision = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool enabled = 5;
 * @return {boolean}
 */
proto.com.vectara.admin.Corpus.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.admin.Corpus} returns this
 */
proto.com.vectara.admin.Corpus.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool save = 17;
 * @return {boolean}
 */
proto.com.vectara.admin.Corpus.prototype.getSave = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.admin.Corpus} returns this
 */
proto.com.vectara.admin.Corpus.prototype.setSave = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool swap_qenc = 6;
 * @return {boolean}
 */
proto.com.vectara.admin.Corpus.prototype.getSwapQenc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.admin.Corpus} returns this
 */
proto.com.vectara.admin.Corpus.prototype.setSwapQenc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool swap_ienc = 7;
 * @return {boolean}
 */
proto.com.vectara.admin.Corpus.prototype.getSwapIenc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.admin.Corpus} returns this
 */
proto.com.vectara.admin.Corpus.prototype.setSwapIenc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool textless = 8;
 * @return {boolean}
 */
proto.com.vectara.admin.Corpus.prototype.getTextless = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.admin.Corpus} returns this
 */
proto.com.vectara.admin.Corpus.prototype.setTextless = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool encrypted = 9;
 * @return {boolean}
 */
proto.com.vectara.admin.Corpus.prototype.getEncrypted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.admin.Corpus} returns this
 */
proto.com.vectara.admin.Corpus.prototype.setEncrypted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional uint64 encoder_id = 10;
 * @return {number}
 */
proto.com.vectara.admin.Corpus.prototype.getEncoderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.Corpus} returns this
 */
proto.com.vectara.admin.Corpus.prototype.setEncoderId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string encoder_name = 16;
 * @return {string}
 */
proto.com.vectara.admin.Corpus.prototype.getEncoderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.Corpus} returns this
 */
proto.com.vectara.admin.Corpus.prototype.setEncoderName = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional uint32 metadata_max_bytes = 11;
 * @return {number}
 */
proto.com.vectara.admin.Corpus.prototype.getMetadataMaxBytes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.Corpus} returns this
 */
proto.com.vectara.admin.Corpus.prototype.setMetadataMaxBytes = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string faiss_index_type = 12;
 * @return {string}
 */
proto.com.vectara.admin.Corpus.prototype.getFaissIndexType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.Corpus} returns this
 */
proto.com.vectara.admin.Corpus.prototype.setFaissIndexType = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * repeated Dimension custom_dimensions = 13;
 * @return {!Array<!proto.com.vectara.admin.Dimension>}
 */
proto.com.vectara.admin.Corpus.prototype.getCustomDimensionsList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.Dimension>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.Dimension, 13));
};


/**
 * @param {!Array<!proto.com.vectara.admin.Dimension>} value
 * @return {!proto.com.vectara.admin.Corpus} returns this
*/
proto.com.vectara.admin.Corpus.prototype.setCustomDimensionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.com.vectara.admin.Dimension=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.Dimension}
 */
proto.com.vectara.admin.Corpus.prototype.addCustomDimensions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.com.vectara.admin.Dimension, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.Corpus} returns this
 */
proto.com.vectara.admin.Corpus.prototype.clearCustomDimensionsList = function() {
  return this.setCustomDimensionsList([]);
};


/**
 * repeated FilterAttribute filter_attributes = 14;
 * @return {!Array<!proto.com.vectara.admin.FilterAttribute>}
 */
proto.com.vectara.admin.Corpus.prototype.getFilterAttributesList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.FilterAttribute>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.FilterAttribute, 14));
};


/**
 * @param {!Array<!proto.com.vectara.admin.FilterAttribute>} value
 * @return {!proto.com.vectara.admin.Corpus} returns this
*/
proto.com.vectara.admin.Corpus.prototype.setFilterAttributesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.com.vectara.admin.FilterAttribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.FilterAttribute}
 */
proto.com.vectara.admin.Corpus.prototype.addFilterAttributes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.com.vectara.admin.FilterAttribute, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.Corpus} returns this
 */
proto.com.vectara.admin.Corpus.prototype.clearFilterAttributesList = function() {
  return this.setFilterAttributesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.AsyncIndexStats.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.AsyncIndexStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.AsyncIndexStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.AsyncIndexStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    pendingCount: jspb.Message.getFieldWithDefault(msg, 20, 0),
    lastAddedEpochSecs: jspb.Message.getFieldWithDefault(msg, 21, 0),
    lastProcessedEpochSecs: jspb.Message.getFieldWithDefault(msg, 22, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.AsyncIndexStats}
 */
proto.com.vectara.admin.AsyncIndexStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.AsyncIndexStats;
  return proto.com.vectara.admin.AsyncIndexStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.AsyncIndexStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.AsyncIndexStats}
 */
proto.com.vectara.admin.AsyncIndexStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 20:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPendingCount(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastAddedEpochSecs(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastProcessedEpochSecs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.AsyncIndexStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.AsyncIndexStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.AsyncIndexStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.AsyncIndexStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPendingCount();
  if (f !== 0) {
    writer.writeUint32(
      20,
      f
    );
  }
  f = message.getLastAddedEpochSecs();
  if (f !== 0) {
    writer.writeUint64(
      21,
      f
    );
  }
  f = message.getLastProcessedEpochSecs();
  if (f !== 0) {
    writer.writeUint64(
      22,
      f
    );
  }
};


/**
 * optional uint32 pending_count = 20;
 * @return {number}
 */
proto.com.vectara.admin.AsyncIndexStats.prototype.getPendingCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.AsyncIndexStats} returns this
 */
proto.com.vectara.admin.AsyncIndexStats.prototype.setPendingCount = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional uint64 last_added_epoch_secs = 21;
 * @return {number}
 */
proto.com.vectara.admin.AsyncIndexStats.prototype.getLastAddedEpochSecs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.AsyncIndexStats} returns this
 */
proto.com.vectara.admin.AsyncIndexStats.prototype.setLastAddedEpochSecs = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional uint64 last_processed_epoch_secs = 22;
 * @return {number}
 */
proto.com.vectara.admin.AsyncIndexStats.prototype.getLastProcessedEpochSecs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.AsyncIndexStats} returns this
 */
proto.com.vectara.admin.AsyncIndexStats.prototype.setLastProcessedEpochSecs = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.Dimension.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.Dimension.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.Dimension} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.Dimension.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    servingDefault: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    indexingDefault: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.Dimension}
 */
proto.com.vectara.admin.Dimension.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.Dimension;
  return proto.com.vectara.admin.Dimension.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.Dimension} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.Dimension}
 */
proto.com.vectara.admin.Dimension.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setServingDefault(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setIndexingDefault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.Dimension.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.Dimension.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.Dimension} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.Dimension.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getServingDefault();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getIndexingDefault();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.vectara.admin.Dimension.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.Dimension} returns this
 */
proto.com.vectara.admin.Dimension.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.com.vectara.admin.Dimension.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.Dimension} returns this
 */
proto.com.vectara.admin.Dimension.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double serving_default = 3;
 * @return {number}
 */
proto.com.vectara.admin.Dimension.prototype.getServingDefault = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.Dimension} returns this
 */
proto.com.vectara.admin.Dimension.prototype.setServingDefault = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double indexing_default = 4;
 * @return {number}
 */
proto.com.vectara.admin.Dimension.prototype.getIndexingDefault = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.Dimension} returns this
 */
proto.com.vectara.admin.Dimension.prototype.setIndexingDefault = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.FilterAttribute.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.FilterAttribute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.FilterAttribute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.FilterAttribute.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description: jspb.Message.getFieldWithDefault(msg, 10, ""),
    indexed: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    type: jspb.Message.getFieldWithDefault(msg, 20, 0),
    level: jspb.Message.getFieldWithDefault(msg, 25, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.FilterAttribute}
 */
proto.com.vectara.admin.FilterAttribute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.FilterAttribute;
  return proto.com.vectara.admin.FilterAttribute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.FilterAttribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.FilterAttribute}
 */
proto.com.vectara.admin.FilterAttribute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIndexed(value);
      break;
    case 20:
      var value = /** @type {!proto.com.vectara.admin.FilterAttributeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 25:
      var value = /** @type {!proto.com.vectara.admin.FilterAttributeLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.FilterAttribute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.FilterAttribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.FilterAttribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.FilterAttribute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIndexed();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      25,
      f
    );
  }
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.com.vectara.admin.FilterAttribute.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.FilterAttribute} returns this
 */
proto.com.vectara.admin.FilterAttribute.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 10;
 * @return {string}
 */
proto.com.vectara.admin.FilterAttribute.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.FilterAttribute} returns this
 */
proto.com.vectara.admin.FilterAttribute.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool indexed = 15;
 * @return {boolean}
 */
proto.com.vectara.admin.FilterAttribute.prototype.getIndexed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.admin.FilterAttribute} returns this
 */
proto.com.vectara.admin.FilterAttribute.prototype.setIndexed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional FilterAttributeType type = 20;
 * @return {!proto.com.vectara.admin.FilterAttributeType}
 */
proto.com.vectara.admin.FilterAttribute.prototype.getType = function() {
  return /** @type {!proto.com.vectara.admin.FilterAttributeType} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.com.vectara.admin.FilterAttributeType} value
 * @return {!proto.com.vectara.admin.FilterAttribute} returns this
 */
proto.com.vectara.admin.FilterAttribute.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional FilterAttributeLevel level = 25;
 * @return {!proto.com.vectara.admin.FilterAttributeLevel}
 */
proto.com.vectara.admin.FilterAttribute.prototype.getLevel = function() {
  return /** @type {!proto.com.vectara.admin.FilterAttributeLevel} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {!proto.com.vectara.admin.FilterAttributeLevel} value
 * @return {!proto.com.vectara.admin.FilterAttribute} returns this
 */
proto.com.vectara.admin.FilterAttribute.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 25, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.CreateCorpusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.CreateCorpusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.CreateCorpusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CreateCorpusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpus: (f = msg.getCorpus()) && proto.com.vectara.admin.Corpus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.CreateCorpusRequest}
 */
proto.com.vectara.admin.CreateCorpusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.CreateCorpusRequest;
  return proto.com.vectara.admin.CreateCorpusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.CreateCorpusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.CreateCorpusRequest}
 */
proto.com.vectara.admin.CreateCorpusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.Corpus;
      reader.readMessage(value,proto.com.vectara.admin.Corpus.deserializeBinaryFromReader);
      msg.setCorpus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.CreateCorpusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.CreateCorpusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.CreateCorpusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CreateCorpusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.vectara.admin.Corpus.serializeBinaryToWriter
    );
  }
};


/**
 * optional Corpus corpus = 1;
 * @return {?proto.com.vectara.admin.Corpus}
 */
proto.com.vectara.admin.CreateCorpusRequest.prototype.getCorpus = function() {
  return /** @type{?proto.com.vectara.admin.Corpus} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.Corpus, 1));
};


/**
 * @param {?proto.com.vectara.admin.Corpus|undefined} value
 * @return {!proto.com.vectara.admin.CreateCorpusRequest} returns this
*/
proto.com.vectara.admin.CreateCorpusRequest.prototype.setCorpus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.CreateCorpusRequest} returns this
 */
proto.com.vectara.admin.CreateCorpusRequest.prototype.clearCorpus = function() {
  return this.setCorpus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.CreateCorpusRequest.prototype.hasCorpus = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.CreateCorpusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.CreateCorpusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.CreateCorpusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CreateCorpusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpusId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    corpusKey: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.CreateCorpusResponse}
 */
proto.com.vectara.admin.CreateCorpusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.CreateCorpusResponse;
  return proto.com.vectara.admin.CreateCorpusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.CreateCorpusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.CreateCorpusResponse}
 */
proto.com.vectara.admin.CreateCorpusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorpusId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorpusKey(value);
      break;
    case 2:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.CreateCorpusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.CreateCorpusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.CreateCorpusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CreateCorpusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCorpusKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 corpus_id = 1;
 * @return {number}
 */
proto.com.vectara.admin.CreateCorpusResponse.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.CreateCorpusResponse} returns this
 */
proto.com.vectara.admin.CreateCorpusResponse.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string corpus_key = 3;
 * @return {string}
 */
proto.com.vectara.admin.CreateCorpusResponse.prototype.getCorpusKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.CreateCorpusResponse} returns this
 */
proto.com.vectara.admin.CreateCorpusResponse.prototype.setCorpusKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional com.vectara.Status status = 2;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.CreateCorpusResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 2));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.CreateCorpusResponse} returns this
*/
proto.com.vectara.admin.CreateCorpusResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.CreateCorpusResponse} returns this
 */
proto.com.vectara.admin.CreateCorpusResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.CreateCorpusResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.UpdateCorpusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.UpdateCorpusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.UpdateCorpusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.UpdateCorpusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    save: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.UpdateCorpusRequest}
 */
proto.com.vectara.admin.UpdateCorpusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.UpdateCorpusRequest;
  return proto.com.vectara.admin.UpdateCorpusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.UpdateCorpusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.UpdateCorpusRequest}
 */
proto.com.vectara.admin.UpdateCorpusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSave(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.UpdateCorpusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.UpdateCorpusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.UpdateCorpusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.UpdateCorpusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.com.vectara.admin.UpdateCorpusRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.UpdateCorpusRequest} returns this
 */
proto.com.vectara.admin.UpdateCorpusRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.vectara.admin.UpdateCorpusRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.UpdateCorpusRequest} returns this
 */
proto.com.vectara.admin.UpdateCorpusRequest.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.admin.UpdateCorpusRequest} returns this
 */
proto.com.vectara.admin.UpdateCorpusRequest.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.UpdateCorpusRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.com.vectara.admin.UpdateCorpusRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.UpdateCorpusRequest} returns this
 */
proto.com.vectara.admin.UpdateCorpusRequest.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.admin.UpdateCorpusRequest} returns this
 */
proto.com.vectara.admin.UpdateCorpusRequest.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.UpdateCorpusRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool save = 4;
 * @return {boolean}
 */
proto.com.vectara.admin.UpdateCorpusRequest.prototype.getSave = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.admin.UpdateCorpusRequest} returns this
 */
proto.com.vectara.admin.UpdateCorpusRequest.prototype.setSave = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.admin.UpdateCorpusRequest} returns this
 */
proto.com.vectara.admin.UpdateCorpusRequest.prototype.clearSave = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.UpdateCorpusRequest.prototype.hasSave = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.UpdateCorpusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.UpdateCorpusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.UpdateCorpusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.UpdateCorpusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.UpdateCorpusResponse}
 */
proto.com.vectara.admin.UpdateCorpusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.UpdateCorpusResponse;
  return proto.com.vectara.admin.UpdateCorpusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.UpdateCorpusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.UpdateCorpusResponse}
 */
proto.com.vectara.admin.UpdateCorpusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.UpdateCorpusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.UpdateCorpusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.UpdateCorpusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.UpdateCorpusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional com.vectara.Status status = 1;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.UpdateCorpusResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.UpdateCorpusResponse} returns this
*/
proto.com.vectara.admin.UpdateCorpusResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.UpdateCorpusResponse} returns this
 */
proto.com.vectara.admin.UpdateCorpusResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.UpdateCorpusResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.DeleteCorpusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.DeleteCorpusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.DeleteCorpusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.DeleteCorpusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    corpusId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.DeleteCorpusRequest}
 */
proto.com.vectara.admin.DeleteCorpusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.DeleteCorpusRequest;
  return proto.com.vectara.admin.DeleteCorpusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.DeleteCorpusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.DeleteCorpusRequest}
 */
proto.com.vectara.admin.DeleteCorpusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorpusId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.DeleteCorpusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.DeleteCorpusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.DeleteCorpusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.DeleteCorpusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 customer_id = 1;
 * @return {number}
 */
proto.com.vectara.admin.DeleteCorpusRequest.prototype.getCustomerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.DeleteCorpusRequest} returns this
 */
proto.com.vectara.admin.DeleteCorpusRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 corpus_id = 2;
 * @return {number}
 */
proto.com.vectara.admin.DeleteCorpusRequest.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.DeleteCorpusRequest} returns this
 */
proto.com.vectara.admin.DeleteCorpusRequest.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.DeleteCorpusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.DeleteCorpusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.DeleteCorpusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.DeleteCorpusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.DeleteCorpusResponse}
 */
proto.com.vectara.admin.DeleteCorpusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.DeleteCorpusResponse;
  return proto.com.vectara.admin.DeleteCorpusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.DeleteCorpusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.DeleteCorpusResponse}
 */
proto.com.vectara.admin.DeleteCorpusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.DeleteCorpusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.DeleteCorpusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.DeleteCorpusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.DeleteCorpusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional com.vectara.Status status = 1;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.DeleteCorpusResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.DeleteCorpusResponse} returns this
*/
proto.com.vectara.admin.DeleteCorpusResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.DeleteCorpusResponse} returns this
 */
proto.com.vectara.admin.DeleteCorpusResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.DeleteCorpusResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ResetCorpusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ResetCorpusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ResetCorpusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ResetCorpusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    corpusId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ResetCorpusRequest}
 */
proto.com.vectara.admin.ResetCorpusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ResetCorpusRequest;
  return proto.com.vectara.admin.ResetCorpusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ResetCorpusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ResetCorpusRequest}
 */
proto.com.vectara.admin.ResetCorpusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorpusId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ResetCorpusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ResetCorpusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ResetCorpusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ResetCorpusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 customer_id = 1;
 * @return {number}
 */
proto.com.vectara.admin.ResetCorpusRequest.prototype.getCustomerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ResetCorpusRequest} returns this
 */
proto.com.vectara.admin.ResetCorpusRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 corpus_id = 2;
 * @return {number}
 */
proto.com.vectara.admin.ResetCorpusRequest.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ResetCorpusRequest} returns this
 */
proto.com.vectara.admin.ResetCorpusRequest.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ResetCorpusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ResetCorpusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ResetCorpusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ResetCorpusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ResetCorpusResponse}
 */
proto.com.vectara.admin.ResetCorpusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ResetCorpusResponse;
  return proto.com.vectara.admin.ResetCorpusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ResetCorpusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ResetCorpusResponse}
 */
proto.com.vectara.admin.ResetCorpusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ResetCorpusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ResetCorpusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ResetCorpusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ResetCorpusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional com.vectara.Status status = 1;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ResetCorpusResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ResetCorpusResponse} returns this
*/
proto.com.vectara.admin.ResetCorpusResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ResetCorpusResponse} returns this
 */
proto.com.vectara.admin.ResetCorpusResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ResetCorpusResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ListCorporaRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ListCorporaRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ListCorporaRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ListCorporaRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListCorporaRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: jspb.Message.getFieldWithDefault(msg, 2, ""),
    numResults: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pageKey: msg.getPageKey_asB64(),
    sortOptionsList: jspb.Message.toObjectList(msg.getSortOptionsList(),
    proto.com.vectara.admin.SortOptions.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ListCorporaRequest}
 */
proto.com.vectara.admin.ListCorporaRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ListCorporaRequest;
  return proto.com.vectara.admin.ListCorporaRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ListCorporaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ListCorporaRequest}
 */
proto.com.vectara.admin.ListCorporaRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilter(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumResults(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPageKey(value);
      break;
    case 6:
      var value = new proto.com.vectara.admin.SortOptions;
      reader.readMessage(value,proto.com.vectara.admin.SortOptions.deserializeBinaryFromReader);
      msg.addSortOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListCorporaRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ListCorporaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ListCorporaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListCorporaRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNumResults();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getPageKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = message.getSortOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.com.vectara.admin.SortOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional string filter = 2;
 * @return {string}
 */
proto.com.vectara.admin.ListCorporaRequest.prototype.getFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ListCorporaRequest} returns this
 */
proto.com.vectara.admin.ListCorporaRequest.prototype.setFilter = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 num_results = 4;
 * @return {number}
 */
proto.com.vectara.admin.ListCorporaRequest.prototype.getNumResults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ListCorporaRequest} returns this
 */
proto.com.vectara.admin.ListCorporaRequest.prototype.setNumResults = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bytes page_key = 5;
 * @return {string}
 */
proto.com.vectara.admin.ListCorporaRequest.prototype.getPageKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes page_key = 5;
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {string}
 */
proto.com.vectara.admin.ListCorporaRequest.prototype.getPageKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPageKey()));
};


/**
 * optional bytes page_key = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListCorporaRequest.prototype.getPageKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPageKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.vectara.admin.ListCorporaRequest} returns this
 */
proto.com.vectara.admin.ListCorporaRequest.prototype.setPageKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};


/**
 * repeated SortOptions sort_options = 6;
 * @return {!Array<!proto.com.vectara.admin.SortOptions>}
 */
proto.com.vectara.admin.ListCorporaRequest.prototype.getSortOptionsList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.SortOptions>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.SortOptions, 6));
};


/**
 * @param {!Array<!proto.com.vectara.admin.SortOptions>} value
 * @return {!proto.com.vectara.admin.ListCorporaRequest} returns this
*/
proto.com.vectara.admin.ListCorporaRequest.prototype.setSortOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.com.vectara.admin.SortOptions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.SortOptions}
 */
proto.com.vectara.admin.ListCorporaRequest.prototype.addSortOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.com.vectara.admin.SortOptions, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ListCorporaRequest} returns this
 */
proto.com.vectara.admin.ListCorporaRequest.prototype.clearSortOptionsList = function() {
  return this.setSortOptionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ListCorporaResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ListCorporaResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ListCorporaResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ListCorporaResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListCorporaResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpusList: jspb.Message.toObjectList(msg.getCorpusList(),
    proto.com.vectara.admin.Corpus.toObject, includeInstance),
    pageKey: msg.getPageKey_asB64(),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ListCorporaResponse}
 */
proto.com.vectara.admin.ListCorporaResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ListCorporaResponse;
  return proto.com.vectara.admin.ListCorporaResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ListCorporaResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ListCorporaResponse}
 */
proto.com.vectara.admin.ListCorporaResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.Corpus;
      reader.readMessage(value,proto.com.vectara.admin.Corpus.deserializeBinaryFromReader);
      msg.addCorpus(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPageKey(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListCorporaResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ListCorporaResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ListCorporaResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListCorporaResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.admin.Corpus.serializeBinaryToWriter
    );
  }
  f = message.getPageKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Corpus corpus = 1;
 * @return {!Array<!proto.com.vectara.admin.Corpus>}
 */
proto.com.vectara.admin.ListCorporaResponse.prototype.getCorpusList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.Corpus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.Corpus, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.Corpus>} value
 * @return {!proto.com.vectara.admin.ListCorporaResponse} returns this
*/
proto.com.vectara.admin.ListCorporaResponse.prototype.setCorpusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.admin.Corpus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.Corpus}
 */
proto.com.vectara.admin.ListCorporaResponse.prototype.addCorpus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.admin.Corpus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ListCorporaResponse} returns this
 */
proto.com.vectara.admin.ListCorporaResponse.prototype.clearCorpusList = function() {
  return this.setCorpusList([]);
};


/**
 * optional bytes page_key = 2;
 * @return {string}
 */
proto.com.vectara.admin.ListCorporaResponse.prototype.getPageKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes page_key = 2;
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {string}
 */
proto.com.vectara.admin.ListCorporaResponse.prototype.getPageKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPageKey()));
};


/**
 * optional bytes page_key = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListCorporaResponse.prototype.getPageKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPageKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.vectara.admin.ListCorporaResponse} returns this
 */
proto.com.vectara.admin.ListCorporaResponse.prototype.setPageKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional com.vectara.Status status = 1000;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ListCorporaResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ListCorporaResponse} returns this
*/
proto.com.vectara.admin.ListCorporaResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ListCorporaResponse} returns this
 */
proto.com.vectara.admin.ListCorporaResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ListCorporaResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.UpdateCorpusEnablementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.UpdateCorpusEnablementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.UpdateCorpusEnablementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.UpdateCorpusEnablementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpusId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    enable: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.UpdateCorpusEnablementRequest}
 */
proto.com.vectara.admin.UpdateCorpusEnablementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.UpdateCorpusEnablementRequest;
  return proto.com.vectara.admin.UpdateCorpusEnablementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.UpdateCorpusEnablementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.UpdateCorpusEnablementRequest}
 */
proto.com.vectara.admin.UpdateCorpusEnablementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorpusId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.UpdateCorpusEnablementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.UpdateCorpusEnablementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.UpdateCorpusEnablementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.UpdateCorpusEnablementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEnable();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional uint32 corpus_id = 1;
 * @return {number}
 */
proto.com.vectara.admin.UpdateCorpusEnablementRequest.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.UpdateCorpusEnablementRequest} returns this
 */
proto.com.vectara.admin.UpdateCorpusEnablementRequest.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool enable = 2;
 * @return {boolean}
 */
proto.com.vectara.admin.UpdateCorpusEnablementRequest.prototype.getEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.admin.UpdateCorpusEnablementRequest} returns this
 */
proto.com.vectara.admin.UpdateCorpusEnablementRequest.prototype.setEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.UpdateCorpusEnablementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.UpdateCorpusEnablementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.UpdateCorpusEnablementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.UpdateCorpusEnablementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.UpdateCorpusEnablementResponse}
 */
proto.com.vectara.admin.UpdateCorpusEnablementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.UpdateCorpusEnablementResponse;
  return proto.com.vectara.admin.UpdateCorpusEnablementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.UpdateCorpusEnablementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.UpdateCorpusEnablementResponse}
 */
proto.com.vectara.admin.UpdateCorpusEnablementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.UpdateCorpusEnablementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.UpdateCorpusEnablementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.UpdateCorpusEnablementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.UpdateCorpusEnablementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional com.vectara.Status status = 1;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.UpdateCorpusEnablementResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.UpdateCorpusEnablementResponse} returns this
*/
proto.com.vectara.admin.UpdateCorpusEnablementResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.UpdateCorpusEnablementResponse} returns this
 */
proto.com.vectara.admin.UpdateCorpusEnablementResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.UpdateCorpusEnablementResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ComputeRecallRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ComputeRecallRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ComputeRecallRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ComputeRecallRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpusId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    maxSampleSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    updateSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ComputeRecallRequest}
 */
proto.com.vectara.admin.ComputeRecallRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ComputeRecallRequest;
  return proto.com.vectara.admin.ComputeRecallRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ComputeRecallRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ComputeRecallRequest}
 */
proto.com.vectara.admin.ComputeRecallRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorpusId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxSampleSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUpdateSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ComputeRecallRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ComputeRecallRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ComputeRecallRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ComputeRecallRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getMaxSampleSize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getUpdateSize();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 corpus_id = 1;
 * @return {number}
 */
proto.com.vectara.admin.ComputeRecallRequest.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ComputeRecallRequest} returns this
 */
proto.com.vectara.admin.ComputeRecallRequest.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 max_sample_size = 2;
 * @return {number}
 */
proto.com.vectara.admin.ComputeRecallRequest.prototype.getMaxSampleSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ComputeRecallRequest} returns this
 */
proto.com.vectara.admin.ComputeRecallRequest.prototype.setMaxSampleSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 update_size = 3;
 * @return {number}
 */
proto.com.vectara.admin.ComputeRecallRequest.prototype.getUpdateSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ComputeRecallRequest} returns this
 */
proto.com.vectara.admin.ComputeRecallRequest.prototype.setUpdateSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ComputeRecallResponse.repeatedFields_ = [1000];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ComputeRecallResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ComputeRecallResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ComputeRecallResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ComputeRecallResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recall: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    sampleSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    statusList: jspb.Message.toObjectList(msg.getStatusList(),
    status_pb.Status.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ComputeRecallResponse}
 */
proto.com.vectara.admin.ComputeRecallResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ComputeRecallResponse;
  return proto.com.vectara.admin.ComputeRecallResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ComputeRecallResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ComputeRecallResponse}
 */
proto.com.vectara.admin.ComputeRecallResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRecall(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSampleSize(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.addStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ComputeRecallResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ComputeRecallResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ComputeRecallResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ComputeRecallResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecall();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getSampleSize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional double recall = 1;
 * @return {number}
 */
proto.com.vectara.admin.ComputeRecallResponse.prototype.getRecall = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ComputeRecallResponse} returns this
 */
proto.com.vectara.admin.ComputeRecallResponse.prototype.setRecall = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional uint32 sample_size = 2;
 * @return {number}
 */
proto.com.vectara.admin.ComputeRecallResponse.prototype.getSampleSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ComputeRecallResponse} returns this
 */
proto.com.vectara.admin.ComputeRecallResponse.prototype.setSampleSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated com.vectara.Status status = 1000;
 * @return {!Array<!proto.com.vectara.Status>}
 */
proto.com.vectara.admin.ComputeRecallResponse.prototype.getStatusList = function() {
  return /** @type{!Array<!proto.com.vectara.Status>} */ (
    jspb.Message.getRepeatedWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {!Array<!proto.com.vectara.Status>} value
 * @return {!proto.com.vectara.admin.ComputeRecallResponse} returns this
*/
proto.com.vectara.admin.ComputeRecallResponse.prototype.setStatusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1000, value);
};


/**
 * @param {!proto.com.vectara.Status=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.Status}
 */
proto.com.vectara.admin.ComputeRecallResponse.prototype.addStatus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1000, opt_value, proto.com.vectara.Status, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ComputeRecallResponse} returns this
 */
proto.com.vectara.admin.ComputeRecallResponse.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ComputeBackgroundRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ComputeBackgroundRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ComputeBackgroundRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ComputeBackgroundRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    encoderId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    maxSampleSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    updateSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ComputeBackgroundRequest}
 */
proto.com.vectara.admin.ComputeBackgroundRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ComputeBackgroundRequest;
  return proto.com.vectara.admin.ComputeBackgroundRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ComputeBackgroundRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ComputeBackgroundRequest}
 */
proto.com.vectara.admin.ComputeBackgroundRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEncoderId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxSampleSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUpdateSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ComputeBackgroundRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ComputeBackgroundRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ComputeBackgroundRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ComputeBackgroundRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEncoderId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getMaxSampleSize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getUpdateSize();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 encoder_id = 1;
 * @return {number}
 */
proto.com.vectara.admin.ComputeBackgroundRequest.prototype.getEncoderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ComputeBackgroundRequest} returns this
 */
proto.com.vectara.admin.ComputeBackgroundRequest.prototype.setEncoderId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 max_sample_size = 2;
 * @return {number}
 */
proto.com.vectara.admin.ComputeBackgroundRequest.prototype.getMaxSampleSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ComputeBackgroundRequest} returns this
 */
proto.com.vectara.admin.ComputeBackgroundRequest.prototype.setMaxSampleSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 update_size = 3;
 * @return {number}
 */
proto.com.vectara.admin.ComputeBackgroundRequest.prototype.getUpdateSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ComputeBackgroundRequest} returns this
 */
proto.com.vectara.admin.ComputeBackgroundRequest.prototype.setUpdateSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ComputeBackgroundResponse.repeatedFields_ = [1000];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ComputeBackgroundResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ComputeBackgroundResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ComputeBackgroundResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ComputeBackgroundResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sampleSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    statusList: jspb.Message.toObjectList(msg.getStatusList(),
    status_pb.Status.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ComputeBackgroundResponse}
 */
proto.com.vectara.admin.ComputeBackgroundResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ComputeBackgroundResponse;
  return proto.com.vectara.admin.ComputeBackgroundResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ComputeBackgroundResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ComputeBackgroundResponse}
 */
proto.com.vectara.admin.ComputeBackgroundResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSampleSize(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.addStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ComputeBackgroundResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ComputeBackgroundResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ComputeBackgroundResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ComputeBackgroundResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSampleSize();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 sample_size = 1;
 * @return {number}
 */
proto.com.vectara.admin.ComputeBackgroundResponse.prototype.getSampleSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ComputeBackgroundResponse} returns this
 */
proto.com.vectara.admin.ComputeBackgroundResponse.prototype.setSampleSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated com.vectara.Status status = 1000;
 * @return {!Array<!proto.com.vectara.Status>}
 */
proto.com.vectara.admin.ComputeBackgroundResponse.prototype.getStatusList = function() {
  return /** @type{!Array<!proto.com.vectara.Status>} */ (
    jspb.Message.getRepeatedWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {!Array<!proto.com.vectara.Status>} value
 * @return {!proto.com.vectara.admin.ComputeBackgroundResponse} returns this
*/
proto.com.vectara.admin.ComputeBackgroundResponse.prototype.setStatusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1000, value);
};


/**
 * @param {!proto.com.vectara.Status=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.Status}
 */
proto.com.vectara.admin.ComputeBackgroundResponse.prototype.addStatus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1000, opt_value, proto.com.vectara.Status, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ComputeBackgroundResponse} returns this
 */
proto.com.vectara.admin.ComputeBackgroundResponse.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.CorpusInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.CorpusInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.CorpusInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CorpusInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.CorpusInfoRequest}
 */
proto.com.vectara.admin.CorpusInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.CorpusInfoRequest;
  return proto.com.vectara.admin.CorpusInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.CorpusInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.CorpusInfoRequest}
 */
proto.com.vectara.admin.CorpusInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.CorpusInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.CorpusInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.CorpusInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CorpusInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.CorpusInfoResponse.repeatedFields_ = [1000];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.CorpusInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.CorpusInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.CorpusInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CorpusInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpus: (f = msg.getCorpus()) && proto.com.vectara.admin.Corpus.toObject(includeInstance, f),
    statusList: jspb.Message.toObjectList(msg.getStatusList(),
    status_pb.Status.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.CorpusInfoResponse}
 */
proto.com.vectara.admin.CorpusInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.CorpusInfoResponse;
  return proto.com.vectara.admin.CorpusInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.CorpusInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.CorpusInfoResponse}
 */
proto.com.vectara.admin.CorpusInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.Corpus;
      reader.readMessage(value,proto.com.vectara.admin.Corpus.deserializeBinaryFromReader);
      msg.setCorpus(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.addStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.CorpusInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.CorpusInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.CorpusInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CorpusInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.vectara.admin.Corpus.serializeBinaryToWriter
    );
  }
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional Corpus corpus = 1;
 * @return {?proto.com.vectara.admin.Corpus}
 */
proto.com.vectara.admin.CorpusInfoResponse.prototype.getCorpus = function() {
  return /** @type{?proto.com.vectara.admin.Corpus} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.Corpus, 1));
};


/**
 * @param {?proto.com.vectara.admin.Corpus|undefined} value
 * @return {!proto.com.vectara.admin.CorpusInfoResponse} returns this
*/
proto.com.vectara.admin.CorpusInfoResponse.prototype.setCorpus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.CorpusInfoResponse} returns this
 */
proto.com.vectara.admin.CorpusInfoResponse.prototype.clearCorpus = function() {
  return this.setCorpus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.CorpusInfoResponse.prototype.hasCorpus = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated com.vectara.Status status = 1000;
 * @return {!Array<!proto.com.vectara.Status>}
 */
proto.com.vectara.admin.CorpusInfoResponse.prototype.getStatusList = function() {
  return /** @type{!Array<!proto.com.vectara.Status>} */ (
    jspb.Message.getRepeatedWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {!Array<!proto.com.vectara.Status>} value
 * @return {!proto.com.vectara.admin.CorpusInfoResponse} returns this
*/
proto.com.vectara.admin.CorpusInfoResponse.prototype.setStatusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1000, value);
};


/**
 * @param {!proto.com.vectara.Status=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.Status}
 */
proto.com.vectara.admin.CorpusInfoResponse.prototype.addStatus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1000, opt_value, proto.com.vectara.Status, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.CorpusInfoResponse} returns this
 */
proto.com.vectara.admin.CorpusInfoResponse.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.CorpusSize.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.CorpusSize.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.CorpusSize} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CorpusSize.toObject = function(includeInstance, msg) {
  var f, obj = {
    epochSecs: jspb.Message.getFieldWithDefault(msg, 1, 0),
    size: jspb.Message.getFieldWithDefault(msg, 2, 0),
    numChars: jspb.Message.getFieldWithDefault(msg, 3, 0),
    numMetadataChars: jspb.Message.getFieldWithDefault(msg, 4, 0),
    numDocs: jspb.Message.getFieldWithDefault(msg, 5, 0),
    numParts: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.CorpusSize}
 */
proto.com.vectara.admin.CorpusSize.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.CorpusSize;
  return proto.com.vectara.admin.CorpusSize.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.CorpusSize} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.CorpusSize}
 */
proto.com.vectara.admin.CorpusSize.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEpochSecs(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNumChars(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNumMetadataChars(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNumDocs(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNumParts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.CorpusSize.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.CorpusSize.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.CorpusSize} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CorpusSize.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEpochSecs();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getNumChars();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getNumMetadataChars();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getNumDocs();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getNumParts();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
};


/**
 * optional int64 epoch_secs = 1;
 * @return {number}
 */
proto.com.vectara.admin.CorpusSize.prototype.getEpochSecs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.CorpusSize} returns this
 */
proto.com.vectara.admin.CorpusSize.prototype.setEpochSecs = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 size = 2;
 * @return {number}
 */
proto.com.vectara.admin.CorpusSize.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.CorpusSize} returns this
 */
proto.com.vectara.admin.CorpusSize.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 num_chars = 3;
 * @return {number}
 */
proto.com.vectara.admin.CorpusSize.prototype.getNumChars = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.CorpusSize} returns this
 */
proto.com.vectara.admin.CorpusSize.prototype.setNumChars = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 num_metadata_chars = 4;
 * @return {number}
 */
proto.com.vectara.admin.CorpusSize.prototype.getNumMetadataChars = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.CorpusSize} returns this
 */
proto.com.vectara.admin.CorpusSize.prototype.setNumMetadataChars = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 num_docs = 5;
 * @return {number}
 */
proto.com.vectara.admin.CorpusSize.prototype.getNumDocs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.CorpusSize} returns this
 */
proto.com.vectara.admin.CorpusSize.prototype.setNumDocs = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 num_parts = 6;
 * @return {number}
 */
proto.com.vectara.admin.CorpusSize.prototype.getNumParts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.CorpusSize} returns this
 */
proto.com.vectara.admin.CorpusSize.prototype.setNumParts = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.CorpusRecall.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.CorpusRecall.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.CorpusRecall} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CorpusRecall.toObject = function(includeInstance, msg) {
  var f, obj = {
    epochSecs: jspb.Message.getFieldWithDefault(msg, 1, 0),
    recall: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    sampleSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.CorpusRecall}
 */
proto.com.vectara.admin.CorpusRecall.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.CorpusRecall;
  return proto.com.vectara.admin.CorpusRecall.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.CorpusRecall} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.CorpusRecall}
 */
proto.com.vectara.admin.CorpusRecall.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEpochSecs(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRecall(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSampleSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.CorpusRecall.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.CorpusRecall.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.CorpusRecall} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CorpusRecall.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEpochSecs();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRecall();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getSampleSize();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional int64 epoch_secs = 1;
 * @return {number}
 */
proto.com.vectara.admin.CorpusRecall.prototype.getEpochSecs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.CorpusRecall} returns this
 */
proto.com.vectara.admin.CorpusRecall.prototype.setEpochSecs = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double recall = 2;
 * @return {number}
 */
proto.com.vectara.admin.CorpusRecall.prototype.getRecall = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.CorpusRecall} returns this
 */
proto.com.vectara.admin.CorpusRecall.prototype.setRecall = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional uint32 sample_size = 3;
 * @return {number}
 */
proto.com.vectara.admin.CorpusRecall.prototype.getSampleSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.CorpusRecall} returns this
 */
proto.com.vectara.admin.CorpusRecall.prototype.setSampleSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ReadCorpusRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadCorpusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadCorpusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadCorpusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadCorpusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpusIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    readBasicInfo: jspb.Message.getBooleanFieldWithDefault(msg, 1000, false),
    readSize: jspb.Message.getBooleanFieldWithDefault(msg, 1001, false),
    readRecall: jspb.Message.getBooleanFieldWithDefault(msg, 1002, false),
    readApiKeys: jspb.Message.getBooleanFieldWithDefault(msg, 1003, false),
    readCustomDimensions: jspb.Message.getBooleanFieldWithDefault(msg, 1004, false),
    readFilterAttributes: jspb.Message.getBooleanFieldWithDefault(msg, 1005, false),
    readAsyncIndexStats: jspb.Message.getBooleanFieldWithDefault(msg, 1006, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadCorpusRequest}
 */
proto.com.vectara.admin.ReadCorpusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadCorpusRequest;
  return proto.com.vectara.admin.ReadCorpusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadCorpusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadCorpusRequest}
 */
proto.com.vectara.admin.ReadCorpusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCorpusId(values[i]);
      }
      break;
    case 1000:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadBasicInfo(value);
      break;
    case 1001:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadSize(value);
      break;
    case 1002:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadRecall(value);
      break;
    case 1003:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadApiKeys(value);
      break;
    case 1004:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadCustomDimensions(value);
      break;
    case 1005:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadFilterAttributes(value);
      break;
    case 1006:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadAsyncIndexStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadCorpusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadCorpusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadCorpusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadCorpusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpusIdList();
  if (f.length > 0) {
    writer.writePackedUint32(
      1,
      f
    );
  }
  f = message.getReadBasicInfo();
  if (f) {
    writer.writeBool(
      1000,
      f
    );
  }
  f = message.getReadSize();
  if (f) {
    writer.writeBool(
      1001,
      f
    );
  }
  f = message.getReadRecall();
  if (f) {
    writer.writeBool(
      1002,
      f
    );
  }
  f = message.getReadApiKeys();
  if (f) {
    writer.writeBool(
      1003,
      f
    );
  }
  f = message.getReadCustomDimensions();
  if (f) {
    writer.writeBool(
      1004,
      f
    );
  }
  f = message.getReadFilterAttributes();
  if (f) {
    writer.writeBool(
      1005,
      f
    );
  }
  f = message.getReadAsyncIndexStats();
  if (f) {
    writer.writeBool(
      1006,
      f
    );
  }
};


/**
 * repeated uint32 corpus_id = 1;
 * @return {!Array<number>}
 */
proto.com.vectara.admin.ReadCorpusRequest.prototype.getCorpusIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.com.vectara.admin.ReadCorpusRequest} returns this
 */
proto.com.vectara.admin.ReadCorpusRequest.prototype.setCorpusIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.ReadCorpusRequest} returns this
 */
proto.com.vectara.admin.ReadCorpusRequest.prototype.addCorpusId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ReadCorpusRequest} returns this
 */
proto.com.vectara.admin.ReadCorpusRequest.prototype.clearCorpusIdList = function() {
  return this.setCorpusIdList([]);
};


/**
 * optional bool read_basic_info = 1000;
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCorpusRequest.prototype.getReadBasicInfo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1000, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.admin.ReadCorpusRequest} returns this
 */
proto.com.vectara.admin.ReadCorpusRequest.prototype.setReadBasicInfo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1000, value);
};


/**
 * optional bool read_size = 1001;
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCorpusRequest.prototype.getReadSize = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1001, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.admin.ReadCorpusRequest} returns this
 */
proto.com.vectara.admin.ReadCorpusRequest.prototype.setReadSize = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1001, value);
};


/**
 * optional bool read_recall = 1002;
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCorpusRequest.prototype.getReadRecall = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1002, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.admin.ReadCorpusRequest} returns this
 */
proto.com.vectara.admin.ReadCorpusRequest.prototype.setReadRecall = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1002, value);
};


/**
 * optional bool read_api_keys = 1003;
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCorpusRequest.prototype.getReadApiKeys = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1003, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.admin.ReadCorpusRequest} returns this
 */
proto.com.vectara.admin.ReadCorpusRequest.prototype.setReadApiKeys = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1003, value);
};


/**
 * optional bool read_custom_dimensions = 1004;
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCorpusRequest.prototype.getReadCustomDimensions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1004, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.admin.ReadCorpusRequest} returns this
 */
proto.com.vectara.admin.ReadCorpusRequest.prototype.setReadCustomDimensions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1004, value);
};


/**
 * optional bool read_filter_attributes = 1005;
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCorpusRequest.prototype.getReadFilterAttributes = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1005, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.admin.ReadCorpusRequest} returns this
 */
proto.com.vectara.admin.ReadCorpusRequest.prototype.setReadFilterAttributes = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1005, value);
};


/**
 * optional bool read_async_index_stats = 1006;
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCorpusRequest.prototype.getReadAsyncIndexStats = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1006, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.admin.ReadCorpusRequest} returns this
 */
proto.com.vectara.admin.ReadCorpusRequest.prototype.setReadAsyncIndexStats = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1006, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ReadCorpusResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadCorpusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadCorpusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadCorpusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadCorpusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    corporaList: jspb.Message.toObjectList(msg.getCorporaList(),
    proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadCorpusResponse}
 */
proto.com.vectara.admin.ReadCorpusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadCorpusResponse;
  return proto.com.vectara.admin.ReadCorpusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadCorpusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadCorpusResponse}
 */
proto.com.vectara.admin.ReadCorpusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo;
      reader.readMessage(value,proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.deserializeBinaryFromReader);
      msg.addCorpora(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadCorpusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadCorpusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadCorpusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadCorpusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorporaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.repeatedFields_ = [4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpus: (f = msg.getCorpus()) && proto.com.vectara.admin.Corpus.toObject(includeInstance, f),
    corpusStatus: (f = msg.getCorpusStatus()) && status_pb.Status.toObject(includeInstance, f),
    size: (f = msg.getSize()) && proto.com.vectara.admin.CorpusSize.toObject(includeInstance, f),
    sizeStatus: (f = msg.getSizeStatus()) && status_pb.Status.toObject(includeInstance, f),
    recall: (f = msg.getRecall()) && proto.com.vectara.admin.CorpusRecall.toObject(includeInstance, f),
    recallStatus: (f = msg.getRecallStatus()) && status_pb.Status.toObject(includeInstance, f),
    apiKeyList: jspb.Message.toObjectList(msg.getApiKeyList(),
    admin_admin_apikey_pb.ApiKey.toObject, includeInstance),
    apiKeyStatus: (f = msg.getApiKeyStatus()) && status_pb.Status.toObject(includeInstance, f),
    customDimensionList: jspb.Message.toObjectList(msg.getCustomDimensionList(),
    proto.com.vectara.admin.Dimension.toObject, includeInstance),
    customDimensionStatus: (f = msg.getCustomDimensionStatus()) && status_pb.Status.toObject(includeInstance, f),
    filterAttributeList: jspb.Message.toObjectList(msg.getFilterAttributeList(),
    proto.com.vectara.admin.FilterAttribute.toObject, includeInstance),
    filterAttributeStatus: (f = msg.getFilterAttributeStatus()) && status_pb.Status.toObject(includeInstance, f),
    asyncIndexStats: (f = msg.getAsyncIndexStats()) && proto.com.vectara.admin.AsyncIndexStats.toObject(includeInstance, f),
    asyncIndexStatsStatus: (f = msg.getAsyncIndexStatsStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo;
  return proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.Corpus;
      reader.readMessage(value,proto.com.vectara.admin.Corpus.deserializeBinaryFromReader);
      msg.setCorpus(value);
      break;
    case 1001:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setCorpusStatus(value);
      break;
    case 2:
      var value = new proto.com.vectara.admin.CorpusSize;
      reader.readMessage(value,proto.com.vectara.admin.CorpusSize.deserializeBinaryFromReader);
      msg.setSize(value);
      break;
    case 1002:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setSizeStatus(value);
      break;
    case 3:
      var value = new proto.com.vectara.admin.CorpusRecall;
      reader.readMessage(value,proto.com.vectara.admin.CorpusRecall.deserializeBinaryFromReader);
      msg.setRecall(value);
      break;
    case 1003:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setRecallStatus(value);
      break;
    case 4:
      var value = new admin_admin_apikey_pb.ApiKey;
      reader.readMessage(value,admin_admin_apikey_pb.ApiKey.deserializeBinaryFromReader);
      msg.addApiKey(value);
      break;
    case 1004:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setApiKeyStatus(value);
      break;
    case 5:
      var value = new proto.com.vectara.admin.Dimension;
      reader.readMessage(value,proto.com.vectara.admin.Dimension.deserializeBinaryFromReader);
      msg.addCustomDimension(value);
      break;
    case 1005:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setCustomDimensionStatus(value);
      break;
    case 6:
      var value = new proto.com.vectara.admin.FilterAttribute;
      reader.readMessage(value,proto.com.vectara.admin.FilterAttribute.deserializeBinaryFromReader);
      msg.addFilterAttribute(value);
      break;
    case 1006:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setFilterAttributeStatus(value);
      break;
    case 7:
      var value = new proto.com.vectara.admin.AsyncIndexStats;
      reader.readMessage(value,proto.com.vectara.admin.AsyncIndexStats.deserializeBinaryFromReader);
      msg.setAsyncIndexStats(value);
      break;
    case 1007:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setAsyncIndexStatsStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.vectara.admin.Corpus.serializeBinaryToWriter
    );
  }
  f = message.getCorpusStatus();
  if (f != null) {
    writer.writeMessage(
      1001,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getSize();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.vectara.admin.CorpusSize.serializeBinaryToWriter
    );
  }
  f = message.getSizeStatus();
  if (f != null) {
    writer.writeMessage(
      1002,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getRecall();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.vectara.admin.CorpusRecall.serializeBinaryToWriter
    );
  }
  f = message.getRecallStatus();
  if (f != null) {
    writer.writeMessage(
      1003,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getApiKeyList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      admin_admin_apikey_pb.ApiKey.serializeBinaryToWriter
    );
  }
  f = message.getApiKeyStatus();
  if (f != null) {
    writer.writeMessage(
      1004,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getCustomDimensionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.com.vectara.admin.Dimension.serializeBinaryToWriter
    );
  }
  f = message.getCustomDimensionStatus();
  if (f != null) {
    writer.writeMessage(
      1005,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getFilterAttributeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.com.vectara.admin.FilterAttribute.serializeBinaryToWriter
    );
  }
  f = message.getFilterAttributeStatus();
  if (f != null) {
    writer.writeMessage(
      1006,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getAsyncIndexStats();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.com.vectara.admin.AsyncIndexStats.serializeBinaryToWriter
    );
  }
  f = message.getAsyncIndexStatsStatus();
  if (f != null) {
    writer.writeMessage(
      1007,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional Corpus corpus = 1;
 * @return {?proto.com.vectara.admin.Corpus}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.getCorpus = function() {
  return /** @type{?proto.com.vectara.admin.Corpus} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.Corpus, 1));
};


/**
 * @param {?proto.com.vectara.admin.Corpus|undefined} value
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
*/
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.setCorpus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.clearCorpus = function() {
  return this.setCorpus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.hasCorpus = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional com.vectara.Status corpus_status = 1001;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.getCorpusStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1001));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
*/
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.setCorpusStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1001, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.clearCorpusStatus = function() {
  return this.setCorpusStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.hasCorpusStatus = function() {
  return jspb.Message.getField(this, 1001) != null;
};


/**
 * optional CorpusSize size = 2;
 * @return {?proto.com.vectara.admin.CorpusSize}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.getSize = function() {
  return /** @type{?proto.com.vectara.admin.CorpusSize} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.CorpusSize, 2));
};


/**
 * @param {?proto.com.vectara.admin.CorpusSize|undefined} value
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
*/
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.setSize = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.clearSize = function() {
  return this.setSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.hasSize = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional com.vectara.Status size_status = 1002;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.getSizeStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1002));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
*/
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.setSizeStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1002, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.clearSizeStatus = function() {
  return this.setSizeStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.hasSizeStatus = function() {
  return jspb.Message.getField(this, 1002) != null;
};


/**
 * optional CorpusRecall recall = 3;
 * @return {?proto.com.vectara.admin.CorpusRecall}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.getRecall = function() {
  return /** @type{?proto.com.vectara.admin.CorpusRecall} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.CorpusRecall, 3));
};


/**
 * @param {?proto.com.vectara.admin.CorpusRecall|undefined} value
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
*/
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.setRecall = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.clearRecall = function() {
  return this.setRecall(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.hasRecall = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional com.vectara.Status recall_status = 1003;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.getRecallStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1003));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
*/
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.setRecallStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1003, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.clearRecallStatus = function() {
  return this.setRecallStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.hasRecallStatus = function() {
  return jspb.Message.getField(this, 1003) != null;
};


/**
 * repeated ApiKey api_key = 4;
 * @return {!Array<!proto.com.vectara.admin.ApiKey>}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.getApiKeyList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.ApiKey>} */ (
    jspb.Message.getRepeatedWrapperField(this, admin_admin_apikey_pb.ApiKey, 4));
};


/**
 * @param {!Array<!proto.com.vectara.admin.ApiKey>} value
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
*/
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.setApiKeyList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.com.vectara.admin.ApiKey=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.ApiKey}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.addApiKey = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.com.vectara.admin.ApiKey, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.clearApiKeyList = function() {
  return this.setApiKeyList([]);
};


/**
 * optional com.vectara.Status api_key_status = 1004;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.getApiKeyStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1004));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
*/
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.setApiKeyStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1004, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.clearApiKeyStatus = function() {
  return this.setApiKeyStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.hasApiKeyStatus = function() {
  return jspb.Message.getField(this, 1004) != null;
};


/**
 * repeated Dimension custom_dimension = 5;
 * @return {!Array<!proto.com.vectara.admin.Dimension>}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.getCustomDimensionList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.Dimension>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.Dimension, 5));
};


/**
 * @param {!Array<!proto.com.vectara.admin.Dimension>} value
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
*/
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.setCustomDimensionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.com.vectara.admin.Dimension=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.Dimension}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.addCustomDimension = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.com.vectara.admin.Dimension, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.clearCustomDimensionList = function() {
  return this.setCustomDimensionList([]);
};


/**
 * optional com.vectara.Status custom_dimension_status = 1005;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.getCustomDimensionStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1005));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
*/
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.setCustomDimensionStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1005, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.clearCustomDimensionStatus = function() {
  return this.setCustomDimensionStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.hasCustomDimensionStatus = function() {
  return jspb.Message.getField(this, 1005) != null;
};


/**
 * repeated FilterAttribute filter_attribute = 6;
 * @return {!Array<!proto.com.vectara.admin.FilterAttribute>}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.getFilterAttributeList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.FilterAttribute>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.FilterAttribute, 6));
};


/**
 * @param {!Array<!proto.com.vectara.admin.FilterAttribute>} value
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
*/
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.setFilterAttributeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.com.vectara.admin.FilterAttribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.FilterAttribute}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.addFilterAttribute = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.com.vectara.admin.FilterAttribute, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.clearFilterAttributeList = function() {
  return this.setFilterAttributeList([]);
};


/**
 * optional com.vectara.Status filter_attribute_status = 1006;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.getFilterAttributeStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1006));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
*/
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.setFilterAttributeStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1006, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.clearFilterAttributeStatus = function() {
  return this.setFilterAttributeStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.hasFilterAttributeStatus = function() {
  return jspb.Message.getField(this, 1006) != null;
};


/**
 * optional AsyncIndexStats async_index_stats = 7;
 * @return {?proto.com.vectara.admin.AsyncIndexStats}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.getAsyncIndexStats = function() {
  return /** @type{?proto.com.vectara.admin.AsyncIndexStats} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.AsyncIndexStats, 7));
};


/**
 * @param {?proto.com.vectara.admin.AsyncIndexStats|undefined} value
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
*/
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.setAsyncIndexStats = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.clearAsyncIndexStats = function() {
  return this.setAsyncIndexStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.hasAsyncIndexStats = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional com.vectara.Status async_index_stats_status = 1007;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.getAsyncIndexStatsStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1007));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
*/
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.setAsyncIndexStatsStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1007, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo} returns this
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.clearAsyncIndexStatsStatus = function() {
  return this.setAsyncIndexStatsStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo.prototype.hasAsyncIndexStatsStatus = function() {
  return jspb.Message.getField(this, 1007) != null;
};


/**
 * repeated CorpusInfo corpora = 1;
 * @return {!Array<!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo>}
 */
proto.com.vectara.admin.ReadCorpusResponse.prototype.getCorporaList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo>} value
 * @return {!proto.com.vectara.admin.ReadCorpusResponse} returns this
*/
proto.com.vectara.admin.ReadCorpusResponse.prototype.setCorporaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo}
 */
proto.com.vectara.admin.ReadCorpusResponse.prototype.addCorpora = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.admin.ReadCorpusResponse.CorpusInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ReadCorpusResponse} returns this
 */
proto.com.vectara.admin.ReadCorpusResponse.prototype.clearCorporaList = function() {
  return this.setCorporaList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadCorpusSizeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadCorpusSizeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadCorpusSizeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadCorpusSizeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpusId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    absoluteWindow: (f = msg.getAbsoluteWindow()) && admin_admin_metric_pb.AbsoluteWindow.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadCorpusSizeRequest}
 */
proto.com.vectara.admin.ReadCorpusSizeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadCorpusSizeRequest;
  return proto.com.vectara.admin.ReadCorpusSizeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadCorpusSizeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadCorpusSizeRequest}
 */
proto.com.vectara.admin.ReadCorpusSizeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorpusId(value);
      break;
    case 2:
      var value = new admin_admin_metric_pb.AbsoluteWindow;
      reader.readMessage(value,admin_admin_metric_pb.AbsoluteWindow.deserializeBinaryFromReader);
      msg.setAbsoluteWindow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadCorpusSizeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadCorpusSizeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadCorpusSizeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadCorpusSizeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAbsoluteWindow();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      admin_admin_metric_pb.AbsoluteWindow.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 corpus_id = 1;
 * @return {number}
 */
proto.com.vectara.admin.ReadCorpusSizeRequest.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ReadCorpusSizeRequest} returns this
 */
proto.com.vectara.admin.ReadCorpusSizeRequest.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional AbsoluteWindow absolute_window = 2;
 * @return {?proto.com.vectara.admin.AbsoluteWindow}
 */
proto.com.vectara.admin.ReadCorpusSizeRequest.prototype.getAbsoluteWindow = function() {
  return /** @type{?proto.com.vectara.admin.AbsoluteWindow} */ (
    jspb.Message.getWrapperField(this, admin_admin_metric_pb.AbsoluteWindow, 2));
};


/**
 * @param {?proto.com.vectara.admin.AbsoluteWindow|undefined} value
 * @return {!proto.com.vectara.admin.ReadCorpusSizeRequest} returns this
*/
proto.com.vectara.admin.ReadCorpusSizeRequest.prototype.setAbsoluteWindow = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadCorpusSizeRequest} returns this
 */
proto.com.vectara.admin.ReadCorpusSizeRequest.prototype.clearAbsoluteWindow = function() {
  return this.setAbsoluteWindow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCorpusSizeRequest.prototype.hasAbsoluteWindow = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ReadCorpusSizeResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadCorpusSizeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadCorpusSizeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadCorpusSizeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadCorpusSizeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sizeList: jspb.Message.toObjectList(msg.getSizeList(),
    proto.com.vectara.admin.CorpusSize.toObject, includeInstance),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadCorpusSizeResponse}
 */
proto.com.vectara.admin.ReadCorpusSizeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadCorpusSizeResponse;
  return proto.com.vectara.admin.ReadCorpusSizeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadCorpusSizeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadCorpusSizeResponse}
 */
proto.com.vectara.admin.ReadCorpusSizeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.CorpusSize;
      reader.readMessage(value,proto.com.vectara.admin.CorpusSize.deserializeBinaryFromReader);
      msg.addSize(value);
      break;
    case 2:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadCorpusSizeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadCorpusSizeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadCorpusSizeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadCorpusSizeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSizeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.admin.CorpusSize.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CorpusSize size = 1;
 * @return {!Array<!proto.com.vectara.admin.CorpusSize>}
 */
proto.com.vectara.admin.ReadCorpusSizeResponse.prototype.getSizeList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.CorpusSize>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.CorpusSize, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.CorpusSize>} value
 * @return {!proto.com.vectara.admin.ReadCorpusSizeResponse} returns this
*/
proto.com.vectara.admin.ReadCorpusSizeResponse.prototype.setSizeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.admin.CorpusSize=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.CorpusSize}
 */
proto.com.vectara.admin.ReadCorpusSizeResponse.prototype.addSize = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.admin.CorpusSize, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ReadCorpusSizeResponse} returns this
 */
proto.com.vectara.admin.ReadCorpusSizeResponse.prototype.clearSizeList = function() {
  return this.setSizeList([]);
};


/**
 * optional com.vectara.Status status = 2;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ReadCorpusSizeResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 2));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ReadCorpusSizeResponse} returns this
*/
proto.com.vectara.admin.ReadCorpusSizeResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadCorpusSizeResponse} returns this
 */
proto.com.vectara.admin.ReadCorpusSizeResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCorpusSizeResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ComputeCorpusSizeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ComputeCorpusSizeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ComputeCorpusSizeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ComputeCorpusSizeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpusId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ComputeCorpusSizeRequest}
 */
proto.com.vectara.admin.ComputeCorpusSizeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ComputeCorpusSizeRequest;
  return proto.com.vectara.admin.ComputeCorpusSizeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ComputeCorpusSizeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ComputeCorpusSizeRequest}
 */
proto.com.vectara.admin.ComputeCorpusSizeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorpusId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ComputeCorpusSizeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ComputeCorpusSizeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ComputeCorpusSizeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ComputeCorpusSizeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 corpus_id = 1;
 * @return {number}
 */
proto.com.vectara.admin.ComputeCorpusSizeRequest.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ComputeCorpusSizeRequest} returns this
 */
proto.com.vectara.admin.ComputeCorpusSizeRequest.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ComputeCorpusSizeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ComputeCorpusSizeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ComputeCorpusSizeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ComputeCorpusSizeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    size: (f = msg.getSize()) && proto.com.vectara.admin.CorpusSize.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ComputeCorpusSizeResponse}
 */
proto.com.vectara.admin.ComputeCorpusSizeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ComputeCorpusSizeResponse;
  return proto.com.vectara.admin.ComputeCorpusSizeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ComputeCorpusSizeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ComputeCorpusSizeResponse}
 */
proto.com.vectara.admin.ComputeCorpusSizeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.CorpusSize;
      reader.readMessage(value,proto.com.vectara.admin.CorpusSize.deserializeBinaryFromReader);
      msg.setSize(value);
      break;
    case 2:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ComputeCorpusSizeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ComputeCorpusSizeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ComputeCorpusSizeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ComputeCorpusSizeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSize();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.vectara.admin.CorpusSize.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional CorpusSize size = 1;
 * @return {?proto.com.vectara.admin.CorpusSize}
 */
proto.com.vectara.admin.ComputeCorpusSizeResponse.prototype.getSize = function() {
  return /** @type{?proto.com.vectara.admin.CorpusSize} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.CorpusSize, 1));
};


/**
 * @param {?proto.com.vectara.admin.CorpusSize|undefined} value
 * @return {!proto.com.vectara.admin.ComputeCorpusSizeResponse} returns this
*/
proto.com.vectara.admin.ComputeCorpusSizeResponse.prototype.setSize = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ComputeCorpusSizeResponse} returns this
 */
proto.com.vectara.admin.ComputeCorpusSizeResponse.prototype.clearSize = function() {
  return this.setSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ComputeCorpusSizeResponse.prototype.hasSize = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional com.vectara.Status status = 2;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ComputeCorpusSizeResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 2));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ComputeCorpusSizeResponse} returns this
*/
proto.com.vectara.admin.ComputeCorpusSizeResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ComputeCorpusSizeResponse} returns this
 */
proto.com.vectara.admin.ComputeCorpusSizeResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ComputeCorpusSizeResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadCorpusRecallRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadCorpusRecallRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadCorpusRecallRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadCorpusRecallRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpusId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    absoluteWindow: (f = msg.getAbsoluteWindow()) && admin_admin_metric_pb.AbsoluteWindow.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadCorpusRecallRequest}
 */
proto.com.vectara.admin.ReadCorpusRecallRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadCorpusRecallRequest;
  return proto.com.vectara.admin.ReadCorpusRecallRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadCorpusRecallRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadCorpusRecallRequest}
 */
proto.com.vectara.admin.ReadCorpusRecallRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorpusId(value);
      break;
    case 2:
      var value = new admin_admin_metric_pb.AbsoluteWindow;
      reader.readMessage(value,admin_admin_metric_pb.AbsoluteWindow.deserializeBinaryFromReader);
      msg.setAbsoluteWindow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadCorpusRecallRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadCorpusRecallRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadCorpusRecallRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadCorpusRecallRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAbsoluteWindow();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      admin_admin_metric_pb.AbsoluteWindow.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 corpus_id = 1;
 * @return {number}
 */
proto.com.vectara.admin.ReadCorpusRecallRequest.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ReadCorpusRecallRequest} returns this
 */
proto.com.vectara.admin.ReadCorpusRecallRequest.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional AbsoluteWindow absolute_window = 2;
 * @return {?proto.com.vectara.admin.AbsoluteWindow}
 */
proto.com.vectara.admin.ReadCorpusRecallRequest.prototype.getAbsoluteWindow = function() {
  return /** @type{?proto.com.vectara.admin.AbsoluteWindow} */ (
    jspb.Message.getWrapperField(this, admin_admin_metric_pb.AbsoluteWindow, 2));
};


/**
 * @param {?proto.com.vectara.admin.AbsoluteWindow|undefined} value
 * @return {!proto.com.vectara.admin.ReadCorpusRecallRequest} returns this
*/
proto.com.vectara.admin.ReadCorpusRecallRequest.prototype.setAbsoluteWindow = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadCorpusRecallRequest} returns this
 */
proto.com.vectara.admin.ReadCorpusRecallRequest.prototype.clearAbsoluteWindow = function() {
  return this.setAbsoluteWindow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCorpusRecallRequest.prototype.hasAbsoluteWindow = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ReadCorpusRecallResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadCorpusRecallResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadCorpusRecallResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadCorpusRecallResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadCorpusRecallResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sizeList: jspb.Message.toObjectList(msg.getSizeList(),
    proto.com.vectara.admin.CorpusRecall.toObject, includeInstance),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadCorpusRecallResponse}
 */
proto.com.vectara.admin.ReadCorpusRecallResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadCorpusRecallResponse;
  return proto.com.vectara.admin.ReadCorpusRecallResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadCorpusRecallResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadCorpusRecallResponse}
 */
proto.com.vectara.admin.ReadCorpusRecallResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.CorpusRecall;
      reader.readMessage(value,proto.com.vectara.admin.CorpusRecall.deserializeBinaryFromReader);
      msg.addSize(value);
      break;
    case 2:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadCorpusRecallResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadCorpusRecallResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadCorpusRecallResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadCorpusRecallResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSizeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.admin.CorpusRecall.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CorpusRecall size = 1;
 * @return {!Array<!proto.com.vectara.admin.CorpusRecall>}
 */
proto.com.vectara.admin.ReadCorpusRecallResponse.prototype.getSizeList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.CorpusRecall>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.CorpusRecall, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.CorpusRecall>} value
 * @return {!proto.com.vectara.admin.ReadCorpusRecallResponse} returns this
*/
proto.com.vectara.admin.ReadCorpusRecallResponse.prototype.setSizeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.admin.CorpusRecall=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.CorpusRecall}
 */
proto.com.vectara.admin.ReadCorpusRecallResponse.prototype.addSize = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.admin.CorpusRecall, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ReadCorpusRecallResponse} returns this
 */
proto.com.vectara.admin.ReadCorpusRecallResponse.prototype.clearSizeList = function() {
  return this.setSizeList([]);
};


/**
 * optional com.vectara.Status status = 2;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ReadCorpusRecallResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 2));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ReadCorpusRecallResponse} returns this
*/
proto.com.vectara.admin.ReadCorpusRecallResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadCorpusRecallResponse} returns this
 */
proto.com.vectara.admin.ReadCorpusRecallResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCorpusRecallResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpusId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    filterAttributesList: jspb.Message.toObjectList(msg.getFilterAttributesList(),
    proto.com.vectara.admin.FilterAttribute.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest}
 */
proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest;
  return proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest}
 */
proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorpusId(value);
      break;
    case 10:
      var value = new proto.com.vectara.admin.FilterAttribute;
      reader.readMessage(value,proto.com.vectara.admin.FilterAttribute.deserializeBinaryFromReader);
      msg.addFilterAttributes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getFilterAttributesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.com.vectara.admin.FilterAttribute.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 corpus_id = 1;
 * @return {number}
 */
proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest} returns this
 */
proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated FilterAttribute filter_attributes = 10;
 * @return {!Array<!proto.com.vectara.admin.FilterAttribute>}
 */
proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest.prototype.getFilterAttributesList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.FilterAttribute>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.FilterAttribute, 10));
};


/**
 * @param {!Array<!proto.com.vectara.admin.FilterAttribute>} value
 * @return {!proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest} returns this
*/
proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest.prototype.setFilterAttributesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.com.vectara.admin.FilterAttribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.FilterAttribute}
 */
proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest.prototype.addFilterAttributes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.com.vectara.admin.FilterAttribute, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest} returns this
 */
proto.com.vectara.admin.ReplaceCorpusFilterAttrsRequest.prototype.clearFilterAttributesList = function() {
  return this.setFilterAttributesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f),
    jobId: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse}
 */
proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse;
  return proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse}
 */
proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getJobId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional com.vectara.Status status = 1000;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse} returns this
*/
proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse} returns this
 */
proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1000) != null;
};


/**
 * optional string job_id = 10;
 * @return {string}
 */
proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse.prototype.getJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse} returns this
 */
proto.com.vectara.admin.ReplaceCorpusFilterAttrsResponse.prototype.setJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.SortOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.SortOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.SortOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.SortOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    sortKey: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sortOrder: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.SortOptions}
 */
proto.com.vectara.admin.SortOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.SortOptions;
  return proto.com.vectara.admin.SortOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.SortOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.SortOptions}
 */
proto.com.vectara.admin.SortOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.vectara.admin.SortKey} */ (reader.readEnum());
      msg.setSortKey(value);
      break;
    case 2:
      var value = /** @type {!proto.com.vectara.admin.SortOrder} */ (reader.readEnum());
      msg.setSortOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.SortOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.SortOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.SortOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.SortOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSortKey();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSortOrder();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional SortKey sort_key = 1;
 * @return {!proto.com.vectara.admin.SortKey}
 */
proto.com.vectara.admin.SortOptions.prototype.getSortKey = function() {
  return /** @type {!proto.com.vectara.admin.SortKey} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.vectara.admin.SortKey} value
 * @return {!proto.com.vectara.admin.SortOptions} returns this
 */
proto.com.vectara.admin.SortOptions.prototype.setSortKey = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional SortOrder sort_order = 2;
 * @return {!proto.com.vectara.admin.SortOrder}
 */
proto.com.vectara.admin.SortOptions.prototype.getSortOrder = function() {
  return /** @type {!proto.com.vectara.admin.SortOrder} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.vectara.admin.SortOrder} value
 * @return {!proto.com.vectara.admin.SortOptions} returns this
 */
proto.com.vectara.admin.SortOptions.prototype.setSortOrder = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.com.vectara.admin.FilterAttributeType = {
  FILTER_ATTRIBUTE_TYPE__UNDEFINED: 0,
  FILTER_ATTRIBUTE_TYPE__INTEGER: 5,
  FILTER_ATTRIBUTE_TYPE__INTEGER_LIST: 10,
  FILTER_ATTRIBUTE_TYPE__REAL: 15,
  FILTER_ATTRIBUTE_TYPE__REAL_LIST: 20,
  FILTER_ATTRIBUTE_TYPE__TEXT: 25,
  FILTER_ATTRIBUTE_TYPE__TEXT_LIST: 30,
  FILTER_ATTRIBUTE_TYPE__BOOLEAN: 35
};

/**
 * @enum {number}
 */
proto.com.vectara.admin.FilterAttributeLevel = {
  FILTER_ATTRIBUTE_LEVEL__UNDEFINED: 0,
  FILTER_ATTRIBUTE_LEVEL__DOCUMENT: 5,
  FILTER_ATTRIBUTE_LEVEL__DOCUMENT_PART: 10
};

/**
 * @enum {number}
 */
proto.com.vectara.admin.SortOrder = {
  SORT_ORDER__UNDEFINED: 0,
  SORT_ORDER_ASCENDING: 1,
  SORT_ORDER_DESCENDING: 2
};

/**
 * @enum {number}
 */
proto.com.vectara.admin.SortKey = {
  SORT_KEY__UNDEFINED: 0,
  SORT_KEY_DATE_CREATED: 1
};

goog.object.extend(exports, proto.com.vectara.admin);
