const retrieveVar = (key: string) => {
  // Enable the server to inject environment variables by setting them
  // on the env object, defined in public/index.html.
  const injectedValue = (window as any).env?.[key];
  if (injectedValue) {
    return injectedValue;
  }

  return process.env[key];
};

export const REACT_APP_ENV = retrieveVar("REACT_APP_ENV");

// Platform
export const REACT_APP_BILLING_PLAN_ID = retrieveVar("REACT_APP_BILLING_PLAN_ID");
export const REACT_APP_API_ENDPOINT = retrieveVar("REACT_APP_API_ENDPOINT");
export const REACT_APP_SERVING_URL = retrieveVar("REACT_APP_SERVING_URL");
export const REACT_APP_REST_SERVING_URL = retrieveVar("REACT_APP_REST_SERVING_URL");
export const REACT_APP_OAUTH_URL = retrieveVar("REACT_APP_OAUTH_URL");
export const REACT_APP_SNOW_URL = retrieveVar("REACT_APP_SNOW_URL");

// Stripe
export const REACT_APP_STRIPE_KEY = retrieveVar("REACT_APP_STRIPE_KEY");

// Auth
export const REACT_APP_AUTH_PROVIDER = retrieveVar("REACT_APP_AUTH_PROVIDER");
export const REACT_APP_ORY_SDK_URL = retrieveVar("REACT_APP_ORY_SDK_URL");
export const REACT_APP_ORY_JWT_TEMPLATE_NAME = retrieveVar("REACT_APP_ORY_JWT_TEMPLATE_NAME");
export const REACT_APP_IDENTITY_LOGIN_ENABLED = retrieveVar("REACT_APP_IDENTITY_LOGIN_ENABLED");

// On-prem flags
export const REACT_APP_IS_SSO_ENABLED = retrieveVar("REACT_APP_IS_SSO_ENABLED");
export const REACT_APP_IS_ANALYTICS_ENABLED = retrieveVar("REACT_APP_IS_ANALYTICS_ENABLED");
export const REACT_APP_IS_TABLE_DATA_QUERYING_ENABLED = retrieveVar("REACT_APP_IS_TABLE_DATA_QUERYING_ENABLED");
export const REACT_APP_IS_DATADOG_ENABLED = retrieveVar("REACT_APP_IS_DATADOG_ENABLED");
export const REACT_APP_IS_BILLING_ENABLED = retrieveVar("REACT_APP_IS_BILLING_ENABLED");
export const REACT_APP_IS_INVITE_EMAIL_ENABLED = retrieveVar("REACT_APP_IS_INVITE_EMAIL_ENABLED");

// Datadog
export const REACT_APP_ALLOW_SESSION_MONITORING = retrieveVar("REACT_APP_ALLOW_SESSION_MONITORING");
export const REACT_APP_DATADOG_APP_ID = retrieveVar("REACT_APP_DATADOG_APP_ID");
export const REACT_APP_DATADOG_CLIENT_TOKEN = retrieveVar("REACT_APP_DATADOG_CLIENT_TOKEN");
export const REACT_APP_DATADOG_SITE = retrieveVar("REACT_APP_DATADOG_SITE");
export const REACT_APP_DATADOG_SERVICE = retrieveVar("REACT_APP_DATADOG_SERVICE");
export const REACT_APP_DATADOG_ENV = retrieveVar("REACT_APP_DATADOG_ENV");
export const REACT_APP_DATADOG_VERSION = retrieveVar("REACT_APP_DATADOG_VERSION");
